import { Grid, Typography, Button, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const SLabel = styled(Typography)`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin: 4px 0px;
    color: ${({ theme }) => theme.colors.periodButtonDark};
  }
`;

interface SAddPortfolio {
  $disabled: boolean;
}

export const SAddPortfolio = styled(Button)<SAddPortfolio>`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin: 0px 4px;
    text-transform: inherit;
    color: ${({ theme }) => theme.colors.textDefault};

    &:hover {
      cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "default")};
    }
  }
`;

export const SGridRow = styled(Grid)`
  && {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const SMenuTitle = styled(MenuItem)`
  && {
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    padding-top: 16px;
    padding-bottom: 12px;
  }
`;

export const SMenuItem = styled(MenuItem)`
  && {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

export const SAddIcon = styled(AddIcon)`
  && {
    margin-right: 16px;
  }
`;

export const SContentCopyIcon = styled(ContentCopyIcon)`
  && {
    margin-right: 16px;
  }
`;
