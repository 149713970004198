/* eslint-disable @typescript-eslint/no-explicit-any */

import { jwtDecode } from "jwt-decode";
import { DefaultTheme } from "styled-components";
import { isTokenExpired, removeCredentials } from "../domain/AuthUtils";

const DEFAULT_RETRY_TIME = 2_000;

export function isRetryable(error: any): boolean {
  return Boolean(
    // must be a response error
    error.response &&
      // must be a rate limit error
      error.response.status === 429
  );
}

export function wait(error: any): Promise<void> {
  return new Promise((resolve) =>
    setTimeout(
      resolve,
      error?.response?.headers?.["retry-after"] * 1_000 || DEFAULT_RETRY_TIME
    )
  );
}

export function retry(axios: any, error: any): Promise<any> {
  if (!error.config) {
    throw error;
  } else {
    return axios(error.config);
  }
}

/**
 * Creates an interceptor for axios that retries on response code 429
 * unsing a header containing "retry-after" to get the delay. It also gives up retry
 * and clears the authentication local stored data if error 401 is provided.
 *
 * @param axios - the axios client
 * @param options - optional overrides to `isRetryable`, `wait` and `retry` helpers
 * @returns the axios response function or an error
 * @example
 * ```ts
 * const client = axios.createClient()
 * client.interceptors.response.use(null, retryInterceptor(client))
 * ```
 */
export function retryInterceptor(
  axios: any,
  useAuth: boolean,
  theme: DefaultTheme,
  options = {}
): any {
  const {
    isRetryable: isRetryable2,
    wait: wait2,
    retry: retry2,
  } = { isRetryable, wait, retry, ...options };

  // eslint-disable-next-line func-names
  return async function (error: any) {
    if (useAuth && !error?.config?.url.includes("login/")) {
      const token = localStorage.getItem("token");

      const removeTokenAndRefresh = (): void => {
        removeCredentials();
        window.location.reload();
      };

      if (error.response.status === 401) {
        if (process.env.NODE_ENV === "development")
          console.error("AUTH: 401 received, invalidating...");

        removeTokenAndRefresh();
      }

      if (token) {
        try {
          const decodedToken = jwtDecode(token);

          if (isTokenExpired(decodedToken, theme)) {
            if (process.env.NODE_ENV === "development")
              console.error("AUTH: Present token is expired...");

            removeTokenAndRefresh();
          }
        } catch {
          if (process.env.NODE_ENV === "development")
            console.error("AUTH: Caught error on parsing token...");

          removeTokenAndRefresh();
        }
      }
    }

    if (isRetryable2(error)) {
      await wait2(error);
      return retry2(axios, error);
    }

    throw error;
  };
}
