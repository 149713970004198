import { Announcement } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { colorFromAssetType, FilterListType, SearchedAssetItem } from "../..";
import { PtBrFiltersType, useCustomBenchmarkAsset } from "../../../../store";
import { translateCategory, convertToPtBrFilters } from "../../../../utils";
import {
  SCategorySeparatorLine,
  SGrid,
  SGridNoResults,
  SGridMappedResults,
} from "./styles";

interface SearchResultsPropsType {
  data: JSON | string;
  isSearching: boolean;
  selectedFilters: FilterListType;
  secondaryMessage: boolean;
  setIsMatchedSearch: React.Dispatch<React.SetStateAction<boolean>>;
  isAnyFilterSelected: boolean;
}

export interface SearchResultsType {
  assetType: string | null;
  cnpj: string | null;
  identifier: string | null;
  isin: string | null;
  label: string | null;
  managementCompany: string | null;
  situation: string | null;
  stockExchange: string | null;
  type: string | null;
  portfolioCategory?: PtBrFiltersType;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const formatSearchResults = (
  rawSearchResults: any,
  filters: FilterListType
): typeof filteredSearch => {
  let rawCategories = Object.keys(rawSearchResults) as PtBrFiltersType[];
  const formattedSearchResults: SearchResultsType[] = [];
  const ptBrFilters = convertToPtBrFilters(filters);

  if (ptBrFilters.length !== 0) {
    rawCategories = rawCategories.filter((key) => ptBrFilters.includes(key));
  }

  rawCategories.forEach((value) => {
    rawSearchResults[value].forEach((asset: SearchResultsType) => {
      formattedSearchResults.push({
        ...asset,
        portfolioCategory: value === "EUROPA" ? "FUNDO" : value,
      });
    });
  });

  rawCategories = rawCategories
    .map((category) => category.replace("EUROPA", "FUNDO") as PtBrFiltersType)
    .filter((value, index, self) => self.indexOf(value) === index);

  // For debugging
  // console.log({ ptBrFilters, rawCategories, formattedSearchResults });

  const filteredSearch = {
    filteredResults: formattedSearchResults,
    filteredBy: rawCategories,
    filteredMatches: formattedSearchResults.length || 0,
    noMatchesFound: formattedSearchResults.length === 0,
  };

  return filteredSearch;
};

export const SearchResults: React.FC<SearchResultsPropsType> = ({
  data,
  isSearching,
  selectedFilters,
  secondaryMessage,
  setIsMatchedSearch,
  isAnyFilterSelected,
}) => {
  const { customBenchmarks, showAllSearchableCustomBenchmarks } =
    useCustomBenchmarkAsset();

  // In case of redux global opt showAllSearchableCustomBenchmarks, we override
  // the "BENCHMARK_PERSONALIZADO" search result with ALL available custom
  // benchmarks.
  const matchedDataSortedForFiltering = {
    ...(typeof data === "object" ? data : {}),
    ...(customBenchmarks.length > 0 && showAllSearchableCustomBenchmarks
      ? { BENCHMARK_PERSONALIZADO: customBenchmarks }
      : {}),
  };

  const foundAssets = formatSearchResults(
    matchedDataSortedForFiltering,
    selectedFilters
  );
  const { t } = useTranslation();
  const isSearchFinished = !(
    foundAssets.noMatchesFound && isSearching === true
  );
  const theme = useTheme();

  useEffect(
    () =>
      foundAssets.noMatchesFound
        ? setIsMatchedSearch(false)
        : setIsMatchedSearch(true),
    [foundAssets, setIsMatchedSearch]
  );

  return (
    <SGrid $isMatchedSearch={!foundAssets.noMatchesFound}>
      {((!isSearchFinished && secondaryMessage) || isAnyFilterSelected) &&
        foundAssets.noMatchesFound && (
          <SGridNoResults>
            <Announcement /> {t("searchAsset.noResults")}
          </SGridNoResults>
        )}
      {foundAssets.filteredBy.map((categoryFound) => {
        return (
          <SGridMappedResults key={categoryFound}>
            <SCategorySeparatorLine
              key={categoryFound}
              $barColor={colorFromAssetType(categoryFound, theme)}
            >
              {translateCategory(categoryFound)}
            </SCategorySeparatorLine>
            {foundAssets?.filteredResults.map(
              (asset) =>
                asset.portfolioCategory === categoryFound && (
                  <SearchedAssetItem key={asset.identifier} asset={asset} />
                )
            )}
          </SGridMappedResults>
        );
      })}
    </SGrid>
  );
};
