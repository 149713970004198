import { Dialog } from "@mui/material";
import React from "react";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import {
  SCloseButton,
  SCloseIcon,
  SDialogContent,
  SDialogTitle,
  SGridColumn1,
  SGridColumn2,
  SGridContainer,
  STitle,
} from "./styles";

interface PropsTypes {
  open: boolean;
  onClose: () => void;
  generateURL: () => string;
}

export const QRCodeDialog: React.FC<PropsTypes> = ({
  open,
  onClose,
  generateURL,
}) => {
  const targetLink = generateURL();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: 8 },
      }}
      hideBackdrop
      maxWidth="sm"
    >
      <SGridContainer>
        <SDialogTitle>
          <STitle>{t("share.qrcode.title")}</STitle>
          <SCloseButton onClick={onClose}>
            <SCloseIcon />
          </SCloseButton>
        </SDialogTitle>
        <SDialogContent>
          <SGridColumn1>{t("share.qrcode.msg")}</SGridColumn1>
          <SGridColumn2>
            <QRCode value={targetLink} size={208} />
          </SGridColumn2>
        </SDialogContent>
      </SGridContainer>
    </Dialog>
  );
};
