import { Backdrop } from "@mui/material";
import styled from "styled-components";

export const SBackdrop = styled(Backdrop)`
  && {
    z-index: 1200;
    color: ${({ theme }) => theme.colors.textWhite};
    background-color: ${({ theme }) => theme.colors.loginPageBg};
  }
`;
