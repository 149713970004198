import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Dialog, TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// eslint-disable-next-line import/no-duplicates
import { enUS, ptBR, es } from "date-fns/locale";
import {
  sub,
  add,
  isAfter,
  isBefore,
  isValid,
  startOfTomorrow,
  // eslint-disable-next-line import/no-duplicates
} from "date-fns";
import {
  SGridContainer,
  STitle,
  SDialogTitle,
  SDialogContent,
  SDialogActions,
  SDatePickerContainer,
  SDatePickerTitle,
  SCalendarTodayIcon,
  SApplyButton,
  SCloseButton,
  SCloseIcon,
  SErrorMessage,
  SErrorIcon,
  SErrorGroup,
} from "./styles";
import {
  useCustomDateRange,
  useGlobalConfigs,
  usePeriod,
} from "../../../../store";

interface DatePickerDialogProps {
  open: boolean;
  onClose: () => void;
}

const getLocale = (language: string): Locale => {
  switch (language) {
    case "en":
      return enUS;
    case "pt":
      return ptBR;
    case "es":
      return es;
    default:
      return ptBR;
  }
};

export const DatePickerDialog: React.FC<DatePickerDialogProps> = ({
  open,
  onClose,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [finalDate, setFinalDate] = useState<Date | null>(null);
  const { setSelectedPeriod, fetchedPeriodDate } = usePeriod();
  const { setCustomDateRange } = useCustomDateRange();
  const [isInitialDateInvalid, setIsInitialDateInvalid] = useState(false);
  const [isFinalDateInvalid, setIsFinalDateInvalid] = useState(false);
  const { minDate } = useGlobalConfigs();

  const gerenateErrorMessage = useCallback(
    (initialDateInvalid: boolean, finalDateInvalid: boolean): string => {
      if (initialDateInvalid && finalDateInvalid) {
        return t("datePicker.error.bothDatesInvalid");
      }
      if (initialDateInvalid && !finalDateInvalid) {
        return t("datePicker.error.invalidInitialDate");
      }
      if (!initialDateInvalid && finalDateInvalid) {
        return t("datePicker.error.invalidFinalDate");
      }
      return "";
    },
    [t]
  );

  // const handleClear = (): void => {
  //   setInitialDate(null);
  //   setFinalDate(null);
  // };

  const handleApply = (): void => {
    if (initialDate !== null && finalDate !== null) {
      const stringifiedInitialDate = new Date(initialDate)
        .toISOString()
        .slice(0, 10);

      const stringifiedFinalDate = new Date(finalDate)
        .toISOString()
        .slice(0, 10);

      setCustomDateRange({
        initialDate: stringifiedInitialDate,
        finalDate: stringifiedFinalDate,
      });
      setSelectedPeriod("DATA_PERSONALIZADA");
    }
    onClose();
  };

  const handleChangeInitialDate = (date: Date | null): void =>
    setInitialDate(date);

  const handleChangeFinalDate = (date: Date | null): void => setFinalDate(date);

  useEffect(() => {
    setInitialDate(
      new Date(String(fetchedPeriodDate.initialDate).replace("-", "."))
    );
    setFinalDate(
      new Date(String(fetchedPeriodDate.finalDate).replace("-", "."))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isFinalDateNotNull = finalDate !== null;
    const isFinalDateValid = isValid(finalDate);
    const isFinalDateBeforeTomorrow =
      isFinalDateNotNull && isBefore(finalDate, startOfTomorrow());
    const isFinalDateAfterInitialDate =
      isFinalDateNotNull &&
      (initialDate !== null
        ? isAfter(finalDate, initialDate)
        : isAfter(finalDate, minDate));
    const shouldInvalidateFinalDate = !(
      isFinalDateNotNull &&
      isFinalDateValid &&
      isFinalDateBeforeTomorrow &&
      isFinalDateAfterInitialDate
    );

    setIsFinalDateInvalid(shouldInvalidateFinalDate);

    const isInitialDateNotNull = initialDate !== null;
    const isInitialDateValid = isValid(initialDate);
    const isInitialDateAfterMinDate =
      isInitialDateNotNull && isAfter(initialDate, minDate);
    const isInitialDateBeforeFinalDate =
      isInitialDateNotNull &&
      (finalDate !== null
        ? isBefore(initialDate, finalDate)
        : isBefore(initialDate, startOfTomorrow()));
    const shouldInvalidateInitialDate = !(
      isInitialDateNotNull &&
      isInitialDateValid &&
      isInitialDateAfterMinDate &&
      isInitialDateBeforeFinalDate
    );

    setIsInitialDateInvalid(shouldInvalidateInitialDate);
  }, [finalDate, initialDate, minDate]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={getLocale(i18n.language)}
    >
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: { borderRadius: 5, position: "fixed", top: "20%" },
        }}
        BackdropProps={{
          style: { backgroundColor: "black", opacity: "0.2" },
        }}
      >
        <SGridContainer>
          <SDialogTitle>
            <STitle>{t("datePicker.customPeriodRange")}</STitle>
            <SCloseButton onClick={onClose}>
              <SCloseIcon />
            </SCloseButton>
          </SDialogTitle>
          <SDialogContent>
            <SDatePickerContainer>
              <SDatePickerTitle>{t("datePicker.initialDate")}</SDatePickerTitle>
              <DatePicker
                value={initialDate}
                onChange={handleChangeInitialDate}
                minDate={minDate}
                maxDate={
                  finalDate === null
                    ? startOfTomorrow()
                    : sub(finalDate, { days: 1 })
                }
                disableFuture
                InputAdornmentProps={{ position: "start" }}
                InputProps={{
                  sx: {
                    backgroundColor: theme.colors.bgGray,
                    maxHeight: "40px",
                    fontSize: "14px",
                    paddingLeft: "20px",
                  },
                  ...(initialDate === null && { error: true }),
                }}
                components={{ OpenPickerIcon: SCalendarTodayIcon }}
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps
                ) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                  />
                )}
              />
            </SDatePickerContainer>
            <SDatePickerContainer>
              <SDatePickerTitle>{t("datePicker.finalDate")}</SDatePickerTitle>
              <DatePicker
                value={finalDate}
                onChange={handleChangeFinalDate}
                minDate={
                  initialDate === null ? minDate : add(initialDate, { days: 1 })
                }
                maxDate={startOfTomorrow()}
                disableFuture
                InputAdornmentProps={{ position: "start" }}
                InputProps={{
                  sx: {
                    backgroundColor: theme.colors.bgGray,
                    maxHeight: "40px",
                    fontSize: "14px",
                    paddingLeft: "20px",
                  },
                  ...(finalDate === null && { error: true }),
                }}
                components={{ OpenPickerIcon: SCalendarTodayIcon }}
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps
                ) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                  />
                )}
              />
            </SDatePickerContainer>
          </SDialogContent>
          <SDialogActions>
            {isInitialDateInvalid || isFinalDateInvalid ? (
              <SErrorGroup>
                <SErrorIcon />
                <SErrorMessage>
                  {gerenateErrorMessage(
                    isInitialDateInvalid,
                    isFinalDateInvalid
                  )}
                </SErrorMessage>
              </SErrorGroup>
            ) : null}

            {/* <SClearButton
              startIcon={<SClearIcon fontSize="small" />}
              onClick={handleClear}
            >
              {t("datePicker.clear")}
            </SClearButton> */}
            <SApplyButton
              disabled={isInitialDateInvalid || isFinalDateInvalid}
              onClick={handleApply}
              // startIcon={<SApplyIcon fontSize="small" />}
            >
              {t("datePicker.apply")}
            </SApplyButton>
          </SDialogActions>
        </SGridContainer>
      </Dialog>
    </LocalizationProvider>
  );
};
