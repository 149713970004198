/**
 * Checks if the provided email address is valid.
 *
 * @param {string} emailAdd - The email address to be validated.
 * @returns {boolean} Returns true if the email address is valid, otherwise false.
 *
 * @example
 * const isValid = isEmailValid('example@email.com');
 * console.log(isValid); // true or false
 */

export const isEmailValid = (emailAdd: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(emailAdd);
};
