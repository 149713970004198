import React, { useState } from "react";
import { useCustomBenchmarkAsset } from "../../../../store";
import { FilterListType } from "../SearchFilterList/SearchFilterList";
import { SBar, SButton, STitle, SCancelIcon } from "./styles";

interface PropTypes {
  barColor: string;
  text: string;
  id: string;
  setFilterIsSelected: React.Dispatch<React.SetStateAction<FilterListType>>;
}

export const SearchFilterButton: React.FC<PropTypes> = ({
  text,
  barColor,
  id,
  setFilterIsSelected,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const { setShowAllSearchableCustomBenchmarks } = useCustomBenchmarkAsset();

  const handleSelect:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined = () => {
    setShowAllSearchableCustomBenchmarks(false);
    setIsSelected((isCurrentSelected) => !isCurrentSelected);
    setFilterIsSelected((currentList: FilterListType) => ({
      ...currentList,
      [id]: !isSelected,
    }));
  };

  return (
    <SButton
      endIcon={isSelected && <SCancelIcon />}
      onClick={handleSelect}
      selected={isSelected}
      id={id}
    >
      <SBar barColor={barColor} />
      <STitle>{text}</STitle>
    </SButton>
  );
};
