import { miliSecondsDelay } from "./DelayUtils";
/**
 * Pools delayed requests until a stop condition is met.
 * @param fn - function you want it to be repeated
 * @param fnStopCondition - a function that returns the condition to stop the pooling
 * @param initialDelay - initial delay before the first retry (in milliseconds)
 * @param maxDelay - maximum delay between retries (in milliseconds)
 * @returns the result of the `fn` function
 * @example
 * ```ts
 * const data = await poolRequest( () => api.get(...), (response) => response.data === "OK", 3000, 60000)
 * ```
 */
export const poolRequest = async <T>(
  fn: () => Promise<T>,
  fnStopCondition: (result: T) => boolean,
  initialDelay = Number(
    process.env.REACT_APP_POOL_RETRY_INITIAL_DELAY_MS ?? 2000
  ),
  maxDelay = Number(process.env.REACT_APP_POOL_RETRY_MAX_DELAY_MS ?? 60000)
): Promise<T> => {
  let delay = initialDelay;
  let result = await fn();
  while (fnStopCondition(result)) {
    // eslint-disable-next-line no-await-in-loop
    await miliSecondsDelay(delay);
    // eslint-disable-next-line no-await-in-loop
    result = await fn();
    delay = Math.min(delay * 2, maxDelay);
  }
  return result;
};
