import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCopyToClipboard } from "react-use";
import { useTranslation } from "react-i18next";
import { stringify as queryStringify } from "query-string";
import { flatten } from "ramda";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { useTheme } from "styled-components";
import {
  SPopoverMessageContainer,
  SContentCopyIcon,
  SPopover,
  SPopoverMessage,
  SClosePopoverButton,
  SClosePopoverIcon,
  SGridContainer,
  SButton,
  SQrCodeIcon,
  SContentSaveIcon,
  SLinearProgress,
} from "./styles";
import {
  useAllPortfolios,
  useBenchmarks,
  useCurrentPortfolioId,
  useCustomDateRange,
  useInitialApplication,
  usePeriod,
  useWallet,
  useWindowValue,
  useUserData,
  useSuperCarteiraFeature,
  UserDataType,
} from "../../../store";
import { QRCodeDialog } from "./QRCodeDialog/QRCodeDialog";
import { useSaveUserData } from "../../../api";

export const ShareButtons: React.FC = () => {
  const { t } = useTranslation();
  const [openPopover, setOpenPopover] = useState(false);
  const [state, copyToClipboard] = useCopyToClipboard();
  const [error, setError] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { allPortfolios } = useAllPortfolios();
  const { selectedBenchmarks } = useBenchmarks();
  const { windowValue } = useWindowValue();
  const { selectedPeriod } = usePeriod();
  const { selectedCustomDateRange } = useCustomDateRange();
  const { initialApplication } = useInitialApplication();
  const [currentPortfolioId] = useCurrentPortfolioId();
  const gridRef = useRef<HTMLDivElement>(null);
  const [isQRCodeDialogOpen, setIsQRCodeDialogOpen] = useState(false);
  const { selectedCompetence, selectedConsolidated } = useWallet();
  const [saveError, setSaveError] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const theme = useTheme();

  const {
    data: userDataFromSaving,
    loading: isSavingUserData,
    fetch: saveUserData,
  } = useSaveUserData();

  const portfolioIdentifiers = useMemo(
    () =>
      Object.keys(allPortfolios)
        .map((portfolioKey) => allPortfolios[Number(portfolioKey)].identifier)
        .filter((identifier) => identifier !== undefined),
    [allPortfolios]
  );

  const benchmarksIdentifiers = useMemo(
    () =>
      flatten(
        Object.keys(selectedBenchmarks).map((benchmarkKey) => [
          selectedBenchmarks[benchmarkKey].asset.assetType,
          selectedBenchmarks[benchmarkKey].asset.identifier,
        ])
      ),
    [selectedBenchmarks]
  );

  const selectedCustomDateArray = useMemo(
    () => [
      selectedCustomDateRange.initialDate,
      selectedCustomDateRange.finalDate,
    ],
    [selectedCustomDateRange]
  );

  const generateShareURL = useCallback((): string => {
    const queryString = queryStringify(
      {
        portfolios: portfolioIdentifiers,
        benchmarks: benchmarksIdentifiers,
        period: selectedPeriod,
        windowValue,
        initialApplication,
        currentPortfolioId,
        selectedCustomDateRange:
          selectedPeriod === "DATA_PERSONALIZADA"
            ? selectedCustomDateArray
            : undefined,
        consolidated: selectedConsolidated,
        competence: selectedCompetence,
      },
      { arrayFormat: "separator", arrayFormatSeparator: "," }
    );
    const completeURL = process.env.REACT_APP_SHARED_URL_BASE
      ? `${process.env.REACT_APP_SHARED_URL_BASE}?${queryString}`
      : `${window.location.protocol}//${window.location.host}/shared?${queryString}`;

    return completeURL;
  }, [
    benchmarksIdentifiers,
    currentPortfolioId,
    initialApplication,
    portfolioIdentifiers,
    selectedCustomDateArray,
    selectedPeriod,
    windowValue,
    selectedConsolidated,
    selectedCompetence,
  ]);

  const handleCopyLink = (): void => {
    copyToClipboard(generateShareURL());
    setError(Boolean(state.error));
    setOpenPopover(true);
    timerRef.current = setTimeout(() => setOpenPopover(false), 300_000);
  };

  const handleClosePopover = (): void => {
    if (timerRef.current !== null) clearTimeout(timerRef.current);
    setOpenPopover(false);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current !== null) clearTimeout(timerRef.current);
    };
  }, []);

  const handleQRCode = (): void => {
    setIsQRCodeDialogOpen(true);
  };

  const { userData } = useUserData();
  const { superCarteiraFeature } = useSuperCarteiraFeature();

  const handleSavePortfolio = (): void => {
    const newData = {
      id: portfolioIdentifiers[0],
      benchmark: benchmarksIdentifiers,
      period: selectedPeriod,
      windowValue,
      initialApplication,
      currentPortfolioId,
      selectedCustomDateRange:
        selectedPeriod === "DATA_PERSONALIZADA"
          ? selectedCustomDateArray
          : null,
      consolidated: selectedConsolidated,
      competence: selectedCompetence,
    } as unknown as UserDataType["portfolios"]["supercarteira"];

    saveUserData(userData.email, newData, superCarteiraFeature);
  };

  useEffect(() => {
    if (userDataFromSaving === "error") {
      setSaveError(true);
      setSaveSuccess(false);
    } else if (userDataFromSaving !== null) {
      setSaveError(false);
      setSaveSuccess(true);
    }
  }, [userDataFromSaving]);

  const handleCloseSaveInfo = (): void => {
    setSaveError(false);
    setSaveSuccess(false);
  };

  return (
    <SGridContainer>
      {theme.generateQRCodeButton.show ? (
        <SButton onClick={handleQRCode}>
          <SQrCodeIcon />
          {t("share.generateQrCode")}
        </SButton>
      ) : null}

      {theme.copySharedLinkButton.show ? (
        <SButton onClick={handleCopyLink}>
          <span style={{ height: "24px" }} ref={gridRef}>
            <SContentCopyIcon />
          </span>
          {t("share.copyLink")}
        </SButton>
      ) : null}

      {theme.savePortfolioButton.show ? (
        <SButton onClick={handleSavePortfolio} disabled={isSavingUserData}>
          <span style={{ height: "24px" }} ref={gridRef}>
            <SContentSaveIcon />
          </span>
          <span style={{ width: "140px" }}>
            {isSavingUserData ? (
              <SLinearProgress variant="indeterminate" color="inherit" />
            ) : (
              t("share.save")
            )}
          </span>
        </SButton>
      ) : null}

      {/* Copied popover for "Copy" feature */}
      <SPopover
        open={openPopover}
        anchorEl={gridRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ClickAwayListener onClickAway={handleClosePopover}>
          <SPopoverMessageContainer $error={error}>
            <SClosePopoverButton onClick={handleClosePopover}>
              <SClosePopoverIcon />
            </SClosePopoverButton>
            <SPopoverMessage>
              <CheckCircleOutline />
              {error ? t("share.failedCopyingLink") : t("share.linkCopied")}
            </SPopoverMessage>
          </SPopoverMessageContainer>
        </ClickAwayListener>
      </SPopover>

      <SPopover
        open={saveError}
        anchorEl={gridRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ClickAwayListener onClickAway={handleCloseSaveInfo}>
          <SPopoverMessageContainer
            $error={saveError || userDataFromSaving === "error"}
          >
            <SClosePopoverButton onClick={handleCloseSaveInfo}>
              <SClosePopoverIcon />
            </SClosePopoverButton>
            <SPopoverMessage>
              <ErrorOutline />
              {t(`share.saveError`)}
            </SPopoverMessage>
          </SPopoverMessageContainer>
        </ClickAwayListener>
      </SPopover>

      <SPopover
        open={saveSuccess}
        anchorEl={gridRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ClickAwayListener onClickAway={handleCloseSaveInfo}>
          <SPopoverMessageContainer $error={false}>
            <SClosePopoverButton onClick={handleCloseSaveInfo}>
              <SClosePopoverIcon />
            </SClosePopoverButton>
            <SPopoverMessage>
              <CheckCircleOutline />
              {t(`share.saveSuccess`)}
            </SPopoverMessage>
          </SPopoverMessageContainer>
        </ClickAwayListener>
      </SPopover>

      {/* QR Code dialog */}
      <QRCodeDialog
        open={isQRCodeDialogOpen}
        onClose={() => setIsQRCodeDialogOpen(false)}
        generateURL={() => generateShareURL()}
      />
    </SGridContainer>
  );
};
