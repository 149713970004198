import React from "react";
import { Link } from "@mui/material";
import { SGridContainer, SPowerdByText } from "./styles";
import { ReactComponent as QuantumLogo } from "../../../assets/logoQuantum.svg";

export const QuantumBanner: React.FC = () => {
  return (
    <SGridContainer>
      <SPowerdByText>Powered by:</SPowerdByText>
      <Link href="https://www.quantumfinance.com.br" target="_blank">
        <QuantumLogo />
      </Link>
    </SGridContainer>
  );
};
