import {
  CircularProgress,
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@mui/material";
import styled from "styled-components";

export const STableContainer = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: 100% !important;
    padding: 0 8px 19px;
  }
`;

export const SCircularProgress = styled(CircularProgress)`
  && {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.addButtonSecondary};
  }
`;

export const SGridRow1 = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const STableTitle = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 18px;
  }
`;

export const SGridRow2 = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.periodButtonDark};
    width: 100%;
    padding-bottom: 11px;
  }
`;

export const SGridRow3 = styled(Grid)`
  && {
    overflow-x: auto;
    width: 100%;
    height: 100%;
    min-height: 50px;

    &::-webkit-scrollbar {
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6d3d3;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-button:start:increment {
      width: 0%;
      display: block;
      background: transparent;
    }
  }
`;

export const STable = styled(Table)`
  && {
    background-color: ${({ theme }) => theme.colors.bgPrimary};
  }
`;

export const STableHead = styled(TableHead)`
  && {
  }
`;

export const STableBody = styled(TableBody)`
  && {
  }
`;

export const SHeaderTableRow = styled(TableRow)`
  && {
    align-items: center;
    min-height: 32px;
    background-color: ${({ theme }) => theme.colors.bgGray};
    color: ${({ theme }) => theme.colors.textDefault};
  }
`;

export const STableRow = styled(TableRow)`
  && {
    align-items: center;
    min-height: 32px;
  }
`;

export const SHeaderCell = styled(TableCell)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textDefault};
    border-bottom: 0px;
    padding: 8px;
    height: 100%;
    text-align: center;
  }
`;

export const SHeaderCellWithBorder = styled(SHeaderCell)`
  && {
    padding: 0;

    &:after {
      content: "";
      display: block;
      width: 95%;
      margin: 8px auto 0;
      border-bottom: 1px solid black;
    }
  }
`;

export const SFirstColumnHeaderCell = styled(TableCell)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textDefault};
    border-bottom: 0px;
    padding: 8px;
    padding-left: 28px;
    height: 100%;
    text-align: left;
    min-width: fit-content;
  }
`;

export const SCell = styled(TableCell)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.periodButtonDark};
    border-bottom: 0px;
    padding: 8px;
    height: 100%;
    text-align: center;
  }
`;

export const SFirstColumnCell = styled(TableCell)`
  && {
    display: flex;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.periodButtonDark};
    border-bottom: 0px;
    padding: 8px;
    height: 100%;
    text-align: left;
    min-width: fit-content;
    align-items: center;
  }
`;

export const SCellContent = styled(Grid)`
  && {
    display: inline-block;
    max-width: fit-content;
    width: fit-content;
    min-width: fit-content;
    white-space: nowrap;
  }
`;

interface SBarPropsTypes {
  barColor: string;
}

export const SBar = styled.div<SBarPropsTypes>`
  height: 16px;
  width: 4px;
  min-width: 4px;
  content: "";
  background-color: ${({ barColor }) => barColor || "black"};
  margin-right: 10px;
`;
