import styled from "styled-components";

import { Typography } from "@mui/material";

export const STypography = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
  }
`;
