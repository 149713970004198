import { Grid, Typography, IconButton, Popover } from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import styled from "styled-components";

export const SGridOuterContainer = styled(Grid)`
  && {
    overflow: scroll;
    height: ${({ theme }) =>
      theme.navbar.show ? "calc(100vh - 44px)" : "calc(100vh + 15px)"};

    &::-webkit-scrollbar {
      width: 10px;
      margin-top: -10%;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6d3d3;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-button:start:increment {
      height: 0%;
      display: block;
      background: transparent;
    }
  }
`;

export const SGridContainer = styled(Grid)`
  && {
    padding-left: 32px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    overflow-y: none;
  }
`;

export const SGridColumnLeft = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    width: 100%;
    height: ${({ theme }) =>
      theme.navbar.show ? "calc(100vh - 15px - 84px)" : "calc(100vh - 15px)"};
  }
`;

export const SGridColumnRight = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.bgGray};
    min-width: 405px;
    max-width: 405px;
    height: ${({ theme }) =>
      theme.navbar.show ? "calc(100vh - 84px)" : "calc(100vh)"};
  }
`;

export const SSearchArea = styled(Grid)`
  && {
    width: 100%;
    padding-right: 32px;
  }
`;

export const STitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    line-height: 33px;
    margin-top: 24px;
    padding: 0;
  }
`;

export const SMessage = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    margin-top: 4px;
    padding-right: 32px;
    margin-bottom: 0px;
    width: 100%;
  }
`;

export const SGridLocaleFloatingMenu = styled(Grid)`
  && {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 9999;
  }
`;

export const SGridQuantumBanner = styled(Grid)`
  && {
    width: 100%;
    margin-top: ${({ theme }) => (theme.navbar.show ? "-25px" : "0")};
    z-index: 9999;
    height: ${({ theme }) =>
      theme.navbar.show ? "calc(76px + 25px)" : "76px"};
  }
`;

export const SPopover = styled(Popover)`
  && {
    top: ${({ theme }) => (theme.navbar.show ? "58px" : "0px")};
  }
`;

export const SPopoverMessageContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${({ theme }) =>
      theme.colors.snackbarMessageError} !important;
    min-height: 58px;
    border-radius: 5px;
    padding: 8px;
    padding-right: 40px;
    padding-left: 30px;
  }
`;

export const SPopoverMessage = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    grid-gap: 10px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textWhite};
  }
`;

export const SClosePopoverButton = styled(IconButton)`
  && {
    position: absolute;
    top: -4px;
    right: -4px;
  }
`;

export const SClosePopoverIcon = styled(CancelRoundedIcon)`
  && {
    color: ${({ theme }) => theme.colors.textWhite};
    font-size: 20px;
  }
`;
