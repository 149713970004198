import styled from "styled-components";
import { Grid, Button } from "@mui/material";

export const SGrid = styled(Grid)`
  && {
    display: flex;
    background-color: ${({ theme }) => theme.colors.addButtonPrimary};
    width: 100%;
    min-height: 56px;
    z-index: 1000;
  }
`;

export const SButton = styled(Button)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: none;
    color: ${({ theme }) => theme.colors.addButtonText};
    width: 100%;
  }
`;
