import React from "react";
import { useTranslation } from "react-i18next";
import { PortfolioEditor } from "../../components/shared";
import { PortfolioPlaceHolder } from "../../components";
import { useCurrentPortfolio } from "../../store";
import {
  SGridColumnLeft,
  SGridColumnRight,
  SGridContainer,
  SMessage,
  STitle,
} from "./styles";

export const PortfolioEditorPage: React.FC = () => {
  const { t } = useTranslation();
  const { selectedPortfolio } = useCurrentPortfolio();

  return (
    <SGridContainer>
      <SGridColumnLeft>
        <STitle>{t("editorPortfolioAssets")}</STitle>
        <SMessage>{t("editorPortfolioAssetsMessage")}</SMessage>
      </SGridColumnLeft>
      <SGridColumnRight>
        {selectedPortfolio === null ? (
          <PortfolioPlaceHolder />
        ) : (
          <PortfolioEditor />
        )}
      </SGridColumnRight>
    </SGridContainer>
  );
};
