import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { parse as qsParse } from "query-string";
import { useFetchSharedStoreByLink } from "../../api";
import { SGridContainer, SCircularProgress, SProgressMessage } from "./styles";

export const PortfolioShared: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { fetch: fetchSharedLinkData } = useFetchSharedStoreByLink();

  useEffect(
    () => {
      const fetch = fetchSharedLinkData;
      fetch(qsParse(search, { arrayFormat: "comma", parseNumbers: true }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <SGridContainer container>
      <SProgressMessage>
        {`${t("share.loadingSharedPortfolio")}...`}
      </SProgressMessage>
      <SCircularProgress />
    </SGridContainer>
  );
};
