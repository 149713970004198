import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  Popover,
  Typography,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    height: 100vh;
    width: 100% !important;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SProgressMessage = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.textDefault};
    margin-bottom: 20px;
  }
`;

export const SCircularProgress = styled(CircularProgress)`
  && {
    color: ${({ theme }) => theme.colors.addButtonSecondary};
  }
`;

export const STitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    line-height: 33px;
    margin-top: 24px;
    padding: 0;
  }
`;

export const SMessage = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    margin-top: 4px;
    padding-right: 32px;
    margin-bottom: 0px;
    width: 100%;
  }
`;

export const SFormControl = styled(FormControl)`
  && {
    width: 100%;
  }
`;

export const SInput = styled(Input)`
  && {
    width: 100%;
    height: 48px;
    margin: 0;
    margin-top: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.bgGray || "gray"};

    &:hover {
      cursor: default;
    }

    &.Mui-focused {
      border: 1px solid ${({ theme }) => theme.colors.textDark || "#254057"};
    }

    input:-webkit-autofill {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.colors.bgGray || "gray"}
        inset;
    }
  }
`;

export const SImport = styled(Button)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme, disabled }) =>
      disabled ? "white !important" : theme.colors.addButtonText};
    background-color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.addButtonSecondary
        : theme.colors.addButtonPrimary};
    text-transform: none;
    white-space: nowrap;
    height: 36px;
    min-width: fit-content;
    width: 154px;
    margin-top: 26.5px;
    align-self: flex-end;

    &:hover {
      background-color: ${({ theme }) => theme.colors.addButtonSecondary};
    }
  }
`;

export const SPopover = styled(Popover)`
  && {
    top: ${({ theme }) => (theme.navbar.show ? "34px" : "0px")};
    left: -28px;
  }
`;

export const SPopoverMessageContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${({ theme }) =>
      theme.colors.snackbarMessageError} !important;
    min-height: 58px;
    border-radius: 5px;
    padding: 8px;
    padding-right: 40px;
    padding-left: 30px;
  }
`;

export const SPopoverMessage = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    grid-gap: 10px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textWhite};
  }
`;

export const SClosePopoverButton = styled(IconButton)`
  && {
    position: absolute;
    top: -4px;
    right: -4px;
  }
`;

export const SClosePopoverIcon = styled(CancelRoundedIcon)`
  && {
    color: ${({ theme }) => theme.colors.textWhite};
    font-size: 20px;
  }
`;
