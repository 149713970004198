import {
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const STitle = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.textDefault};
    margin: 0;
    padding: 0;
    margin-right: 32px;
  }
`;

export const SButtonGroup = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
  }
`;

export const SSelect = styled(Select)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    height: 40px;
    width: 295px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.textDefault};
    background-color: ${({ theme }) => theme.colors.bgGray};
    margin-right: 32px;
    border-radius: 2px;

    & .MuiSelect-select:focus {
      background-color: ${({ theme }) => theme.colors.bgGray};
    }
  }
`;

export const SMenuItem = styled(MenuItem)`
  && {
  }
`;

export const SGridLoading = styled(Grid)`
  && {
    height: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

export const SLinearProgress = styled(LinearProgress)`
  && {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 40px;
    width: 295px;
    color: ${({ theme }) => theme.colors.addButtonSecondary};
  }
`;
