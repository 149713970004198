import { Grid, Box, Typography } from "@mui/material";
import styled from "styled-components";

interface SGridPropsType {
  $isMatchedSearch: boolean;
}

export const SGrid = styled(Box)<SGridPropsType>`
  && {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: ${({ $isMatchedSearch }) =>
      !$isMatchedSearch ? "center" : "flex-start"};
    width: 100%;
  }
`;

export const SGridMappedResults = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const SGridNoResults = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 300px;
    color: ${({ theme }) => theme.colors.textFaded};
  }
`;

interface SCategorySeparatorLineType {
  $barColor: string;
}

export const SCategorySeparatorLine = styled(
  Typography
)<SCategorySeparatorLineType>`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    border-bottom: 1px solid ${({ $barColor }) => $barColor};
    margin-bottom: 6px;
    color: ${({ $barColor }) => $barColor};
  }
`;
