import React, { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { indexOf, mergeAll } from "ramda";
import { useRafLoop } from "react-use";
import { useTheme } from "styled-components";
import { useFetchHistoricRentability } from "../../../api";
import {
  useAllPortfolios,
  useBenchmarks,
  useCustomDateRange,
  usePeriod,
  useShowAssets,
} from "../../../store";
import { RentabilityTableRow } from "./RentabilityTableRow/RentabilityTableRow";
import {
  STableContainer,
  // SGridRow1,
  SGridRow2,
  SGridRow3,
  STable,
  STableBody,
  // STableTitle,
  SHeaderTableRow,
  STableRow,
  SCell,
  SButtonGroup,
  SButton,
  SHeaderCell,
  SFirstColumnHeaderCell,
  SCircularProgress,
  SCellContent,
} from "./styles";
import { useAssetColor, useBenchmarkColor } from "../../../utils";

export const RentabilityTable: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { allPortfolios } = useAllPortfolios();
  const [toggle, setToggle] = useState(true);
  const { selectedPeriod } = usePeriod();
  const { isAssetsShowing } = useShowAssets();
  const {
    loading: isLoading,
    data: rentabilityTableData,
    fetch: fetchRentabilityTableData,
  } = useFetchHistoricRentability();
  const { selectedBenchmarks } = useBenchmarks();
  const { generateAssetColor } = useAssetColor();
  const { generateBenchmarkColor } = useBenchmarkColor();
  const [columnPaddings, setColumnPaddings] = useState(Array(8).fill("8px"));

  const monthRef = useRef<HTMLDivElement | null>(null);
  const yearToDateRef = useRef<HTMLDivElement | null>(null);
  const sixMonthsRef = useRef<HTMLDivElement | null>(null);
  const yearRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLDivElement | null>(null);
  const participationRef = useRef<HTMLDivElement | null>(null);
  const initialBalanceRef = useRef<HTMLDivElement | null>(null);
  const grossBalanceRef = useRef<HTMLDivElement | null>(null);

  useRafLoop(() => {
    setColumnPaddings(
      [
        monthRef.current?.offsetLeft,
        yearToDateRef.current?.offsetLeft,
        sixMonthsRef.current?.offsetLeft,
        yearRef.current?.offsetLeft,
        titleRef.current?.offsetLeft,
        participationRef.current?.offsetLeft,
        initialBalanceRef.current?.offsetLeft,
        grossBalanceRef.current?.offsetLeft,
      ].map((paddingWidth) => `${paddingWidth}px`)
    );
  });

  const portfoliosAssets = useMemo(
    () =>
      mergeAll(
        Object.keys(allPortfolios).map(
          (key) => allPortfolios[Number(key)].assets
        )
      ),
    [allPortfolios]
  );

  const handleToggle = (): void => {
    setToggle((previousState) => !previousState);
  };

  const { selectedCustomDateRange } = useCustomDateRange();

  useEffect(
    () => {
      fetchRentabilityTableData(
        allPortfolios,
        selectedBenchmarks,
        selectedPeriod,
        toggle ? "RETORNO" : "PORCENTAGEM_BENCHMARK_RETORNO"
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allPortfolios,
      selectedPeriod,
      toggle,
      selectedBenchmarks,
      selectedCustomDateRange,
    ]
  );

  return (
    <STableContainer>
      {/* Disabled Feature: Table Title */}
      {/* <SGridRow1>
        <STableTitle>{t("rentabilityTable.title")}</STableTitle>
      </SGridRow1> */}
      <SGridRow2>
        {t("rentabilityTable.return")}:
        {theme.return.showReturnButtons ? (
          <SButtonGroup>
            <SButton $isSelected={toggle === true} onClick={handleToggle}>
              {t("rentabilityTable.return")}
            </SButton>
            <SButton $isSelected={toggle === false} onClick={handleToggle}>
              % CDI
            </SButton>
          </SButtonGroup>
        ) : null}
      </SGridRow2>
      <SGridRow3>
        <STable>
          <colgroup>
            <col style={{ width: "20%" }} />
          </colgroup>
          <STableBody>
            <SHeaderTableRow key="rentability-header">
              <SFirstColumnHeaderCell>{t("portfolio")}</SFirstColumnHeaderCell>

              <SHeaderCell align="center">
                <SCellContent ref={monthRef}>
                  {t("rentabilityTable.month")}
                </SCellContent>
              </SHeaderCell>

              <SHeaderCell align="center">
                <SCellContent ref={yearToDateRef}>
                  {t("rentabilityTable.yearToDate")}
                </SCellContent>
              </SHeaderCell>

              <SHeaderCell align="center">
                <SCellContent ref={sixMonthsRef}>
                  {t("rentabilityTable.sixMonths")}
                </SCellContent>
              </SHeaderCell>

              <SHeaderCell align="center">
                <SCellContent ref={yearRef}>
                  {t("rentabilityTable.year")}
                </SCellContent>
              </SHeaderCell>
              <SHeaderCell align="center">
                <SCellContent ref={titleRef}>
                  {t("periodButton.title")}
                </SCellContent>
              </SHeaderCell>

              <SHeaderCell align="center">
                <SCellContent ref={participationRef}>
                  {t("rentabilityTable.participation")}
                </SCellContent>
              </SHeaderCell>

              <SHeaderCell align="center">
                <SCellContent ref={initialBalanceRef}>
                  {t("rentabilityTable.initialBalance")}
                </SCellContent>
              </SHeaderCell>

              <SHeaderCell align="center">
                <SCellContent ref={grossBalanceRef}>
                  {t("rentabilityTable.grossBalance")}
                </SCellContent>
              </SHeaderCell>
            </SHeaderTableRow>

            {isLoading ? (
              <STableRow key="rentability-progress-key">
                <SCell colSpan={9}>
                  <SCircularProgress />
                </SCell>
              </STableRow>
            ) : (
              <React.Fragment key="rentability-assets-key">
                {Object.keys(allPortfolios).map(
                  (portfolioKey) =>
                    rentabilityTableData?.[portfolioKey] && (
                      <React.Fragment key={`rentability-${portfolioKey}`}>
                        <RentabilityTableRow
                          key={portfolioKey}
                          large
                          paddingRights={columnPaddings}
                          asset={{
                            ...rentabilityTableData[portfolioKey],
                            key: portfolioKey,
                            color: generateAssetColor(Number(portfolioKey)),
                          }}
                        />
                        {isAssetsShowing &&
                          Object.keys(
                            rentabilityTableData[portfolioKey].assets
                          ).map((assetKey) => (
                            <RentabilityTableRow
                              key={`rentability-${assetKey}`}
                              paddingRights={columnPaddings}
                              asset={{
                                ...rentabilityTableData[portfolioKey].assets[
                                  assetKey
                                ],

                                key: assetKey,
                                color: generateAssetColor(
                                  indexOf(
                                    assetKey,
                                    Object.keys(portfoliosAssets)
                                  ) + Object.keys(allPortfolios).length
                                ),
                              }}
                            />
                          ))}
                      </React.Fragment>
                    )
                )}
                {Object.keys(selectedBenchmarks).map(
                  (benchmarkKey) =>
                    rentabilityTableData?.[benchmarkKey] && (
                      <RentabilityTableRow
                        key={`rentability-${benchmarkKey}`}
                        paddingRights={columnPaddings}
                        asset={{
                          ...rentabilityTableData[benchmarkKey],
                          key: benchmarkKey,
                          color: generateBenchmarkColor(
                            indexOf(
                              benchmarkKey,
                              Object.keys(selectedBenchmarks)
                            )
                          ),
                        }}
                      />
                    )
                )}
              </React.Fragment>
            )}
          </STableBody>
        </STable>
      </SGridRow3>
    </STableContainer>
  );
};
