import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  Input,
} from "@mui/material";
import styled from "styled-components";

export const SGridCard = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.loginCardBg};
    color: ${({ theme }) => theme.colors.loginCardFg};
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    height: 556px;
    width: 435px;
    margin: 0;
    padding: 0;
    margin-right: 0;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.loginCardBorder};
    border-radius: 15px;
  }
`;

export const SLogoImg = styled.img`
  && {
    max-height: 104.4px;
    max-width: 300.75px;
    margin-top: 54px;
  }
`;

export const SMessageLine1 = styled.div`
  && {
    margin-top: 36.6px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15px;
    text-align: center;
  }
`;

export const SMessageLine2 = styled.div`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15px;
    text-align: center;
  }
`;

export const SLabel = styled.div`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    margin-top: 39px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15px;
    width: 100%;
    text-align: left;
    color: ${({ theme }) => theme.colors.loginCardInsert};
    padding-left: 60px;
    padding-right: 60px;
  }
`;

export const SFormControl = styled(FormControl)`
  && {
    width: 100%;
  }
`;

export const SInput = styled(Input)`
  && {
    --login-input-x-padding: 60px;

    width: calc(100% - (2 * var(--login-input-x-padding)));
    height: 60px;
    margin-top: 15px;
    margin-left: var(--login-input-x-padding);
    margin-right: var(--login-input-x-padding);
    padding-left: 10px;
    padding-right: 10px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.loginCardEmailBg || "gray"};
    color: ${({ theme }) => theme.colors.loginCardEmailFg};

    input.Mui-disabled {
      color: gray !important;
      -webkit-text-fill-color: unset;
    }

    &:hover {
      cursor: default;
    }

    &.Mui-focused {
      border: 1px solid
        ${({ theme }) => theme.colors.loginCardBorder || "#254057"};
    }

    input:-webkit-autofill {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.colors.bgGray || "gray"}
        inset;
    }
  }
`;

export const SButton = styled(Button)`
  && {
    --login-button-x-padding: 60px;

    height: 60px;
    background-color: ${({ theme }) =>
      theme.colors.loginCardOkButtonBg || "blue"};
    color: ${({ theme }) => theme.colors.loginCardOkButtonFg || "white"};
    font-weight: 600;
    font-size: 14px;
    margin-top: 15px;
    width: calc(100% - (2 * var(--login-button-x-padding)));
    margin-left: var(--login-button-x-padding);
    margin-right: var(--login-button-x-padding);

    &:hover {
      filter: brightness(110%);
      background-color: ${({ theme }) =>
        theme.colors.loginCardOkButtonBg || "blue"};
      color: ${({ theme }) => theme.colors.loginCardOkButtonFg || "white"};
    }
  }
`;

export const SError = styled(Alert)`
  && {
    --login-error-x-padding: 60px;

    margin-top: 20px;

    background-color: ${({ theme }) => theme.colors.loginCardBg};
    color: ${({ theme }) => theme.colors.alertRed};

    width: calc(100% - (2 * var(--login-error-x-padding)));
    margin-left: var(--login-error-x-padding);
    margin-right: var(--login-button-x-padding);
  }
`;

export const SGridLoading = styled(Grid)`
  && {
    height: 400px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

export const SCircularProgress = styled(CircularProgress)`
  && {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.addButtonSecondary};
  }
`;

export const SRememberMe = styled.div`
  && {
    --login-button-x-padding: 60px;

    margin-left: var(--login-button-x-padding);

    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 2px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 12px;
    width: fit-content;
    letter-spacing: 0.15px;
  }
`;

export const SCardCheckBox = styled(Checkbox)`
  && {
    width: 13px;
    height: 13px;
    transform: scale(0.722);
    align-self: flex-start;
    margin: 0;
    margin-left: 2px;
    margin-right: 7px;
    margin-bottom: 4px;
    padding: 0;
    color: ${({ theme, checked }) =>
      checked ? theme.login.checkbox.checked : theme.login.checkbox.unchecked};
    background-color: ${({ theme }) => theme.login.checkbox.unchecked};
    border-radius: 0;
    border: 8px solid white;

    &:hover {
      color: ${({ theme, checked }) =>
        checked
          ? theme.login.checkbox.checked
          : theme.login.checkbox.unchecked};
      background-color: ${({ theme }) => theme.login.checkbox.unchecked};
    }
  }
`;

export const SEmailSent = styled.div`
  && {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const SSuccess = styled(Alert)`
  && {
    --login-error-x-padding: 0px;

    background-color: ${({ theme }) => theme.colors.loginCardBg};
    color: ${({ theme }) => theme.colors.loginCardOkButtonFg};

    width: calc(100% - (2 * var(--login-error-x-padding)) - 10px);

    color: ${({ theme }) => theme.colors.loginCardInsert};
    font-size: 18px;

    overflow-wrap: break-word;

    .MuiAlert-icon {
      color: ${({ theme }) => theme.colors.loginCardInsert};
    }
  }
`;
