import { drop, take } from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { useRemoteBenchmarks } from "../../../api";
import { BenchmarkAssetType, useBenchmarks } from "../../../store";
import { translateCategory } from "../../../utils";
import {
  SAutocomplete,
  SButton,
  SButtonGroup,
  SCloseIcon,
  SGridContainer,
  SInputAdornment,
  SLabel,
  SLinearProgress,
  SOthersButton,
  SSearchIcon,
  STextField,
} from "./styles";

interface BenchmarksSelectorPropsType {
  setBenchmarksOthers: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BenchmarksSelector: React.FC<BenchmarksSelectorPropsType> = ({
  setBenchmarksOthers,
}) => {
  const [serverAvailableBenchmarks, setServerAvailableBenchmarks] = useState<
    BenchmarkAssetType[]
  >([]);
  const [serverDefaultBenchmarks, setServerDefaultBenchmarks] = useState<
    BenchmarkAssetType[]
  >([]);
  const [serverMoreBenchmarks, setServerMoreBenchmarks] = useState<
    BenchmarkAssetType[]
  >([]);
  const [toggleBetweenButtonAndMenu, setToggleBetweenButtonAndMenu] =
    useState(true);
  const { selectedBenchmarks, addSelectedBenchmark, removeSelectedBenchmark } =
    useBenchmarks();
  const { loading: isBenchmarkFetchLoading, data: benchmarks } =
    useRemoteBenchmarks();
  const { t } = useTranslation();
  const theme = useTheme();

  const showOthersBenchmarks = theme.benchmarks.others.show;

  useEffect(() => {
    setServerAvailableBenchmarks(benchmarks);
    // Take first N (theme defined) benchmarks from server as default benchmarks
    setServerDefaultBenchmarks(
      take(theme.benchmarks.primary.quantity, benchmarks)
    );
    setServerMoreBenchmarks(
      drop(theme.benchmarks.primary.quantity, benchmarks)
    );
  }, [benchmarks, theme]);

  const generateBenchmarkId = (benchmarkItem: BenchmarkAssetType): string =>
    `${String(benchmarkItem.assetType)}❤❤❤${String(benchmarkItem.identifier)}`;

  const isBenchmarkSelected = useCallback(
    (benchmarkItem): boolean => {
      const benchmarkId = generateBenchmarkId(benchmarkItem);
      const selectedBenchmarksIds = Object.keys(selectedBenchmarks);

      return selectedBenchmarksIds.includes(benchmarkId);
    },
    [selectedBenchmarks]
  );

  const handleSelection = (benchmarkItem: BenchmarkAssetType): void => {
    if (isBenchmarkSelected(benchmarkItem)) {
      removeSelectedBenchmark(generateBenchmarkId(benchmarkItem));
    } else {
      addSelectedBenchmark(benchmarkItem);
    }
  };

  const handleMenuSelection = (
    _event: React.SyntheticEvent,
    benchmarkItem: unknown
  ): void => {
    if (!isBenchmarkSelected(benchmarkItem)) {
      addSelectedBenchmark(benchmarkItem as BenchmarkAssetType);
    }
    setToggleBetweenButtonAndMenu(true);
  };

  useEffect(
    () => setBenchmarksOthers(toggleBetweenButtonAndMenu),
    [toggleBetweenButtonAndMenu, setBenchmarksOthers]
  );

  return (
    <SGridContainer>
      <SLabel>{t("benchmarkButton.title")}</SLabel>
      {isBenchmarkFetchLoading ? (
        <SLinearProgress variant="indeterminate" color="inherit" />
      ) : (
        serverAvailableBenchmarks.length > 0 && (
          <SButtonGroup>
            {/* Default Benchmarks List */}
            {serverDefaultBenchmarks.length > 0 &&
              serverDefaultBenchmarks.map((benchmarkItem) => (
                <SButton
                  $isSelected={isBenchmarkSelected(benchmarkItem)}
                  key={benchmarkItem.identifier}
                  onClick={() => handleSelection(benchmarkItem)}
                >
                  {translateCategory(benchmarkItem.label)}
                </SButton>
              ))}

            {/* Extended Benchmarks List */}
            {serverMoreBenchmarks.length > 0 &&
              serverMoreBenchmarks
                .filter((benchmarkItem) => isBenchmarkSelected(benchmarkItem))
                .map((benchmarkItem) => (
                  <SButton
                    $isSelected={isBenchmarkSelected(benchmarkItem)}
                    key={benchmarkItem.identifier}
                    onClick={() => {
                      handleSelection(benchmarkItem);
                      setToggleBetweenButtonAndMenu(false);
                    }}
                  >
                    {translateCategory(benchmarkItem.label)}

                    <SCloseIcon
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSelection(benchmarkItem);
                        setToggleBetweenButtonAndMenu(true);
                      }}
                    />
                  </SButton>
                ))}
            {showOthersBenchmarks &&
              (toggleBetweenButtonAndMenu ? (
                !serverMoreBenchmarks.every((benchmarkItem) =>
                  isBenchmarkSelected(benchmarkItem)
                ) && (
                  <SOthersButton
                    key="others"
                    onClick={() => setToggleBetweenButtonAndMenu(false)}
                  >
                    {t("benchmarkButton.others")}
                  </SOthersButton>
                )
              ) : (
                <SAutocomplete
                  sx={{ height: 40, paddingTop: 0 }}
                  options={serverMoreBenchmarks}
                  getOptionLabel={(option) =>
                    (option as BenchmarkAssetType).label
                  }
                  blurOnSelect
                  noOptionsText={t("benchmarkButton.noMatches")}
                  onChange={handleMenuSelection}
                  renderInput={(params) => (
                    <STextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      autoFocus
                      placeholder={t("benchmarkButton.search")}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <SInputAdornment position="start">
                            <SSearchIcon />
                          </SInputAdornment>
                        ),
                        disableUnderline: true,
                        sx: { height: 40, paddingLeft: 1, paddingRight: 3 },
                      }}
                    />
                  )}
                />
              ))}
          </SButtonGroup>
        )
      )}
    </SGridContainer>
  );
};
