import { Button, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components";

interface SButtonPropTypes {
  selected: boolean;
}

export const SButton = styled(Button)<SButtonPropTypes>`
  && {
    height: 40px;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    padding: 10px;
    border: 1px solid
      ${({ theme, selected }) =>
        selected ? theme.colors.selectedBlue : theme.colors.textDefault};
    border-radius: 8px;
    text-transform: capitalize;
    background-color: ${({ theme, selected }) =>
      selected ? theme.colors.selectedLightBlue : "inherit"};

    &:hover {
      background-color: ${({ theme }) => theme.colors.portfolioButtonHover};
    }
  }
`;

interface STitlePropsTypes {
  $chipMode: boolean;
}

export const STitle = styled(Typography)<STitlePropsTypes>`
  && {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    font-weight: ${({ $chipMode }) => ($chipMode ? "400" : "600")};
    line-height: 16px;
    color: #343a40;
  }
`;

interface SBarPropsTypes {
  barColor: string;
}

export const SBar = styled.div<SBarPropsTypes>`
  height: 16px;
  width: 4px;
  min-width: 4px;
  content: "";
  background-color: ${({ barColor }) => barColor || "black"};
`;

export const SEditIcon = styled(EditIcon)`
  && {
    margin: 0;
    margin: 10px 0px;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 12px;
    color: ${({ theme }) => theme.colors.textDefault};
  }
`;
