import { Button, Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const STitle = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.textDefault};
    margin: 0;
    padding: 0;
    margin-right: 32px;
  }
`;

export const SButtonGroup = styled(Grid)`
  && {
    display: flex;
    grid-gap: 8px;
  }
`;

interface SButtonPropsType {
  $isSelected: boolean;
}

export const SButton = styled(Button)<SButtonPropsType>`
  && {
    text-transform: capitalize;
    white-space: nowrap;
    word-break: keep-all;
    min-width: 70px;
    max-width: 70px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 40px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: ${({ $isSelected }) => ($isSelected ? 600 : "normal")};
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.periodButtonSelectedFg
        : theme.colors.periodButtonDark};
    background: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.periodButtonSelected
        : theme.colors.periodButtonLight};
    border: 1px solid ${({ theme }) => theme.colors.periodButtonDark};
    box-sizing: border-box;
    border-radius: 2px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.periodButtonHover};
      color: ${({ theme }) => theme.colors.periodButtonHoverFg};
      border: 1px solid ${({ theme }) => theme.colors.periodButtonHover};
    }
  }
`;
