import { Toolbar, MenuItem } from "@mui/material";
import styled from "styled-components";

export const SToolbar = styled(Toolbar)`
  && {
    border: none;
    margin: 0;
    padding: 0;
  }
`;

export const SMenuItem = styled(MenuItem)`
  && {
    border: none;
    margin: 0;
    padding: 0;
  }
`;
