import logo from "../assets/logo-super-carteira.png";
import loginLogo from "../assets/logo-super-carteira-login.png";

import { ThemeType } from "./defaultTheme";

const themeProps = {
  auth: {
    useAuth: true,
    checkTokenExpirationOnClientSide: false,
  },
  lang: {
    default: "pt",
  },
  currency: {
    default: "BRL",
  },
  login: {
    logo: {
      show: true,
      img: loginLogo,
    },
    checkbox: {
      checked: "#00A9FE",
      unchecked: "#FFFFFF",
    },
    splashMessageLine1: "login.supercarteira.splash1",
    splashMessageLine2: "login.supercarteira.splash2",
    insertMailMessage: "login.supercarteira.insertMail",
    okButtonMessage: "login.supercarteira.ok",
    errorMessage: "login.supercarteira.error",
    invalidEmailMessage: "login.supercarteira.invalidEmail",
    rememberThisDevice: "login.supercarteira.rememberThisDevice",
    successMessage: "login.supercarteira.success",
    authErrorMessage: "login.supercarteira.authError",
  },
  fonts: {
    primary: "Montserrat, sans-serif",
    pre: "Consolas, Liberation Mono, Menlo, Courier, monospace",
    quote: "Georgia, serif",
  },
  logo: {
    img: logo,
    height: "78px",
    padding: "0px 0px",
    align: "center",
    show: true,
  },
  navbar: {
    show: true,
    startingRoute: "/",
    supercarteiraButtons: true,
  },
  localeSettings: {
    showSettingsGearButton: false,
    showInsideLeftColumn: false,
  },
  delay: {
    textInput: 1000,
    numberInput: 3000,
  },
  assetSearchShowAllFromSelectedCategory: true,
  assetSearch: {
    funds: {
      show: false,
    },
    stocks: {
      show: false,
    },
    fixedIncome: {
      show: false,
    },
    valorization: {
      show: true,
    },
    diversification: {
      show: true,
    },
    stability: {
      show: true,
    },
    antifragility: {
      show: true,
    },
    others: {
      show: true,
    },
    createAsset: {
      show: false,
    },
  },
  benchmarks: {
    primary: {
      quantity: 4,
    },
    others: {
      show: false,
    },
  },
  portfolios: {
    singlePortfolioMode: true,
    addPortoflio: {
      show: false,
    },
    namePrefix: {
      useCustom: true,
      name: "SuperCarteira",
    },
  },
  portfolioSummary: {
    compactList: true,
    noCardIcons: true,
  },
  copySharedLinkButton: {
    show: false,
  },
  generateQRCodeButton: {
    show: true,
  },
  savePortfolioButton: {
    show: true,
  },
  chartExportingButton: {
    show: false,
  },
  defaultPeriod: {
    useCustom: true,
    key: "OTIMO",
  },
  return: {
    showReturnButtons: true,
  },
  colors: {
    navbarBg: "#0A1916",
    navbarFg: "#FFFFFF",
    navbarSelectionLine: "#D89B5E",
    textDefault: "#0A1916",
    textFaded: "rgba(37, 64, 87, 0.5)",
    textDark: "#254057",
    textGray: "rgba(51, 51, 51, 0.6)",
    textWhite: "#FFFFFF",
    textBlack: "#000000",
    textDarkGray: "#0A1916",
    bgPrimary: "white",
    bgSecondary: "gray",
    bgGray: "#F1F3F5",
    iconCyan: "#00A9FE",
    selectedBlue: "#09B4FF",
    selectedLightBlue: "#D9F2FF",
    funds: "#604AF0",
    stocks: "#c77c30",
    fixedIncome: "#123f38",
    pension: "#32A32A",
    valorization: "#2b9983",
    diversification: "#DBBF3C",
    stability: "#CD4926",
    antifragility: "#2f71af",
    others: "#8545d4",
    createAsset: "#3E85F0",
    leftover: "rgba(51, 51, 51, 0.6)",
    custom: "#3E85F0",
    addButtonPrimary: "#0A1916",
    addButtonSecondary: "#0A1916BB",
    addButtonText: "#D89B5E",
    addButtonHoverFg: "#D89B5E",
    removeButtonPrimary: "#0A1916",
    removeButtonSecondary: "rgba(223, 11, 11, 0.48)",
    removeButtonText: "#D89B5E",
    removeButtonHoverFg: "#D89B5E",
    cardBorder: "#F2F1F6",
    cardBg: "#FFFFFF",
    cardBgSelected: "#D9F2FF",
    cardCheckboxChecked: "#00A9FE",
    cardCheckboxUnchecked: "rgba(51, 51, 51, 0.25)",
    periodButtonDark: "#0A1916",
    periodButtonLight: "#FFFFFF",
    periodButtonSelected: "#0A1916",
    periodButtonSelectedFg: "#D89B5E",
    periodButtonHover: "#0A1916BB",
    periodButtonHoverFg: "#D89B5E",
    portfolioButtonHover: "#EFF1F3",
    accordionTitleBg: "#0A1916",
    accordionTitleFg: "#D89B5E",
    alertRed: "#DF0B0B",
    showAssetsButtonLight: "#FFFFFF",
    showAssetsButtonHover: "#0A1916BB",
    showAssetsButtonHoverFg: "#D89B5E",
    showAssetsButtonDark: "#333333",
    showAssetsButtonSelected: "#0A1916",
    showAssetsButtonSelectedFg: "#D89B5E",
    bgQuantumBanner: "#0A1916",
    addAssetBorder: "#00a9fe",
    addAssetTextColor: "#254057",
    addAssetBackground: "#D9F2FF",
    snackbarMessageSuccess: "#27618F",
    snackbarMessageError: "#DF0B0B",
    shareButtonLight: "#FFFFFF",
    shareButtonHover: "#0A1916",
    shareButtonDark: "#0A1916",
    shareButtonHoverFg: "#D89B5E",
    assets: [
      "#58AD69",
      "#E88564",
      "#C558FF",
      "#FF7661",
      "#1910A7",
      "#FFD261",
      "#E88564",
      "#94535A",
      "#153A2A",
      "#947853",
      "#B8295E",
      "#53D4C3",
      "#31153B",
      "#E6935C",
      "#D95D75",
      "#2E1220",
      "#C94106",
      "#078490",
      "#CA54D9",
      "#C2B400",
    ],
    deletePortfolioRed: "#DF0B0B",
    loginPageBg: "#0F1E19",
    loginCardBg: "#0A1916",
    loginCardFg: "#FFFFFF",
    loginCardBorder: "#D89B5E",
    loginCardInsert: "#D89B5E",
    loginCardEmailBg: "#102320",
    loginCardEmailFg: "#FFFFFF",
    loginCardOkButtonFg: "#FFFFFF",
    loginCardOkButtonBg: "#D89B5E",
  },
};

export const superCarteiraTheme: ThemeType = themeProps;
