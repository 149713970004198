import React, { useEffect, useRef, useState } from "react";
import { parse as qsParse } from "query-string";
import { useTranslation } from "react-i18next";
import { ErrorOutline } from "@mui/icons-material";
import { ClickAwayListener } from "@mui/material";
import {
  SGridContainer,
  STitle,
  SMessage,
  SFormControl,
  SInput,
  SImport,
  SPopoverMessageContainer,
  SClosePopoverButton,
  SClosePopoverIcon,
  SPopoverMessage,
  SPopover,
} from "./styles";
import { useSaveUserData } from "../../api";
import {
  initialState,
  useBlankPortfolio,
  UserDataType,
  useShowImportedNotifier,
  useSuperCarteiraRedirections,
  useUserData,
} from "../../store";
import { SCircularProgress } from "../PortfolioShared/styles";

export type superCarteiraFeatures = "supercarteira" | "previdencia";

type SuperCarteiraImporterPagePropsType = {
  mode: superCarteiraFeatures;
};

type InputStateType = {
  [K in superCarteiraFeatures]?: string;
};

export const SuperCarteiraImporterPage: React.FC<
  SuperCarteiraImporterPagePropsType
> = ({ mode }) => {
  const { t } = useTranslation();
  const { data, loading, fetch: saveUserData } = useSaveUserData();

  const gridRef = useRef<HTMLDivElement>(null);

  const { userData } = useUserData();

  const [inputState, setInputState] = useState<InputStateType>({});

  const [showErrorNotifier, setShowErrorNotifier] = useState(false);

  const { setShowImportedNotifier } = useShowImportedNotifier();

  const { resetToBlankPortfolio } = useBlankPortfolio();

  const { gotoFeatureWithUserData } = useSuperCarteiraRedirections();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    if (e.target?.value !== undefined && e.target?.value !== null)
      setInputState((lastState) => ({
        ...lastState,
        [mode]: e.target?.value,
      }));
  };

  const handleImportClick = (): void => {
    setShowImportedNotifier(false);

    const parsedOptionsFromLink = qsParse(
      String(inputState?.[mode]).split("?")[1],
      {
        arrayFormat: "comma",
        parseNumbers: true,
      }
    );

    if (Object.keys(parsedOptionsFromLink || {}).length <= 2) {
      setShowErrorNotifier(true);
      return;
    }

    const newData = {
      id: String(parsedOptionsFromLink?.portfolios) || null,
      benchmark: parsedOptionsFromLink?.benchmarks || [],
      competence:
        String(parsedOptionsFromLink?.competence) || initialState.competence,
      consolidated:
        String(parsedOptionsFromLink?.consolidated) ||
        initialState.consolidated,
      currentPortfolioId:
        Number(parsedOptionsFromLink?.currentPortfolioId) || 0,
      initialApplication:
        Number(parsedOptionsFromLink?.initialApplication) ||
        initialState.applicationAmount,
      period: String(parsedOptionsFromLink?.period) || initialState.period,
      selectedCustomDateRange:
        parsedOptionsFromLink?.selectedCustomDateRange || null,
      windowValue:
        Number(parsedOptionsFromLink?.windowValue) || initialState.windowValue,
    } as unknown as UserDataType["portfolios"]["previdencia"];

    saveUserData(userData.email, newData, mode);
  };

  const handleClosePopover = (): void => {
    setShowErrorNotifier(false);
  };

  useEffect(() => {
    if (data === "error") {
      setShowErrorNotifier(true);
      setShowImportedNotifier(false);
    }
    if (data !== "error" && data !== null && typeof data !== "string") {
      setShowImportedNotifier(true);
      resetToBlankPortfolio();
      gotoFeatureWithUserData(mode, data);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SGridContainer container sx={{ position: "relative" }} ref={gridRef}>
      <SPopover
        open={showErrorNotifier}
        anchorEl={gridRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ClickAwayListener onClickAway={handleClosePopover}>
          <SPopoverMessageContainer>
            <SClosePopoverButton onClick={handleClosePopover}>
              <SClosePopoverIcon />
            </SClosePopoverButton>
            <SPopoverMessage>
              <SPopoverMessage>
                <ErrorOutline />
                {t(`import.${mode}.invalidLink`)}
              </SPopoverMessage>
            </SPopoverMessage>
          </SPopoverMessageContainer>
        </ClickAwayListener>
      </SPopover>

      <STitle>{t(`import.${mode}.title`)}</STitle>
      <SMessage>{t(`import.${mode}.message`)}</SMessage>
      <SFormControl variant="standard">
        <SInput
          key={Math.random()}
          id="importUrl"
          value={inputState?.[mode] || ""}
          disabled={loading}
          onChange={handleInputChange}
          autoComplete="off"
          disableUnderline
          autoFocus
        />
        <SImport
          onClick={handleImportClick}
          disabled={loading || (inputState?.[mode] || "").length < 20}
        >
          {loading ? (
            <SCircularProgress size="30px" />
          ) : (
            t(`import.${mode}.import`)
          )}
        </SImport>
      </SFormControl>
    </SGridContainer>
  );
};
