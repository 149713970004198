import Big from "big.js";
import React, { useState } from "react";
import { PortfolioCompositionType } from "../../../../api";
import { formatPercentage } from "../../../../utils";
import {
  SColumn1,
  SColumn2,
  SGridContainer,
  SRow,
  SScrollableArea,
  STitle,
  STooltip,
} from "./styles";

interface PropTypes {
  title: string;
  compositionAssets: PortfolioCompositionType[];
  othersString: string;
}

export const WalletTable: React.FC<PropTypes> = ({
  title,
  compositionAssets,
  othersString,
}) => {
  const [isShowAll] = useState(false);
  const data = compositionAssets
    ? {
        first10: compositionAssets.slice(0, 10),
        rest: compositionAssets.slice(10),
      }
    : { first10: [], rest: [] };

  const calculateOthers = (currentData: typeof data): string => {
    const allRestParticipations = currentData.rest.map((compositionAsset) =>
      Number(compositionAsset.participation)
    );

    const restParticipationSum = allRestParticipations.reduce((acc, current) =>
      Big(Big(acc)).plus(Big(current)).toNumber()
    );

    return formatPercentage(String(restParticipationSum));
  };

  /* This feature has been disabled by request. Show/Hide all items in participation. */

  /* const handleShowAll = (): void =>
   *   setShowAll((currentShowAllState) => !currentShowAllState); */

  return (
    <SGridContainer>
      <STitle>{title}</STitle>
      <SScrollableArea>
        {data.first10.map((entry) => (
          <SRow key={`${entry.participation}-${entry.label}--key`}>
            <SColumn1>{formatPercentage(entry.participation)}</SColumn1>
            <STooltip title={entry.label} followCursor>
              <SColumn2>{entry.label}</SColumn2>
            </STooltip>
          </SRow>
        ))}

        {data.rest.length > 0 &&
          (isShowAll ? (
            <>
              {data.rest.map((entry) => (
                <SRow key={`${entry.participation}-${entry.label}--key`}>
                  <SColumn1>{formatPercentage(entry.participation)}</SColumn1>
                  <STooltip title={entry.label} followCursor>
                    <SColumn2>{entry.label}</SColumn2>
                  </STooltip>
                </SRow>
              ))}
              {/* <SRow>
                    <SColumn1 />
                    <SShowHideButton onClick={handleShowAll}>
                    Exibir apenas os 10 primeiros
                    </SShowHideButton>
		    </SRow> */}
            </>
          ) : (
            <>
              <SRow>
                <SColumn1>{calculateOthers(data)}</SColumn1>
                <STooltip title={othersString} followCursor>
                  <SColumn2>{othersString}</SColumn2>
                </STooltip>
              </SRow>
              {/* <SRow>
                  <SColumn1 />
                  <SShowHideButton onClick={handleShowAll}>
                  Exibir carteira completa
                  </SShowHideButton>
		  </SRow> */}
            </>
          ))}
      </SScrollableArea>
    </SGridContainer>
  );
};
