import {
  AccordionSummary,
  Grid,
  IconButton,
  Popover,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const SGridColumnLeft = styled(Grid)`
  && {
    position: relative;
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    padding-top: 4px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow-y: auto;
    height: ${({ theme }) =>
      theme.navbar.show ? "calc(100vh - 84px)" : "calc(100vh)"};

    &::-webkit-scrollbar {
      width: 10px;
      margin-top: -10%;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6d3d3;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-button:start:increment {
      height: 0%;
      display: block;
      background: transparent;
    }
  }
`;

export const SPaddedLine = styled(Grid)`
  && {
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
  }
`;

export const SUnpaddedLine = styled(Grid)`
  && {
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

export const SGridColumnRight = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.bgGray};
    min-width: 405px;
    max-width: 405px;
    height: ${({ theme }) =>
      theme.navbar.show ? "calc(100vh - 58px)" : "calc(100vh)"};
  }
`;

export const STitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    line-height: 33px;
    margin-top: 0;
    padding: 0;
  }
`;

export const SMessage = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
    padding-right: 32px;
  }
`;

export const SHeaderRow = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const STitleAndButtonsRow = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
`;

export const SGridButtonsRow = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0;
    margin-top: 4px;
    margin-bottom: 10px;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    width: 100%;
  }
`;

export const STabs = styled(Tabs)`
  && {
    width: 100%;
    margin-right: -50%;
    border-top: 1px solid #f1f3f5;

    .MuiTabs-indicator {
      background-color: ${({ theme }) => theme.colors.textDefault};
      height: 3px;
    }
  }
`;

export const STab = styled(Tab)`
  && {
    background: #ffffff;
    box-sizing: border-box;
    border-bottom: 3px solid transparent;
    height: 40px;
    width: 148px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    line-height: 24px;

    &.Mui-selected {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.textDefault} !important;
      border-bottom: 3px solid ${({ theme }) => theme.colors.textDefault};
      z-index: 666;
    }
  }
`;

export const SChartsArea = styled(Grid)`
  && {
    width: 100%;
    height: 800%;
    background-color: ${({ theme }) => theme.colors.bgGray};
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const SChartRow1 = styled(Grid)`
  && {
    width: 100%;
    padding: 32px;
  }
`;

export const SChartRow2 = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const SAccordionSummary = styled(AccordionSummary)`
  && {
    background-color: ${({ theme }) => theme.colors.accordionTitleBg};
    height: 44px;
    min-height: 44px !important;
    border-radius: 4px;

    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.accordionTitleFg};
      height: 24px;
      width: 24px;
    }

    .Mui-expanded {
    }
  }
`;

export const SAccordionTitle = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    height: 22px;
    color: ${({ theme }) => theme.colors.accordionTitleFg};
  }
`;

export const SGridLocalesFloatingMenu = styled(Grid)`
  && {
    position: fixed;
    top: ${({ theme }) => (theme.navbar.show ? "150px" : "70px")};
    z-index: 9999;
  }
`;

export const SPopover = styled(Popover)`
  && {
    top: ${({ theme }) => (theme.navbar.show ? "34px" : "0px")};
    left: -28px;
  }
`;

export const SPopoverMessageContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${({ theme }) =>
      theme.colors.snackbarMessageSuccess} !important;
    min-height: 58px;
    border-radius: 5px;
    padding: 8px;
    padding-right: 40px;
    padding-left: 30px;
    width: 420px;
    right: 100px;
  }
`;

export const SPopoverMessage = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    grid-gap: 10px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textWhite};
  }
`;

export const SClosePopoverButton = styled(IconButton)`
  && {
    position: absolute;
    top: -4px;
    right: -4px;
  }
`;

export const SClosePopoverIcon = styled(CancelRoundedIcon)`
  && {
    color: ${({ theme }) => theme.colors.textWhite};
    font-size: 20px;
  }
`;
