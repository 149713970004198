import { Grid } from "@mui/material";
import styled from "styled-components";

export const SFiltersGrid = styled(Grid)`
  && {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
  }
`;
