import { IconButton, Paper } from "@mui/material";
import styled from "styled-components";

export const SIconButtonMenu = styled(IconButton)`
  && {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 5px;
    margin-right: 5px;
  }
`;

export const SIconCloseMenu = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.colors.navbarFg};
    width: 100%;
    justify-content: flex-end;
  }
`;

export const SPaper = styled(Paper)`
  && {
    width: 280px;
    padding: 16px;
    height: 100%;
    border-radius: 0;
    background-color: ${({ theme }) => theme.colors.navbarBg};
  }
`;
