import styled from "styled-components";
import { Grid, Typography } from "@mui/material";

export const SGridContainer = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 55%;
    padding: 0px 32px 0px 3px;
  }
`;

export const SLegendTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    justify-content: right;
    align-items: center;
    padding-bottom: 8px;
  }
`;

export const SMessage = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    margin-top: 8px;
  }
`;

interface SGridLegendRowPropType {
  $removePadding?: boolean;
}

export const SGridLegendRow = styled(Grid)<SGridLegendRowPropType>`
  && {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding-bottom: ${({ $removePadding }) => ($removePadding ? "0px" : "8px")};
  }
`;

export const SPercentage = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    padding-left: 10px;
    min-width: 32px;
  }
`;

export const SCategory = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    padding-left: 10px;
  }
`;
