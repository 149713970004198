import React from "react";
import { useTranslation } from "react-i18next";
import {
  SCardImg,
  SEmptyBox,
  SEmptyInput,
  SGridAssets,
  SGridCard,
  SGridContainer,
  SGridDescript,
  SGridImg,
  SGridRow1,
  SGridRow2,
  SHeader,
  SHeader2,
  SMessage,
} from "./styles";
import placeHolderDonut from "../../assets/placeHolderDonut.svg";

export const PortfolioPlaceHolder: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SGridContainer>
      <SGridCard>
        <SGridRow1>
          <SGridImg>
            <SCardImg src={placeHolderDonut} alt="donut" />
          </SGridImg>
          <SGridDescript>
            <SHeader>{t("placeHolderHeader")}</SHeader>
            <SMessage>{t("placeHolderMessage")}</SMessage>
          </SGridDescript>
        </SGridRow1>
        <SGridRow2>
          <SEmptyInput /> <SEmptyInput />
        </SGridRow2>
      </SGridCard>
      <SGridAssets>
        <SHeader2>{t("placeHolderAssetsList")}</SHeader2>
        <SEmptyBox>{t("placeHolderNoAssetsSelected")}</SEmptyBox>
      </SGridAssets>
    </SGridContainer>
  );
};
