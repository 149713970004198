import { CircularProgress } from "@mui/material";
import React from "react";
import { SBackdrop } from "./styles";

export interface BackdropProps {
  open: boolean;
}

export const AuthenticationBackdrop: React.FC<BackdropProps> = ({ open }) => {
  return (
    <SBackdrop open={open}>
      <CircularProgress color="inherit" />
    </SBackdrop>
  );
};
