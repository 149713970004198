import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const SGridContainer = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.bgGray};
    padding-left: 32px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.1);
  }
`;

export const SGridCard = styled(Grid)`
  && {
    background-color: white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    height: 231px;
    margin: 0;
    padding: 0;
    margin-top: 45px;
    margin-right: 0;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SGridRow1 = styled(Grid)`
  && {
    display: flex;
    margin: 0;
    padding: 0;
    height: 80%;
    width: 100%;
  }
`;

export const SGridRow2 = styled(Grid)`
  && {
    display: flex;
    margin: 0;
    padding: 0;
    height: 20%;
    width: 100%;
    grid-gap: 32px;
    align-items: center;
    justify-content: center;
  }
`;

export const SEmptyInput = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.bgGray};
    width: 155px;
    height: 36px;
    border-radius: 4px;
  }
`;

export const SGridImg = styled(Grid)`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SCardImg = styled.img`
  && {
    z-index: 1000;
    background-color: white;
  }
`;

export const SGridDescript = styled(Grid)`
  && {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
`;

export const SGridAssets = styled(Grid)`
  && {
    padding-top: 12px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 8px;
  }
`;

export const SHeader = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    margin-top: 24px;
    padding: 0;
    width: 100%;
  }
`;

export const SHeader2 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
  }
`;

export const SMessage = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    margin-top: 8px;
    padding-right: 32px;
  }
`;

export const SEmptyBox = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 151px;
    border: 1px dashed ${({ theme }) => theme.colors.textGray};
  }
`;

export const SExpandMoreIcon = styled(ExpandMoreIcon)`
  && {
    font-size: 24px;
  }
`;
