import { Grid } from "@mui/material";
import styled from "styled-components";

export const SLoginCardContainer = styled(Grid)`
  && {
    margin-top: -76px;
    z-index: 1010 !important;
  }
`;

export const SBannerContainer = styled(Grid)`
  && {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

export const SContainer = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.loginPageBg};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
