import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useMeasure } from "react-use";
import { mergeAll } from "ramda";
import {
  SCircularProgress,
  SGridContainer,
  SGridLoading,
  SNoResultsWarning,
} from "./styles";
import {
  AssetData,
  AssetsType,
  useAllPortfolios,
  useBenchmarks,
  useCurrency,
  useInitialApplication,
} from "../../../store";
import { useFetchPortfolioLiquidity } from "../../../api";
import { LiquidityChart } from "./LiquidityChart/LiquidityChart";
import { formatMoney } from "../../../utils";

export interface liquidityValueType {
  assets: AssetData[];
  value: number;
  days: string;
  percentage: number;
}

export const LiquidityCharts: React.FC = () => {
  const { t } = useTranslation();
  const { currency } = useCurrency();
  const { selectedBenchmarks } = useBenchmarks();
  const { allPortfolios } = useAllPortfolios();
  const {
    loading: isPortfolioLiquidityLoading,
    fetch: getPortfoliosLiquidityResults,
  } = useFetchPortfolioLiquidity();
  const { initialApplication } = useInitialApplication();
  const [containerRef, { width: containerWidth }] =
    useMeasure<HTMLDivElement>();
  const [areAllPortfoliosResultsEmpty, setAreAllPortfoliosResultsEmpty] =
    useState(false);
  const [mergedLiquidity, setMergedLiquidity] = useState<{
    [x: string]: liquidityValueType[];
  }>();
  const [generalAssetsList, setGeneralAssetsList] = useState<AssetsType>();

  const handleDataFetching = useCallback(async (): Promise<void> => {
    let portfoliosLiquidityResults = {} as {
      [x: string]: liquidityValueType[];
    };

    const portfoliosAssets = mergeAll(
      Object.keys(allPortfolios).map((key) => allPortfolios[Number(key)].assets)
    );
    setGeneralAssetsList(portfoliosAssets);

    if (allPortfolios[0].identifier !== "") {
      // @ts-expect-error: TODO: refactor for matching types
      portfoliosLiquidityResults = await getPortfoliosLiquidityResults(
        allPortfolios
      );
    }

    const portfoliosKeys = Object.keys(portfoliosLiquidityResults);
    const emptyPortfoliosScan = portfoliosKeys
      .map(
        (portfolios) =>
          Object.keys(portfoliosLiquidityResults[portfolios]).length === 0
      )
      .filter((checkedEmptiness) => checkedEmptiness === true);

    const isNoPortfoliosData =
      portfoliosKeys.length === emptyPortfoliosScan.length;
    setAreAllPortfoliosResultsEmpty(isNoPortfoliosData);

    setMergedLiquidity({ ...portfoliosLiquidityResults });
  }, [selectedBenchmarks, allPortfolios, initialApplication]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleDataFetching();
  }, [handleDataFetching]);

  const translateXKeys = (xKey: string): string => {
    switch (xKey) {
      case "sec":
        return t("liquidity.sec");
      case "ni":
        return t("liquidity.ni");
      case "np":
        return t("liquidity.np");
      case "nd":
        return t("liquidity.nd");
      case "na":
        return t("liquidity.na");
      case "nh":
        return t("liquidity.nh");
      case "cc":
        return t("liquidity.cc");
      case "ter":
        return t("liquidity.ter");
      default:
        return xKey;
    }
  };

  return (
    <SGridContainer key="RentabilityContainer" ref={containerRef}>
      {isPortfolioLiquidityLoading ? (
        <SGridLoading key="RentabilityChartLoading">
          <SCircularProgress variant="indeterminate" color="inherit" />
        </SGridLoading>
      ) : (
        <>
          {/* No chart is shown if all portfolios results are empty */}
          {areAllPortfoliosResultsEmpty ? (
            <SGridLoading>
              <SNoResultsWarning>
                {t("rentability.noResults.line1")} <br />
                {t("rentability.noResults.line2")} <br />
                {t("rentability.noResults.line3")}
              </SNoResultsWarning>
            </SGridLoading>
          ) : (
            mergedLiquidity &&
            Object.keys(mergedLiquidity).map((liquidityAssetKey) => {
              const xData = mergedLiquidity[liquidityAssetKey].map((value) =>
                translateXKeys(String(value.days))
              );

              const xOverValues = mergedLiquidity[liquidityAssetKey].map(
                (value) =>
                  value.value === 0
                    ? ""
                    : translateXKeys(formatMoney(String(value.value), currency))
              );

              return (
                <LiquidityChart
                  liquidityAssetKey={liquidityAssetKey}
                  mergedLiquidity={mergedLiquidity}
                  containerWidth={containerWidth}
                  xData={xData}
                  xOverValues={xOverValues}
                  key={`${liquidityAssetKey}--liquidity`}
                  generalAssetsList={generalAssetsList}
                />
              );
            })
          )}
        </>
      )}
    </SGridContainer>
  );
};
