import "./i18n";
import { CssBaseline } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { ThemeProvider } from "styled-components";
import { applyMiddleware, createStore, compose } from "redux";
import { Provider as ReduxProvider } from "react-redux";
import { createLogger as createReduxLogger } from "redux-logger";
import thunk from "redux-thunk";
import { reducer } from "./store";
import { createAnalyticsMiddleware } from "./analytics/analyticsMiddleware";
import {
  BaseStyles,
  defaultTheme,
  ouiTheme,
  superCarteiraTheme,
  blankLabelTheme,
  ThemeType,
  kurtosysTheme,
  investsetTheme,
} from "./themes";
import App from "./App";

const selectedTheme = process.env.REACT_APP_THEME || "";
const theme = (): ThemeType => {
  switch (selectedTheme) {
    case "OUI":
      return ouiTheme;
    case "SUPER_CARTEIRA":
      return superCarteiraTheme;
    case "BLANK_LABEL":
      return blankLabelTheme;
    case "KURTOSYS":
      return kurtosysTheme;
    case "INVESTSET":
      return investsetTheme;
    default:
      return defaultTheme;
  }
};

const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? "none";

if (!/none/.test(googleAnalyticsId)) {
  ReactGA.initialize(googleAnalyticsId);
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const loggerMiddleware = createReduxLogger({
  collapsed: true,
  duration: true,
  diff: false,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware =
  process.env.REACT_APP_USE_REDUX_LOGGER === "true"
    ? composeEnhancers(
        applyMiddleware(loggerMiddleware, thunk),
        applyMiddleware(createAnalyticsMiddleware)
      )
    : composeEnhancers(
        applyMiddleware(thunk),
        applyMiddleware(createAnalyticsMiddleware)
      );

const store = createStore(reducer, middleware);
export type AppDispatchType = typeof store.dispatch;
export type RootStateType = ReturnType<typeof store.getState>;
export type CallableStateType = typeof store.getState;

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <CssBaseline />
        <BaseStyles />
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
