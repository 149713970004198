import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";
import { InputAdornment } from "@mui/material";
import { any, values, filter } from "ramda";
import { useTheme } from "styled-components";
import { SearchFilterList, SearchResults } from "..";
import {
  SCancelIcon,
  SFormControl,
  SGridResults,
  SGridContainer,
  SInput,
  SSearch,
  SCircularProgress,
} from "./styles";
import { useSearchRemoteAsset } from "../../../api";
import { useCustomBenchmarkAsset, useGlobalConfigs } from "../../../store";
import { FilterListType } from "./SearchFilterList/SearchFilterList";
import { convertToPtBrFilters } from "../../../utils";

interface SearchAssetPropsType {
  setSecondaryMessage: React.Dispatch<React.SetStateAction<boolean>>;
  secondaryMessage: boolean;
}

export const SearchAsset: React.FC<SearchAssetPropsType> = ({
  setSecondaryMessage,
  secondaryMessage,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState<JSON | string>("");
  const [selectedFilters, setSelectedFilters] = useState({
    funds: false,
    stocks: false,
    fixedIncome: false,
    pension: false,
    createAsset: false,
    valorization: false,
    diversification: false,
    stability: false,
    antifragility: false,
    others: false,
  } as FilterListType);
  const [isMatchedSearch, setIsMatchedSearch] = useState(false);
  const {
    loading: isSearchLoading,
    data: searchRemoteResults,
    fetch: searchRemoteAssets,
  } = useSearchRemoteAsset();
  const { customBenchmarks } = useCustomBenchmarkAsset();
  const [searchLocalResults, setSearchLocalResults] = useState({});
  const { minCharsForSearch } = useGlobalConfigs();
  const { setShowAllSearchableCustomBenchmarks } = useCustomBenchmarkAsset();

  const searchLocalAssets = useCallback(
    (searchQuery: string): void => {
      const matchedLocal = customBenchmarks.filter((asset) =>
        asset.label?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
      );

      setSearchLocalResults(
        matchedLocal.length > 0 ? { BENCHMARK_PERSONALIZADO: matchedLocal } : {}
      );
    },
    [customBenchmarks]
  );

  const clearTextAndResults = useCallback((): void => {
    setSearchText("");
    setResults("");
  }, [setSearchText, setResults]);

  const handleTextChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (event) => {
    setSearchText(event.target.value);
  };

  const handleClear:
    | React.MouseEventHandler<SVGSVGElement>
    | undefined = () => {
    clearTextAndResults();
  };

  const fetchSearchResults = useCallback(() => {
    if (
      searchText.length >= minCharsForSearch ||
      (theme.assetSearchShowAllFromSelectedCategory &&
        Object.keys(selectedFilters)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .map((i) => selectedFilters[i])
          .filter((x) => x === true).length > 0)
    ) {
      searchRemoteAssets(
        searchText || " ",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        convertToPtBrFilters(filter((x) => x, selectedFilters))
      );
      searchLocalAssets(searchText);
    } else {
      setResults("");
    }
  }, [
    searchText,
    searchRemoteAssets,
    searchLocalAssets,
    minCharsForSearch,
    selectedFilters,
    setResults,
    theme.assetSearchShowAllFromSelectedCategory,
  ]);

  useEffect(
    () =>
      setResults({
        ...JSON.parse(JSON.stringify(searchRemoteResults)),
        ...searchLocalResults,
      }),
    [searchRemoteResults, searchLocalResults]
  );

  useDebounce(
    () => {
      fetchSearchResults();
    },
    300,
    [searchText]
  );

  useDebounce(
    () => {
      fetchSearchResults();
    },
    100,
    [selectedFilters]
  );

  const isAnyFilterSelected = useCallback((): boolean => {
    return any((x) => x === true)(values(selectedFilters));
  }, [selectedFilters]);

  const isSearchBoxEmpty = useCallback(
    (): boolean => searchText.length === 0,
    [searchText]
  );

  useEffect(() => {
    if (isAnyFilterSelected() || !isSearchBoxEmpty()) {
      setSecondaryMessage(true);
    } else {
      setSecondaryMessage(false);
    }
  }, [
    selectedFilters,
    setSecondaryMessage,
    isSearchBoxEmpty,
    isAnyFilterSelected,
  ]);

  const isInputEmpty = Boolean(searchText);

  // For debugging
  // console.log("selectedFilters: ", { selectedFilters });

  return (
    <SGridContainer>
      <SFormControl variant="standard">
        <SearchFilterList
          passSelectedToParent={setSelectedFilters}
          setSearchText={setSearchText}
          setResults={setResults}
        />
        <SInput
          id="searchBox"
          value={searchText}
          onChange={handleTextChange}
          onFocus={() => setShowAllSearchableCustomBenchmarks(false)}
          startAdornment={
            <InputAdornment position="start">
              <SSearch />
            </InputAdornment>
          }
          placeholder={t("searchAsset.placeHolder")}
          disableUnderline
          endAdornment={
            <InputAdornment position="end">
              {isInputEmpty && <SCancelIcon onClick={handleClear} />}
            </InputAdornment>
          }
          autoFocus
        />
      </SFormControl>
      <SGridResults
        $isLoading={isSearchLoading}
        $isSearchDone={isMatchedSearch}
      >
        {isSearchLoading ? (
          <SCircularProgress />
        ) : (
          <SearchResults
            selectedFilters={selectedFilters}
            isSearching={isInputEmpty}
            data={results}
            secondaryMessage={secondaryMessage}
            setIsMatchedSearch={setIsMatchedSearch}
            isAnyFilterSelected={isAnyFilterSelected()}
          />
        )}
      </SGridResults>
    </SGridContainer>
  );
};
