import type * as HighchartsTypes from "highcharts";
import React, { useCallback, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import {
  Chart,
  ColumnSeries,
  HighchartsChart,
  HighchartsProvider,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from "react-jsx-highcharts";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { findIndex, indexOf, isEmpty, isNil, propEq } from "ramda";
import {
  chartNumber,
  formatMoney,
  formatPercentage,
  getExportingConfig,
  useAssetColor,
} from "../../../../utils";
import { liquidityValueType } from "../LiquidityCharts";
import { AssetsType, useAllPortfolios, useCurrency } from "../../../../store";

HighchartsExporting(Highcharts);

interface PropTypes {
  mergedLiquidity: {
    [x: string]: liquidityValueType[];
  };
  containerWidth: number;
  xData: string[] | undefined;
  xOverValues: string[];
  liquidityAssetKey: string;
  generalAssetsList: AssetsType | undefined;
}

export const LiquidityChart: React.FC<PropTypes> = ({
  mergedLiquidity,
  containerWidth,
  xData,
  xOverValues,
  liquidityAssetKey,
  generalAssetsList,
}) => {
  const [exposedChart, setExposedChart] = useState<HighchartsTypes.Chart>();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { allPortfolios } = useAllPortfolios();
  const { currency } = useCurrency();
  const { generateAssetColor } = useAssetColor();

  Highcharts.setOptions({
    lang: {
      contextButtonTitle: t("chartExport.downloadImage"),
      downloadPDF: t("chartExport.downloadPDF"),
      downloadJPEG: t("chartExport.downloadJPEG"),
      downloadPNG: t("chartExport.downloadPNG"),
      decimalPoint: i18n.language === "en" ? "." : ",",
      thousandsSep: i18n.language === "en" ? "," : ".",
      resetZoom: t("rentability.resetZoom"),
    },
  });

  const yLabels = {
    formatter(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/no-this-in-sfc
      return `${Highcharts.numberFormat(this.value, 0)}%`;
    },
  };

  function tooltipFormatter(): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react/no-this-in-sfc
    const xPointedValue = this.x;

    const assetsOnPoint =
      mergedLiquidity[liquidityAssetKey][indexOf(xPointedValue, xData || [])]
        .assets;

    const allAssetsInOrder = Object.keys(generalAssetsList || {});

    const getAssetColor = (asset: AssetsType["asset"]): string => {
      return String(
        generateAssetColor(
          indexOf(
            `${asset.asset.assetType}❤❤❤${asset.asset.identifier}`,
            allAssetsInOrder
          ) + Object.keys(allPortfolios).length
        )
      );
    };

    const tooltipTableHeader =
      Number(xPointedValue) >= 0
        ? `${xPointedValue} ${
            xPointedValue === 1
              ? t("liquidity.businessDay")
              : t("liquidity.businessDays")
          }`
        : xPointedValue;

    const tooltipTableRows = assetsOnPoint.map(
      (asset) => `<tr>
                          <td style="
                            background-color: ${getAssetColor(asset)};
                            color: ${getAssetColor(asset)}; 
                            width: 5px; 
                            min-width: 5px;
                            max-width: 5px;
                          ">
                            &nbsp;
                          </td>
                          <td style="padding-left: 4px; 
                                     padding-right: 10px; 
                                     font-weight: 600;
                                     text-align: right;
                                     ">
                                     ${formatPercentage(
                                       String(asset.percentage)
                                     )}
                          </td>
                          <td style="padding-right: 10px; font-weight: 600;">${formatMoney(
                            String(asset.amount),
                            currency
                          )}</td>
                          <td style="padding-right: 10px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 240px">
                                    ${asset.asset.label}
                          </td>
                       </tr>`
    );

    const tooltipTableFormatted = `<div style="
                              padding-left: 8px;
                              margin: 0;
                              margin-bottom: 8px;
                              color: rgba(2, 1, 1, 0.6);
                              font-family: ${theme.fonts.primary};
                              font-style: normal;
                              font-weight: normal;
                              font-size: 12px;
                              line-height: 16px;
                              display: flex;
                              justify-content: flex-start;
                              flex-direction: column;
                              max-width: auto;
                            ">
                            <div style="border-bottom: 1px solid #333333; 
                                        width: 100%;
                                        text-align: center;
                                        font-family: ${theme.fonts.primary};
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: 12px;
                                        line-height: 16px;
                                        margin: 8px;
                                        margin-left: -4px;
                                        color: #333333;
                            ">
                              ${tooltipTableHeader}
                            </div>
                            <table>
                              ${tooltipTableRows.join("")}
                            </table>
                           </div>`;

    return tooltipTableFormatted;
  }

  const generateChartTitle = (): string => {
    if (!isEmpty(mergedLiquidity) && !isNil(mergedLiquidity)) {
      return allPortfolios[Number(liquidityAssetKey)]?.name;
    }
    return "";
  };

  const getChart = (chart: HighchartsTypes.Chart): void =>
    setExposedChart(chart);

  const handleReflowChart = useCallback((): void => {
    try {
      if (typeof exposedChart !== "undefined") exposedChart.reflow();
      // eslint-disable-next-line no-empty
    } catch {}
  }, [exposedChart]);

  useEffect(() => handleReflowChart, [containerWidth, handleReflowChart]);

  return (
    <HighchartsProvider
      Highcharts={Highcharts}
      key={containerWidth + i18n.language}
    >
      <HighchartsChart
        callback={getChart}
        key={`${liquidityAssetKey}hicharts-${i18n.language}`}
        exporting={getExportingConfig(
          70,
          60,
          t("liquidity.tab"),
          t("liquidity.tab"),
          theme.chartExportingButton.show
        )}
        plotOptions={{
          column: {
            pointPadding: 0,
            groupPadding: 0,
            dataLabels: {
              crop: false,
              enabled: true,
              y: -22,
              rotation: -45,
              formatter() {
                return String(
                  xOverValues[
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line react/no-this-in-sfc
                    findIndex(propEq("days", this.key))(
                      mergedLiquidity[liquidityAssetKey]
                    )
                  ]
                );
              },
            },
          },
        }}
      >
        <Chart
          renderTo="container"
          width={containerWidth / Object.keys(mergedLiquidity).length}
        />
        <Title
          align="left"
          verticalAlign="top"
          useHTML
          style={{
            color: theme.colors.textDefault,
            fontFamily: theme.fonts.primary,
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "22px",
            paddingBottom: "23px",
          }}
        >
          {generateChartTitle()}
        </Title>

        <XAxis visible categories={xData}>
          <XAxis.Title
            style={{
              color: theme.colors.textDefault,
              fontFamily: theme.fonts.primary,
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "9px",
              lineHeight: "22px",
              paddingBottom: "23px",
            }}
          >
            {t("liquidity.footnote")}
          </XAxis.Title>
        </XAxis>
        {/* Plots Portfolios Data */}
        <YAxis labels={yLabels}>
          {allPortfolios[Number(liquidityAssetKey)] && (
            <ColumnSeries
              marker={{ enabled: false }}
              color={generateAssetColor(Number(liquidityAssetKey))}
              name={allPortfolios[Number(liquidityAssetKey)].name}
              key={`${liquidityAssetKey}--`}
              data={mergedLiquidity[Number(liquidityAssetKey)].map((values) => [
                values.days,
                chartNumber(Number(values.percentage)),
              ])}
              pointWidth={32}
            />
          )}
        </YAxis>
        <Tooltip
          shared
          useHTML
          outside
          valueDecimals={2}
          valueSuffix="%"
          borderRadius={4}
          borderColor="rgba(51, 51, 51, 0.5)"
          // eslint-disable-next-line react/jsx-no-bind
          formatter={tooltipFormatter}
          backgroundColor="rgba(255, 255, 255, 0.9)"
          style={{ zIndex: 9999 }}
        />
      </HighchartsChart>
    </HighchartsProvider>
  );
};
