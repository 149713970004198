import CloseIcon from "@mui/icons-material/Close";
import { Menu } from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  useAllPortfolios,
  useCurrentPortfolioId,
  useMaxAllowedPortfolios,
  useSideEditor,
} from "../../../store";
import { useAssetColor } from "../../../utils";
import { PortfoliosButton } from "./PortfoliosButton/PortfoliosButton";
import {
  SAddIcon,
  SAddPortfolio,
  SContentCopyIcon,
  SGridContainer,
  SGridRow,
  SLabel,
  SMenuItem,
  SMenuTitle,
} from "./styles";

export const PortfoliosSelector: React.FC = () => {
  const { allPortfolios, createNewPortfolio } = useAllPortfolios();
  const [, setCurrentPortfolioId] = useCurrentPortfolioId();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isPortfoliosMaxedOut } = useMaxAllowedPortfolios();
  const [showCloneList, setShowCloneList] = useState(false);
  const { setPortfolioBeingEdited } = useSideEditor();
  const { generateAssetColor } = useAssetColor();
  const theme = useTheme();

  const showAddButton = theme.portfolios.addPortoflio.show;

  const handleAddPortfolio = (): void => {
    if (!isPortfoliosMaxedOut()) {
      createNewPortfolio();
      setCurrentPortfolioId(Object.keys(allPortfolios).length);
      navigate("../", { replace: true });
    }
  };

  const handleCloneShowList = (): void => {
    setShowCloneList((isCloneListShown) => !isCloneListShown);
  };

  useEffect(() => {
    setPortfolioBeingEdited(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SGridContainer>
      <SGridRow>
        <SLabel>{t("portfoliosSelector.title")}</SLabel>
      </SGridRow>
      <SGridRow>
        {Object.keys(allPortfolios).map((portfolioKey) => {
          const portfolio = allPortfolios[Number(portfolioKey)];

          return (
            <PortfoliosButton
              text={portfolio.name}
              barColor={generateAssetColor(Number(portfolioKey))}
              id={portfolioKey}
              key={portfolioKey}
            />
          );
        })}

        {showAddButton && !isPortfoliosMaxedOut() ? (
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <>
                {/* eslint-disable react/jsx-props-no-spreading */}
                <SAddPortfolio
                  $disabled={isPortfoliosMaxedOut()}
                  disabled={isPortfoliosMaxedOut()}
                  {...bindTrigger(popupState)}
                >
                  {t("portfoliosSelector.add")}
                </SAddPortfolio>
                <Menu {...bindMenu(popupState)}>
                  <SMenuTitle>
                    {t("portfoliosSelector.addPortfolio")}
                    <CloseIcon onClick={popupState.close} />
                  </SMenuTitle>
                  <SMenuItem onClick={handleAddPortfolio}>
                    <SAddIcon /> {t("portfoliosSelector.addNew")}
                  </SMenuItem>
                  <SMenuItem onClick={handleCloneShowList}>
                    <SContentCopyIcon />
                    {t("portfoliosSelector.clonePortfolio")}
                  </SMenuItem>
                  {showCloneList && (
                    <SMenuItem onClick={handleCloneShowList}>
                      {Object.keys(allPortfolios).map((portfolioKey) => {
                        const portfolio = allPortfolios[Number(portfolioKey)];

                        return (
                          <PortfoliosButton
                            text={portfolio.name}
                            barColor={generateAssetColor(Number(portfolioKey))}
                            id={portfolioKey}
                            key={portfolioKey}
                            cloneMode={showCloneList}
                            cloneModeCloseMenu={popupState.close}
                          />
                        );
                      })}
                    </SMenuItem>
                  )}
                </Menu>
              </>
            )}
          </PopupState>
        ) : null}
      </SGridRow>
    </SGridContainer>
  );
};
