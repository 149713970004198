import styled from "styled-components";
import { Grid, Typography, Input } from "@mui/material";

export const SInputContainer = styled(Grid)`
  && {
    margin-right: 32px;
  }
`;

export const SLabel = styled(Typography)`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin: 4px 0px;
    color: ${({ theme }) => theme.colors.periodButtonDark};
  }
`;

export const SInputField = styled(Input)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.textDefault || "black"};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    padding-left: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 6px;
    width: 128.5px;
    height: 40px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.bgGray || "gray"};

    input {
      text-align: right;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.colors.bgGray || "gray"} inset;
    }
  }
`;
