import styled from "styled-components";
import { IconButton, Grid, Typography } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QrCodeIcon from "@mui/icons-material/QrCode";

export const SShareIcon = styled(ShareIcon)`
  && {
    color: ${({ theme }) => theme.colors.bgQuantumBanner};
  }
`;

export const SCloseIcon = styled(CloseIcon)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-size: 22px;
  }
`;

export const SMenuHeaderContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 343px;
    padding: 6px 16px;
  }
`;

export const SMenuTitle = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.textDefault};
  }
`;

export const SContentCopyIcon = styled(ContentCopyIcon)`
  && {
    color: ${({ theme }) => theme.colors.textBlack};
  }
`;

export const SQrCodeIcon = styled(QrCodeIcon)`
  && {
    color: ${({ theme }) => theme.colors.textBlack};
  }
`;

export const SShareMessage = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textBlack};
    margin-left: 18px;
  }
`;

interface ErrorOnCopy {
  $error: boolean;
}

export const SSnackbarMessageContainer = styled(Grid)<ErrorOnCopy>`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  ${({ $error, theme }) =>
      $error
        ? theme.colors.snackbarMessageError
        : theme.colors.snackbarMessageSuccess}};
    min-width: 198px;
    min-height: 58px;
    border-radius: 5px;
  }
`;

export const SSnackbarMessage = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textWhite};
  }
`;

export const SCloseSnackbarButton = styled(IconButton)`
  && {
    position: absolute;
    top: -8px;
    right: -8px;
  }
`;

export const SCloseSnackbarIcon = styled(CancelRoundedIcon)`
  && {
    color: ${({ theme }) => theme.colors.textWhite};
    font-size: 20px;
  }
`;
