import React, { useEffect, useState, useRef } from "react";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import {
  SInputContainer,
  SInputTitle,
  SInputField,
  SInputDescription,
  SEditIcon,
} from "./styles";
import { useCurrentPortfolio } from "../../../../store";

export const PortfolioName: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const { portfolioName, setPortfolioName } = useCurrentPortfolio();
  const nameInputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };

  // Show portfolio name only on click
  // const handleFocus = (): void => {
  //   setName(portfolioName);
  //   if (nameInputRef?.current) {
  //     setTimeout(() => {
  //       const input = nameInputRef?.current as HTMLInputElement;
  //       input.select();
  //     }, 0);
  //   }
  // };

  const [, cancelPortfolioNameDebounce] = useDebounce(
    () => {
      if (name !== portfolioName) {
        if (name.trim().length === 0) {
          setName(portfolioName);
        } else {
          setPortfolioName(name);
        }
      }
    },
    theme.delay.textInput,
    [name]
  );

  const handleEnterPress: React.KeyboardEventHandler = (event) => {
    if (event.key === "Enter") {
      if (name !== portfolioName) {
        setPortfolioName(name);
      }
      cancelPortfolioNameDebounce();
    }
  };

  useEffect(() => {
    cancelPortfolioNameDebounce();
  }, [cancelPortfolioNameDebounce]);

  useEffect(() => {
    setName(portfolioName);
  }, [portfolioName]);

  return (
    <SInputContainer>
      <SInputTitle>{t("portfolioEditorName")}</SInputTitle>
      <SInputField
        value={name}
        onChange={handleChange}
        // onFocus={handleFocus}
        onKeyDown={handleEnterPress}
        size="small"
        startAdornment={<SEditIcon fontSize="small" />}
        disableUnderline
        inputRef={nameInputRef}
      />
      <SInputDescription>{t("optional")}</SInputDescription>
    </SInputContainer>
  );
};
