import styled from "styled-components";
import { Input, Typography, Grid } from "@mui/material";

export const SInputContainer = styled(Grid)`
  width: 100%;
`;

export const SInputTitle = styled(Typography)`
  && {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
  }
`;

export const SInputField = styled(Input)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.textDefault || "black"};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 4px;
    padding: 0;
    padding-left: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 6px;
    width: 134px;
    height: 40px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.bgGray || "gray"};

    input {
      text-align: right;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.colors.bgGray || "gray"} inset;
    }
  }
`;

export const SInputDescription = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: inherit;
    font-style: inherit;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const SMoneyAdornment = styled.div`
  && {
    margin: 0;
    padding: 0;
    padding-right: 5px;
    color: ${({ theme }) => theme.colors.textDark};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 600;
    font-size: 12px;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }
`;
