import React from "react";
import { SBar, SButton, STitle } from "./styles";

interface PropTypes {
  barColor: string;
  text: string;
  id: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const CreateAssetButton: React.FC<PropTypes> = ({
  text,
  barColor,
  id,
  onClick,
}) => {
  return (
    <SButton id={id} onClick={onClick} disableFocusRipple>
      <SBar barColor={barColor} />
      <STitle>{text}</STitle>
    </SButton>
  );
};
