import { parse as qsParse } from "query-string";
import React, { useState } from "react";
import { useLocation, useMount } from "react-use";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { LoginCard } from "../../components";
import { AuthenticationBackdrop, QuantumBanner } from "../../components/shared";
import { SBannerContainer, SContainer, SLoginCardContainer } from "./styles";

import { useFetchUserAuth } from "../../api";
import { isTokenExpired } from "../../utils";

export type AuthErrorMsg = {
  isError: boolean;
  message: string;
};

export type DecodedJWT = {
  userEmail: "string";
  exp: number;
  iat: number;
};

export const LoginPage: React.FC = () => {
  const { search } = useLocation();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [authError, setAuthError] = useState<AuthErrorMsg>({
    isError: false,
    message: "",
  });

  const { fetch: fetchLongExpToken } = useFetchUserAuth();

  const theme = useTheme();
  const { t } = useTranslation();

  const authErrorMessage = t(theme.login.authErrorMessage as never);

  useMount(async () => {
    const qs = qsParse(String(search));

    if (Object.keys(qs).includes("token")) {
      try {
        const emailToken = String(qs?.token);
        const decodedToken = jwtDecode(emailToken);

        if (isTokenExpired(decodedToken, theme)) {
          setAuthError({
            isError: true,
            message: authErrorMessage,
          });

          if (process.env.NODE_ENV === "development")
            console.error("AUTH: EMAIL TOKEN EXPIRED!!!");

          setIsAuthenticating(false);
          return;
        }

        const tokenGetRes = await fetchLongExpToken(emailToken);

        if (tokenGetRes !== null) {
          if (process.env.NODE_ENV === "development")
            console.warn(
              "AUTH: long token received and saved. User logged in!",
              tokenGetRes
            );

          document.location.replace("/");
          return;
        }

        throw new Error();
      } catch {
        setAuthError({
          isError: true,
          message: authErrorMessage,
        });
        if (process.env.NODE_ENV === "development")
          console.error("AUTH: TOKEN ERROR!!!");

        setIsAuthenticating(false);
      }
    } else {
      if (process.env.NODE_ENV === "development")
        console.warn(
          "AUTH: NORMAL redirecting, user logged or do not need auth, or if not logged, waiting for login."
        );

      setIsAuthenticating(false);
    }
  });

  return (
    <SContainer>
      {isAuthenticating ? (
        <AuthenticationBackdrop open={isAuthenticating} />
      ) : (
        <>
          <SLoginCardContainer>
            <LoginCard error={authError} />
          </SLoginCardContainer>

          <SBannerContainer>
            <QuantumBanner />
          </SBannerContainer>
        </>
      )}
    </SContainer>
  );
};
