import Big from "big.js";
import { t } from "i18next";
import { useTheme } from "styled-components";
import { AssetsType, PortfoliosType, useGlobalConfigs } from "../../store";
import { rotateToRight } from "../general/ArrayUtils";

/**
 * Generates a "Portfolio [Letter]" portfolio name string
 * @param allPortfolios - the entire portfolios Object from Redux (state.portfolios)
 * @returns a portfolio name, such as "Portfólio A"
 */
export const generatePortfolioName = (
  allPortfolios: PortfoliosType
): string => {
  const portfolioName =
    process.env.REACT_APP_PORTFOLIO_NAME_PREFIX ?? t("portfolio");

  return `${portfolioName} ${Object.keys(allPortfolios).length + 1}`;
};

const useGeneratePortfolioColor = (): typeof returnHook => {
  const theme = useTheme();
  const { benchmarksInitialColor } = useGlobalConfigs();

  // BusinessLogic:
  //
  // Inside theme.colors.assets there's an array with 20 colors to be used with
  // portfolios, assets and benchmarks.
  //
  // Portfolios and assets must get a color from the first position.
  // Benchmarks colors starts from de 12th color.
  //
  // As soon as the 20 limit is reached, colors start to repeat from the
  // beginning of the list.

  /**
   * Generates a unique visible distinct color using the assetIndex
   * @param assetIndex - the asset position in a sorted list
   * @param isBenchmark - boolean flag to set benchmark pallet usage
   * @returns a hexadecimal color, such as #00FAFA
   */
  const generateColor = (assetIndex: number, isBenchmark = false): string => {
    const themeColors = isBenchmark
      ? rotateToRight(
          theme.colors.assets,
          theme.colors.assets.length - (benchmarksInitialColor - 1)
        )
      : theme.colors.assets;

    // Ensures that assetIndex bigger then the pallet are normalized
    const colorIndex = assetIndex % themeColors.length;

    return themeColors[colorIndex];
  };

  const returnHook = {
    generateColor,
  };

  return returnHook;
};

export const useAssetColor = (): typeof returnHook => {
  const { generateColor } = useGeneratePortfolioColor();

  const generateAssetColor = (assetIndex: number): string =>
    generateColor(assetIndex);

  const returnHook = {
    generateAssetColor,
  };

  return returnHook;
};

export const useBenchmarkColor = (): typeof returnHook => {
  const { generateColor } = useGeneratePortfolioColor();

  const generateBenchmarkColor = (assetIndex: number): string =>
    generateColor(assetIndex, true);

  const returnHook = {
    generateBenchmarkColor,
  };

  return returnHook;
};

export const calculateAssetsTotalAmount = (
  assets: AssetsType | null,
  roundingDecimalPlaces = 16
): number => {
  if (assets !== null) {
    return Object.keys(assets)
      .reduce(
        (accumulator, currentAsset) =>
          accumulator.plus(Big(assets[currentAsset].amount || 0)),
        Big(0)
      )
      .round(roundingDecimalPlaces)
      .toNumber();
  }
  return 0;
};

export const calculateAssetsTotalPercentage = (
  assets: AssetsType | null,
  roundingDecimalPlaces?: number
): number => {
  if (assets !== null) {
    return Object.keys(assets)
      .reduce(
        (accumulator, currentAsset) =>
          accumulator.plus(Big(assets[currentAsset].percentage || 0)),
        Big(0)
      )
      .round(roundingDecimalPlaces)
      .toNumber();
  }
  return 0;
};
