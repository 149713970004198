import { Button, Grid } from "@mui/material";
import styled from "styled-components";

interface SGridContainerProps {
  $isMenuOpen: boolean;
}

export const SGridContainer = styled(Grid)<SGridContainerProps>`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 0px;
    background-color: ${({ theme }) => theme.colors.addButtonPrimary};
    color: white;
    border-radius: 10px 0 0 10px;
    height: 45px;
    z-index: 9999;

    &:hover {
      background-color: ${({ theme, $isMenuOpen }) =>
        $isMenuOpen
          ? theme.colors.addButtonPrimary
          : theme.colors.addButtonSecondary};
    }
  }
`;

export const SGridMenuArea = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    grid-gap: 15px;
    padding-right: 10px;
  }
`;

export const SButton = styled(Button)`
  && {
    color: white;
  }
`;
