import { SetStateAction, useCallback, useEffect, useState } from "react";
import { stringify as queryStringify } from "query-string";
import axios from "axios";
import { mergeAll, sum, uniq, zipObj, filter } from "ramda";
import { useNavigate } from "react-router-dom";
import { isAfter, isBefore, isValid, startOfTomorrow } from "date-fns";
import { useTheme } from "styled-components";
import {
  AssetData,
  BenchmarkAssetType,
  BenchmarkData,
  BenchmarksType,
  CompetenceType,
  PortfoliosType,
  PortfolioType,
  useAllPortfolios,
  useCurrency,
  usePeriod,
  useCustomDateRange,
  useStoreForSharedLink,
  useGlobalConfigs,
  useShowShareError,
  useSharedLink,
  useWallet,
  UserDataType,
  useUserData,
  useAuth,
  AssetsType,
} from "../store";
import { SearchResultsType } from "../components/shared";
import { poolRequest, retryInterceptor } from "../utils";
import { superCarteiraFeatures } from "../pages";

interface RentabilityType {
  initialDate?: string;
  finalDate?: string;
  format?: string;
  value?: string;
}

interface CompositionType {
  assetType?: string;
  identifier?: string;
  label?: string;
  participation?: number;
  initialBalance?: number;
  grossBalance?: number;
}

interface CustomBenchmarkType {
  name: string;
  benchmarkIndexer: {
    id: number;
    name: string;
  };
  rate: string;
  format: string;
}

export interface PortfolioCompositionType {
  label: string;
  value?: string;
  participation: string;
  assetTypeDescription?: string;
}

interface PeriodType {
  initialDate: string | null;
  finalDate: string | null;
  assetsWithNoSeriesIntersection: string[];
}

export interface AssetsForCompetenceFetchType {
  assetType: string | null;
  identifier: string | null;
}

interface ApiSaveUserSupercarteiraDataType {
  userEmail: string;
  portfolioType: superCarteiraFeatures;
  newData: UserDataType["portfolios"][superCarteiraFeatures];
}

export type ImportedParsedValuesType = {
  portfolios: {
    [x: number]: {
      assetType: "PORTFOLIO";
      identifier: string;
      assets: AssetsType;
      name: string;
      totalAmount: number;
    };
  };
  benchmarks: BenchmarksType;
  period: string | null;
  windowValue: number;
  applicationAmount: number;
  currentPortfolioId: number;
  consolidated: string | null;
  competence: string | null;
  customDateRange: {
    initialDate: string | null;
    finalDate: string | null;
  };
};

type ApiValidateSupercarteiraTokenReturn = {
  user: UserDataType | null;
  token: string;
};

const useAPI = (): typeof hookReturn => {
  const { currency } = useCurrency();
  const { selectedCustomDateRange } = useCustomDateRange();
  const theme = useTheme();

  const api = axios.create({
    baseURL: String(process.env.REACT_APP_API_URL),
    headers: {
      "x-subscriptionkey": String(process.env.REACT_APP_API_KEY),
      "x-moeda": currency,
    },
    timeout: 60000,
  });

  // NOTE: This interceptor retries on code 429 and clears auth on 401.
  api.interceptors.response.use(
    undefined,
    retryInterceptor(api, theme.auth.useAuth, theme)
  );

  // NOTE: This adds the Authorization header on every request. If not wanted
  //       for some request, the config object for this request should override
  //       this by not adding the header.
  if (theme.auth.useAuth) {
    api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          // NOTE: config is of type AxiosRequestConfig<any>
          //       we can't control this "any", hence this is needed.
          //
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  const apiSearchAssets = async (
    searchString: string,
    filters: string[]
  ): Promise<string | JSON> => {
    try {
      const { data } = await api.get(
        `asset?${queryStringify(
          {
            searchString,
            filter: filters,
          },
          { arrayFormat: "separator", arrayFormatSeparator: "|" }
        )}`
      );
      return data;
    } catch {
      return "";
    }
  };

  const apiFetchBenchmarks = async (): Promise<BenchmarkAssetType[]> => {
    try {
      const { data } = await api.get(`asset/indices?showMoreIndices=true`);
      return data;
    } catch {
      return [];
    }
  };

  const apiRemoteAddCustomBenchmark = async (
    customBenchmark: CustomBenchmarkType
  ): Promise<string> => {
    const { data } = await api.post(
      `custom_benchmark/`,
      JSON.stringify(customBenchmark)
    );

    return data;
  };

  const apiFetchPeriods = async (): Promise<string[]> => {
    try {
      const { data } = await api.get(`period`);
      return data;
    } catch {
      return [];
    }
  };

  interface DefaultPeriodAssetsType {
    assetType: string | undefined;
    identifier: string | undefined;
  }

  const apiFetchDefaultPeriod = async (
    assetsId: DefaultPeriodAssetsType[]
  ): Promise<{ period: string }> => {
    try {
      const { data } = await poolRequest(
        () => api.post(`period/default`, JSON.stringify(assetsId)),
        (response) => {
          return response.data === "Resource not ready yet.";
        }
      );

      return data === undefined ? { period: "UM_ANO_UTIL" } : data;
    } catch {
      return { period: "UM_ANO_UTIL" };
    }
  };

  const apiFetchPeriodRange = async (
    assets:
      | PortfolioType[]
      | AssetData["asset"][]
      | BenchmarkData["asset"][]
      | (BenchmarkAssetType | SearchResultsType | PortfolioType)[],
    selectedPeriod: string
  ): Promise<PeriodType> => {
    const isAnyAssetWithoutIdentifier =
      assets
        .map((asset) => asset.identifier === "")
        .filter((trues) => trues === true).length > 0;

    const defaultData = {
      initialDate: null,
      finalDate: null,
      assetsWithNoSeriesIntersection: [],
    };

    if (isAnyAssetWithoutIdentifier) return defaultData;

    try {
      if (selectedPeriod === "DATA_PERSONALIZADA") {
        const { initialDate, finalDate } = selectedCustomDateRange;
        return {
          initialDate,
          finalDate,
          assetsWithNoSeriesIntersection: [],
        };
      }
      const { data } = await poolRequest(
        () =>
          api.get(
            `period/${selectedPeriod}${
              selectedPeriod === "OTIMO"
                ? `?${queryStringify(
                    {
                      assetType: assets.map((asset) => asset.assetType),
                      identifier: assets.map((asset) => asset.identifier),
                    },
                    { arrayFormat: "separator", arrayFormatSeparator: "|" }
                  )}`
                : ""
            }`
          ),
        (response) => {
          return response.data === "Resource not ready yet.";
        }
      );
      return data;
    } catch {
      return defaultData;
    }
  };

  const apiFetchBaseDate = async (): Promise<string> => {
    try {
      const { data } = await api.get(`last_update_date/`);
      return data;
    } catch {
      return "";
    }
  };

  const apiFetchCompetenceRange = async (
    assets: AssetsForCompetenceFetchType[]
  ): Promise<CompetenceType> => {
    try {
      const formattedAssets = assets.map((asset) => ({
        assetType: asset.assetType,
        identifier: asset.identifier,
      }));

      const { data } = await api.post(
        `period/portfolio/competences/`,
        JSON.stringify(formattedAssets),
        {
          timeout: 60000 * 5, // 5 minutes timeout for this request only
        }
      );

      return data;
    } catch {
      return [];
    }
  };

  interface requestResponse {
    date: string;
    value: number | null;
  }
  const apiFetchAssetRentability = async (
    asset: BenchmarkAssetType | SearchResultsType | PortfolioType,
    initialAmount: string,
    selectedPeriod: PeriodType
  ): Promise<string[] | requestResponse[]> => {
    try {
      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${asset.assetType}/${
              asset.identifier
            }/return/series?${queryStringify({
              initialApplication: initialAmount,
              initialDate: selectedPeriod.initialDate,
              finalDate: selectedPeriod.finalDate,
            })}`
          ),
        (response) => {
          return (
            response.data === "Resource not ready yet." ||
            response.data === "Invalid param 'body'."
          );
        }
      );

      return data;
    } catch {
      return [
        {
          date: "1986-01-01",
          value: null,
        },
      ];
    }
  };

  const apiFetchAssetRisk = async (
    asset: BenchmarkAssetType | SearchResultsType | PortfolioType,
    initialAmount: string,
    selectedPeriod: PeriodType,
    window: number
  ): Promise<string[] | requestResponse[]> => {
    try {
      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${asset.assetType}/${
              asset.identifier
            }/return/rolling_window/series?${queryStringify({
              initialApplication: initialAmount,
              initialDate: selectedPeriod.initialDate,
              finalDate: selectedPeriod.finalDate,
              windowValue: window,
            })}`
          ),
        (response) => {
          return (
            response.data === "Resource not ready yet." ||
            response.data === "Invalid param 'body'."
          );
        }
      );

      return data;
    } catch {
      return [
        {
          date: "1986-01-01",
          value: null,
        },
      ];
    }
  };

  interface LiquidityResponseType {
    initialDate: string;
    finalDate: string;
    value: number;
    format: string;
  }

  const apiFetchAssetLiquidity = async (
    asset: BenchmarkAssetType | SearchResultsType | PortfolioType
  ): Promise<LiquidityResponseType> => {
    try {
      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${asset.assetType}/${asset.identifier}/LIQUIDEZ`
          ),
        (response) => {
          return (
            response.data === "Resource not ready yet." ||
            response.data === "Invalid param 'body'."
          );
        }
      );

      return data;
    } catch {
      return {
        initialDate: "1986-01-01",
        finalDate: "1986-01-01",
        value: 0,
        format: "NUMBER",
      };
    }
  };

  const apiRemoteAddPortfolio = async (
    portfolio: PortfolioType
  ): Promise<string> => {
    const formattedPortfolio = { ...portfolio };
    delete formattedPortfolio.identifier;

    const { data } = await api.post(
      `portfolio/`,
      JSON.stringify(formattedPortfolio)
    );

    return data;
  };

  const apiRemoteUpdatePortfolio = async (
    portfolio: PortfolioType
  ): Promise<string> => {
    const portfolioRemoteId = portfolio.identifier;
    const formattedPortfolio = { ...portfolio };

    const { data } = await api.put(
      `portfolio/${portfolioRemoteId}`,
      JSON.stringify(formattedPortfolio)
    );

    return data;
  };

  const apiFetchComposition = async (
    asset: PortfolioType | SearchResultsType | BenchmarkAssetType,
    initialDate: string,
    finalDate: string,
    showPortfolio?: boolean,
    amount?: number
  ): Promise<CompositionType[]> => {
    try {
      const { identifier, assetType } = asset;
      if (identifier === "") return [];

      const { data } = await api.get(
        `calculation/${assetType}/${identifier}/composition?${queryStringify(
          {
            field: ["PARTICIPATION", "INITIAL_BALANCE", "GROSS_BALANCE"],
            compositionDate: finalDate,
            initialDate,
            ...(showPortfolio !== undefined && { showPortfolio }),
            ...(amount !== undefined && { amount }),
          },
          { arrayFormat: "separator", arrayFormatSeparator: "|" }
        )}`
      );
      return data;
    } catch {
      return [];
    }
  };

  const apiFetchPeriodsRanges = async (): Promise<PeriodType[]> => {
    try {
      const { data } = await api.get(`period/functionality/COMPARE_PORTFOLIOS`);
      return data.map(
        (range: {
          initialDate: string;
          finalDate: string;
          period: string;
        }) => ({
          initialDate: range.initialDate,
          finalDate: range.finalDate,
          assetsWithNoSeriesIntersection: [],
        })
      );
    } catch {
      return [];
    }
  };

  const apiFetchReturn = async (
    asset: PortfolioType | SearchResultsType | BenchmarkAssetType,
    periodRange: PeriodType
  ): Promise<RentabilityType> => {
    try {
      const { identifier, assetType } = asset;
      if (identifier === "") return {};

      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${assetType}/${identifier}/RETORNO?${queryStringify({
              initialDate: periodRange.initialDate,
              finalDate: periodRange.finalDate,
            })}`
          ),
        (response) => {
          return response.data === "Resource not ready yet.";
        }
      );

      return data;
    } catch {
      return {};
    }
  };

  const apiFetchBenchmarkedReturn = async (
    asset: PortfolioType | SearchResultsType | BenchmarkAssetType,
    periodRange: PeriodType
  ): Promise<RentabilityType> => {
    try {
      const { identifier, assetType } = asset;
      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${assetType}/${identifier}/PORCENTAGEM_BENCHMARK_RETORNO?${queryStringify(
              {
                initialDate: periodRange.initialDate,
                finalDate: periodRange.finalDate,
              }
            )}`
          ),
        (response) => {
          return response.data === "Resource not ready yet.";
        }
      );
      return data;
    } catch {
      return {};
    }
  };

  interface VolatilityType {
    value?: string;
  }

  const apiFetchVolatility = async (
    asset: PortfolioType | SearchResultsType | BenchmarkAssetType,
    periodRange: PeriodType
  ): Promise<VolatilityType> => {
    try {
      const { identifier, assetType } = asset;
      if (identifier === "") return {};

      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${assetType}/${identifier}/VOLATILIDADE?${queryStringify(
              {
                initialDate: periodRange.initialDate,
                finalDate: periodRange.finalDate,
              }
            )}`
          ),
        (response) => {
          return response.data === "Resource not ready yet.";
        }
      );
      return data;
    } catch {
      return {};
    }
  };

  interface SharpeType {
    format?: string;
    value?: string;
  }

  const apiFetchSharpe = async (
    asset: PortfolioType | SearchResultsType | BenchmarkAssetType,
    periodRange: PeriodType
  ): Promise<SharpeType> => {
    try {
      const { identifier, assetType } = asset;
      if (identifier === "") return {};

      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${assetType}/${identifier}/SHARPE?${queryStringify({
              initialDate: periodRange.initialDate,
              finalDate: periodRange.finalDate,
            })}`
          ),
        (response) => {
          return response.data === "Resource not ready yet.";
        }
      );
      return data;
    } catch {
      return {};
    }
  };

  interface ReturnTimesType {
    format?: string;
    value?: number;
  }

  const apiFetchPositiveReturnTimes = async (
    asset: PortfolioType | SearchResultsType | BenchmarkAssetType,
    periodRange: PeriodType
  ): Promise<ReturnTimesType> => {
    try {
      const { identifier, assetType } = asset;
      if (identifier === "") return {};

      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${assetType}/${identifier}/NUM_VEZES_RETORNO_POSITIVO?${queryStringify(
              {
                initialDate: periodRange.initialDate,
                finalDate: periodRange.finalDate,
              }
            )}`
          ),
        (response) => {
          return response.data === "Resource not ready yet.";
        }
      );
      return data;
    } catch {
      return {};
    }
  };

  const apiFetchNegativeReturnTimes = async (
    asset: PortfolioType | SearchResultsType | BenchmarkAssetType,
    periodRange: PeriodType
  ): Promise<ReturnTimesType> => {
    try {
      const { identifier, assetType } = asset;
      if (identifier === "") return {};

      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${assetType}/${identifier}/NUM_VEZES_RETORNO_NEGATIVO?${queryStringify(
              {
                initialDate: periodRange.initialDate,
                finalDate: periodRange.finalDate,
              }
            )}`
          ),
        (response) => {
          return response.data === "Resource not ready yet.";
        }
      );
      return data;
    } catch {
      return {};
    }
  };

  interface AverageReturnType {
    format?: string;
    value?: string;
  }

  const apiFetchAverageReturn = async (
    asset: PortfolioType | SearchResultsType | BenchmarkAssetType,
    periodRange: PeriodType
  ): Promise<AverageReturnType> => {
    try {
      const { identifier, assetType } = asset;
      if (identifier === "") return {};

      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${assetType}/${identifier}/RETORNO_MEDIO?${queryStringify(
              {
                initialDate: periodRange.initialDate,
                finalDate: periodRange.finalDate,
              }
            )}`
          ),
        (response) => {
          return response.data === "Resource not ready yet.";
        }
      );
      return data;
    } catch {
      return {};
    }
  };

  interface ReturnExtremesType {
    format?: string;
    value?: string;
    date?: string;
  }

  const apiFetchMaximumReturn = async (
    asset: PortfolioType | SearchResultsType | BenchmarkAssetType,
    periodRange: PeriodType
  ): Promise<ReturnExtremesType> => {
    try {
      const { identifier, assetType } = asset;
      if (identifier === "") return {};

      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${assetType}/${identifier}/RETORNO_MAXIMO?${queryStringify(
              {
                initialDate: periodRange.initialDate,
                finalDate: periodRange.finalDate,
              }
            )}`
          ),
        (response) => {
          return response.data === "Resource not ready yet.";
        }
      );
      return data;
    } catch {
      return {};
    }
  };

  const apiFetchMinimumReturn = async (
    asset: PortfolioType | SearchResultsType | BenchmarkAssetType,
    periodRange: PeriodType
  ): Promise<ReturnExtremesType> => {
    try {
      const { identifier, assetType } = asset;
      if (identifier === "") return {};

      const { data } = await poolRequest(
        () =>
          api.get(
            `calculation/${assetType}/${identifier}/RETORNO_MINIMO?${queryStringify(
              {
                initialDate: periodRange.initialDate,
                finalDate: periodRange.finalDate,
              }
            )}`
          ),
        (response) => {
          return response.data === "Resource not ready yet.";
        }
      );
      return data;
    } catch {
      return {};
    }
  };

  const apiFetchPortfolioComposition = async (
    portfolio: PortfolioType,
    groupType: string,
    competenceDate: string
  ): Promise<PortfolioCompositionType[]> => {
    const emptyComposition = [{ label: "n/a", participation: "0" }];
    try {
      if ([null, ""].includes(competenceDate) || portfolio.identifier === "")
        return emptyComposition;

      const { data } = await api.get(
        `portfolio/${portfolio.identifier}/composition?${queryStringify({
          groupType,
          competenceDate,
        })}`
      );

      return data;
    } catch {
      return emptyComposition;
    }
  };

  const apiFetchPortfolio = async (
    portfolioId: PortfolioType["identifier"]
  ): Promise<PortfolioType> => {
    const { data } = await poolRequest(
      () => api.get(`portfolio/${portfolioId}`),
      (response) => {
        return response.data === "Resource not ready yet.";
      }
    );
    return data;
  };

  const apiClonePortfolio = async (
    portfolioId: PortfolioType["identifier"]
  ): Promise<PortfolioType> => {
    const { data } = await poolRequest(
      () => api.get(`portfolio/${portfolioId}/clone`),
      (response) => {
        return response.data === "Resource not ready yet.";
      }
    );
    return data;
  };

  const apiSendAccessLinkEmail = async (
    userEmail: string,
    remember: boolean
  ): Promise<boolean | null> => {
    try {
      const { data } = await api.get(
        `supercarteira/login/${btoa(userEmail)}?${queryStringify({
          rememberMe: remember,
        })}`,
        {
          headers: {
            // NOTE: We do not want to pass any automatic Authorization header
            // (set by the interceptor above on the code). Hence this is a
            // "blank override".
          },
        }
      );

      return Boolean(data);
    } catch {
      return null;
    }
  };

  const apiValidateSupercarteiraToken = async (
    token: string
  ): Promise<ApiValidateSupercarteiraTokenReturn | null> => {
    try {
      const { data } = await api.get(`supercarteira/auth`, {
        headers: {
          // NOTE: This Auth header is the token passed as query param to the
          //       login route (the one received by e-mail), it is a short
          //       expiration JWT used to the frontend to get the long
          //       expiration JWT it will use for further requests.
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    } catch {
      return null;
    }
  };

  const apiFetchUserData = async (
    userEmail: string
  ): Promise<UserDataType | null> => {
    try {
      const { data } = await api.get(`supercarteira/user/${btoa(userEmail)}`);
      return data;
    } catch {
      return null;
    }
  };

  const apiSaveUserSupercarteiraData = async ({
    userEmail,
    newData,
    portfolioType,
  }: ApiSaveUserSupercarteiraDataType): Promise<UserDataType | null> => {
    const postData = {
      portfolioType,
      ...newData,
    };

    try {
      const { data } = await api.post(
        `supercarteira/user/${btoa(userEmail)}`,
        JSON.stringify(postData)
      );
      return data;
    } catch {
      return null;
    }
  };

  const hookReturn = {
    apiSearchAssets,
    apiFetchBenchmarks,
    apiFetchDefaultPeriod,
    apiFetchPeriods,
    apiFetchPeriodRange,
    apiFetchAssetRentability,
    apiFetchAssetRisk,
    apiRemoteAddCustomBenchmark,
    apiRemoteAddPortfolio,
    apiRemoteUpdatePortfolio,
    apiFetchComposition,
    apiFetchPeriodsRanges,
    apiFetchReturn,
    apiFetchBenchmarkedReturn,
    apiFetchAssetLiquidity,
    apiFetchVolatility,
    apiFetchSharpe,
    apiFetchPositiveReturnTimes,
    apiFetchNegativeReturnTimes,
    apiFetchAverageReturn,
    apiFetchMaximumReturn,
    apiFetchMinimumReturn,
    apiFetchCompetenceRange,
    apiFetchPortfolioComposition,
    apiFetchPortfolio,
    apiClonePortfolio,
    apiFetchBaseDate,
    apiFetchUserData,
    apiSaveUserSupercarteiraData,
    apiSendAccessLinkEmail,
    apiValidateSupercarteiraToken,
  };

  return hookReturn;
};

export const useSearchRemoteAsset = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<string | JSON>("");
  const { apiSearchAssets } = useAPI();

  const searchAssets = async (
    searchString: string,
    filters: string[]
  ): Promise<void> => {
    setIsLoading(true);
    const data = await apiSearchAssets(searchString, filters);
    setSearchResults(data);
    setIsLoading(false);
  };

  const hookReturn = {
    loading: isLoading,
    data: searchResults,
    fetch: searchAssets,
  };

  return hookReturn;
};

export const useFetchRemoteBenchmarks = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [benchmarks, setBenchmarks] = useState<BenchmarkAssetType[]>([]);
  const { apiFetchBenchmarks } = useAPI();

  const fetchBenchmarks = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const data = await apiFetchBenchmarks();
      setBenchmarks(data);
    } finally {
      setIsLoading(false);
    }
  };

  const hookReturn = {
    loading: isLoading,
    data: benchmarks,
    fetch: fetchBenchmarks,
  };

  return hookReturn;
};

export const useRemoteBenchmarks = (): typeof hookReturn => {
  const { fetch, data, loading } = useFetchRemoteBenchmarks();

  useEffect(() => {
    if (data.length === 0) {
      fetch();
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const hookReturn = {
    data,
    loading,
  };

  return hookReturn;
};

export const useFetchRemotePeriods = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [periods, setPeriods] = useState<string[]>([]);
  const { allPortfolios } = useAllPortfolios();
  const { apiFetchPeriods, apiFetchDefaultPeriod } = useAPI();
  const { setSelectedPeriod } = usePeriod();
  const { setLoadingPeriodsFromSharedLink, sharedLinkTasks } = useSharedLink();
  const theme = useTheme();

  const fetchPeriods = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true);
      const data = await apiFetchPeriods();
      // [data[2], data[3]] = [data[3], data[2]]; // 6 meses e 1 ano vem em posições invertidas (houve uma task para deixar invertido)
      setPeriods(data);

      const arrayedPortfolios = Object.keys(allPortfolios).map(
        (portfolioKey) => ({
          assetType: allPortfolios[Number(portfolioKey)].assetType,
          identifier: allPortfolios[Number(portfolioKey)].identifier,
        })
      );

      if (!sharedLinkTasks.isLoadingPeriods) {
        const { period: defaultPeriod } = theme.defaultPeriod.useCustom
          ? { period: theme.defaultPeriod.key }
          : await apiFetchDefaultPeriod(arrayedPortfolios);
        setSelectedPeriod(defaultPeriod);
      }
    } finally {
      if (sharedLinkTasks.isLoadingPeriods)
        setLoadingPeriodsFromSharedLink(false);
      setIsLoading(false);
    }
  }, [
    apiFetchPeriods,
    allPortfolios,
    sharedLinkTasks.isLoadingPeriods,
    apiFetchDefaultPeriod,
    setSelectedPeriod,
    setLoadingPeriodsFromSharedLink,
    theme.defaultPeriod,
  ]);

  const hookReturn = {
    loading: isLoading,
    data: periods,
    fetch: fetchPeriods,
  };

  return hookReturn;
};

export const useRemotePeriods = (): typeof hookReturn => {
  const { fetch, data, loading } = useFetchRemotePeriods();

  useEffect(() => {
    if (data.length === 0) {
      fetch();
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const hookReturn = {
    data,
    loading,
  };

  return hookReturn;
};

export const useFetchRentability = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const { apiFetchPeriodRange, apiFetchAssetRentability } = useAPI();

  const getAssetsRentabilityResults = useCallback(
    async (
      amount = "100",
      namedAssets: BenchmarksType | PortfolioType["assets"],
      period: string,
      portfoliosPeriodReferenceOnly: PortfoliosType
    ): Promise<{ [x: string]: string[] }> => {
      const initialAmount = String(amount);
      const portfoliosForPeriodReference = Object.keys(
        portfoliosPeriodReferenceOnly
      ).map(
        (portfolioKey) => portfoliosPeriodReferenceOnly[Number(portfolioKey)]
      );

      const benchmarkAssets = Object.keys(namedAssets).map(
        (benchmarkKey) => namedAssets[benchmarkKey].asset
      );

      try {
        setIsLoading(true);
        const periodRange = await apiFetchPeriodRange(
          [...portfoliosForPeriodReference, ...benchmarkAssets],
          period
        );
        const benchmarkRentabilityResults = await Promise.all(
          benchmarkAssets.map((asset) =>
            apiFetchAssetRentability(asset, initialAmount, periodRange)
          )
        );

        const rentabilityResults = zipObj(
          Object.keys(namedAssets),
          benchmarkRentabilityResults
        );
        setData(rentabilityResults);

        return rentabilityResults as {
          [x: string]: string[] | XMLHttpRequestResponseType[];
        };
      } catch {
        setData({});
        return {};
      } finally {
        setIsLoading(false);
      }
    },
    [apiFetchAssetRentability, apiFetchPeriodRange]
  );

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: getAssetsRentabilityResults,
  };

  return hookReturn;
};

export const useFetchPortfolioRentability = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const { apiFetchPeriodRange, apiFetchAssetRentability } = useAPI();

  const getPortfoliosRentabilityResults = useCallback(
    async (
      amount = "100",
      portfolios: PortfoliosType,
      period: string,
      benchmarksPeriodReferenceOnly: BenchmarksType
    ): Promise<{ [x: string]: string[] }> => {
      const initialAmount = String(amount);
      const portfoliosArrayed = Object.keys(portfolios).map(
        (portfolioKey) => portfolios[Number(portfolioKey)]
      );
      const benchmarksForPeriodReference = Object.keys(
        benchmarksPeriodReferenceOnly
      ).map(
        (benchmarkKey) => benchmarksPeriodReferenceOnly[benchmarkKey].asset
      );

      try {
        setIsLoading(true);
        const periodRange = await apiFetchPeriodRange(
          [...benchmarksForPeriodReference, ...portfoliosArrayed],
          period
        );
        const portfolioRentabilityResults = await Promise.all(
          portfoliosArrayed.map((asset) =>
            apiFetchAssetRentability(asset, initialAmount, periodRange)
          )
        );

        const rentabilityResults = zipObj(
          Object.keys(portfolios),
          portfolioRentabilityResults
        );
        setData(rentabilityResults);

        // @ts-expect-error: ramda does not provide a type for it
        return rentabilityResults;
      } catch {
        setData({});
        return {};
      } finally {
        setIsLoading(false);
      }
    },
    [apiFetchAssetRentability, apiFetchPeriodRange]
  );

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: getPortfoliosRentabilityResults,
  };

  return hookReturn;
};

export const useAddOrUpdateRemotePortfolio = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const { apiRemoteAddPortfolio, apiRemoteUpdatePortfolio } = useAPI();

  const addRemotePortfolio = async (
    portfolio: PortfolioType
  ): Promise<string> => {
    try {
      setIsLoading(true);
      const remoteId = await apiRemoteAddPortfolio(portfolio);
      setData(remoteId);
      return remoteId;
    } catch {
      setData("failed");
      return "failed";
    } finally {
      setIsLoading(false);
    }
  };

  const updateRemotePortfolio = async (
    portfolio: PortfolioType
  ): Promise<string> => {
    try {
      setIsLoading(true);
      const remoteId = String(portfolio.identifier);
      await apiRemoteUpdatePortfolio(portfolio);
      setData(remoteId);
      return remoteId;
    } catch {
      setData("failed");
      return "failed";
    } finally {
      setIsLoading(false);
    }
  };

  const addOrUpdateRemotePortfolio = async (
    portfolio: PortfolioType | null
  ): Promise<string> => {
    if (portfolio === null) return "";
    if (Object.keys(portfolio.assets).length === 0) {
      return "failed";
    }

    if (portfolio.identifier === "") {
      return addRemotePortfolio(portfolio);
    }
    return updateRemotePortfolio(portfolio);
  };

  const hookReturn = {
    data,
    loading: isLoading,
    send: addOrUpdateRemotePortfolio,
  };

  return hookReturn;
};

export interface HistoricRentabilityData {
  [key: string]: {
    label: string | null;
    month: string | null;
    yearToDate: string | null;
    sixMonths: string | null;
    year: string | null;
    selectedPeriod: string | null;
    participation: string | null;
    initialBalance: string | null;
    grossBalance: string | null;
  };
}

interface RentabilityTableData extends HistoricRentabilityData {
  [key: string]: {
    assets: HistoricRentabilityData;
    label: string | null;
    month: string | null;
    yearToDate: string | null;
    sixMonths: string | null;
    year: string | null;
    selectedPeriod: string | null;
    participation: string | null;
    initialBalance: string | null;
    grossBalance: string | null;
  };
}

export const useFetchHistoricRentability = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [rentabilityTableData, setRentabilityTableData] =
    useState<RentabilityTableData>();
  const {
    apiFetchPeriodsRanges,
    apiFetchComposition,
    apiFetchReturn,
    apiFetchBenchmarkedReturn,
    apiFetchPeriodRange,
  } = useAPI();

  const fetchRentabilityTableData = useCallback(
    async (
      portfolios: PortfoliosType,
      benchmarks: BenchmarksType,
      selectedPeriod: string,
      type: "RETORNO" | "PORCENTAGEM_BENCHMARK_RETORNO"
    ): Promise<void> => {
      const portfoliosArrayed = Object.keys(portfolios).map(
        (portfolioKey) => portfolios[Number(portfolioKey)]
      );
      const benchmarksArrayed = Object.keys(benchmarks).map(
        (benchmarkKey) => benchmarks[benchmarkKey].asset
      );

      try {
        setIsLoading(true);

        // Pre-fetch the period ranges needed to fetch all other data
        const periodRanges = (
          await Promise.all([
            apiFetchPeriodsRanges(),
            apiFetchPeriodRange(
              [...portfoliosArrayed, ...benchmarksArrayed],
              selectedPeriod
            ),
          ])
        )
          .flat(1)
          .filter((_range, index) => index !== 4); // Remove the 5th period range because it is not used here.

        // Construct the promises needed to fetch the Portfolios data
        const portfoliosDataPromise = portfoliosArrayed.map((portfolio) => {
          const compositionDataPromise = apiFetchComposition(
            portfolio,
            periodRanges[4].initialDate ? periodRanges[4].initialDate : "",
            periodRanges[4].finalDate ? periodRanges[4].finalDate : "",
            true
          );

          const portfolioPromises = periodRanges.map((periodRange) =>
            type === "RETORNO"
              ? apiFetchReturn(portfolio, periodRange)
              : apiFetchBenchmarkedReturn(portfolio, periodRange)
          );

          const portfolioAssets = Object.keys(portfolio.assets).map(
            (assetKey) => portfolio.assets[assetKey]
          );
          const portfolioAssetsPromises = portfolioAssets
            .map((asset) =>
              periodRanges.map((periodRange) =>
                type === "RETORNO"
                  ? apiFetchReturn(asset.asset, periodRange)
                  : apiFetchBenchmarkedReturn(asset.asset, periodRange)
              )
            )
            .flat(1);

          const portfolioDataPromise = Promise.all([
            ...portfolioPromises,
            compositionDataPromise,
            ...portfolioAssetsPromises,
          ]);
          return portfolioDataPromise;
        });

        // Construct the promises needed to fetch the benchmarks data
        const benchmarksDataPromise = benchmarksArrayed.map((benchmark) => {
          const benchmarkPromises = periodRanges.map((periodRange) =>
            type === "RETORNO"
              ? apiFetchReturn(benchmark, periodRange)
              : apiFetchBenchmarkedReturn(benchmark, periodRange)
          );

          const benchmarkDataPromise = Promise.all(benchmarkPromises);
          return benchmarkDataPromise;
        });

        const [portfoliosData, benchmarksData] = await Promise.all([
          await Promise.all(portfoliosDataPromise),
          await Promise.all(benchmarksDataPromise),
        ]);

        // Construct the return object for benchmarks
        const benchmarksRentabilityData = benchmarksArrayed.map(
          (benchmark, index) => {
            const benchmarkData = benchmarksData[index];

            return {
              label: benchmark.label,
              month: (benchmarkData[0] as RentabilityType)?.value || null,
              year: (benchmarkData[1] as RentabilityType)?.value || null,
              yearToDate: (benchmarkData[2] as RentabilityType)?.value || null,
              sixMonths: (benchmarkData[3] as RentabilityType)?.value || null,
              selectedPeriod:
                (benchmarkData[4] as RentabilityType)?.value || null,
              participation: null,
              initialBalance: null,
              grossBalance: null,
              assets: {},
            };
          }
        );

        // Construct the return object for portfolios (with its assets)
        const portfoliosRentabilityData = portfoliosArrayed.map(
          (portfolio, portfolioIndex) => {
            // Extract current portfolio's data in a variable
            const portfolioData = portfoliosData[portfolioIndex];

            // Assets data begins after 6 elements, so this line extracts only the assets' data
            const assetsData = portfolioData.slice(6);

            // Extract the portfolio's composition data array in a variable
            const compositionData = portfolioData[5] as CompositionType[];

            // Portfolio composition data is always the first element in the composition data array
            const portfolioCompositionData = compositionData[0];

            // Assets composition data are the rest of the composition data array
            // Create an object that addresses each asset's composition data with it's 'label'
            const assetsCompositionData = zipObj(
              [...compositionData.slice(1)].map((asset) => asset?.label || ""),
              compositionData.slice(1)
            );

            // Extract the current portfolio's assets in a variable
            const assetsArrayed = Object.keys(portfolio.assets).map(
              (assetKey) => portfolio.assets[assetKey]
            );

            // Construct the return object for the current portfolio's assets
            const assetsRentabilityData = assetsArrayed.map(
              (asset, assetIndex) => {
                const currentAssetDataStart = assetIndex * 5;
                const currentAssetData = assetsData.slice(
                  currentAssetDataStart,
                  currentAssetDataStart + 5
                );

                return {
                  label: asset.asset.label,
                  month:
                    (currentAssetData[0] as RentabilityType)?.value || null,
                  year: (currentAssetData[1] as RentabilityType)?.value || null,
                  yearToDate:
                    (currentAssetData[2] as RentabilityType)?.value || null,
                  sixMonths:
                    (currentAssetData[3] as RentabilityType)?.value || null,
                  selectedPeriod:
                    (currentAssetData[4] as RentabilityType)?.value || null,
                  participation: assetsCompositionData[
                    asset.asset.label as string
                  ]?.participation
                    ? String(
                        assetsCompositionData[String(asset.asset.label)]
                          .participation
                      )
                    : null,
                  initialBalance: assetsCompositionData[
                    asset.asset.label as string
                  ]?.initialBalance
                    ? String(
                        assetsCompositionData[String(asset.asset.label)]
                          .initialBalance
                      )
                    : null,
                  grossBalance: assetsCompositionData[
                    asset.asset.label as string
                  ]?.grossBalance
                    ? String(
                        assetsCompositionData[String(asset.asset.label)]
                          .grossBalance
                      )
                    : null,
                };
              }
            );

            const assets = zipObj(
              Object.keys(portfolio.assets),
              assetsRentabilityData
            );

            return {
              label: portfolio.name,
              month: (portfolioData[0] as RentabilityType)?.value || null,
              year: (portfolioData[1] as RentabilityType)?.value || null,
              yearToDate: (portfolioData[2] as RentabilityType)?.value || null,
              sixMonths: (portfolioData[3] as RentabilityType)?.value || null,
              selectedPeriod:
                (portfolioData[4] as RentabilityType)?.value || null,
              participation: portfolioCompositionData?.participation
                ? String(portfolioCompositionData.participation)
                : null,
              initialBalance: portfolioCompositionData?.initialBalance
                ? String(portfolioCompositionData.initialBalance)
                : null,
              grossBalance: portfolioCompositionData?.grossBalance
                ? String(portfolioCompositionData.grossBalance)
                : null,
              assets,
            };
          }
        );

        const rentabilityResults = zipObj(
          [...Object.keys(portfolios), ...Object.keys(benchmarks)],
          [...portfoliosRentabilityData, ...benchmarksRentabilityData]
        );

        setRentabilityTableData(rentabilityResults);
      } catch {
        setRentabilityTableData({});
      } finally {
        setIsLoading(false);
      }
    },
    [
      apiFetchPeriodsRanges,
      apiFetchComposition,
      apiFetchReturn,
      apiFetchBenchmarkedReturn,
      apiFetchPeriodRange,
    ]
  );

  const hookReturn = {
    loading: isLoading,
    data: rentabilityTableData,
    fetch: fetchRentabilityTableData,
  };

  return hookReturn;
};

export interface VolatilityData {
  [key: string]: {
    label: string | null;
    yearVolatility: string | null;
    volatility: string | null;
    sharpe: string | null;
    returnDays: {
      positive: number | null;
      negative: number | null;
    };
    dailyReturn: {
      average: string | null;
      maximum: string | null;
      maximumDate: string | null;
      minimum: string | null;
      minimumDate: string | null;
    };
  };
}

interface VolatilityTableData extends VolatilityData {
  [key: string]: {
    assets: VolatilityData;
    label: string | null;
    yearVolatility: string | null;
    volatility: string | null;
    sharpe: string | null;
    returnDays: {
      positive: number | null;
      negative: number | null;
    };
    dailyReturn: {
      average: string | null;
      maximum: string | null;
      maximumDate: string | null;
      minimum: string | null;
      minimumDate: string | null;
    };
  };
}

export const useFetchVolatilityTableData = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [volatilityTableData, setVolatilityTableData] =
    useState<VolatilityTableData>();
  const {
    apiFetchPeriodRange,
    apiFetchVolatility,
    apiFetchSharpe,
    apiFetchPositiveReturnTimes,
    apiFetchNegativeReturnTimes,
    apiFetchAverageReturn,
    apiFetchMaximumReturn,
    apiFetchMinimumReturn,
  } = useAPI();

  const fetchVolatilityTableData = useCallback(
    async (
      portfolios: PortfoliosType,
      benchmarks: BenchmarksType,
      selectedPeriod: string
    ) => {
      const portfoliosArrayed = Object.keys(portfolios).map(
        (portfolioKey) => portfolios[Number(portfolioKey)]
      );
      const benchmarksArrayed = Object.keys(benchmarks).map(
        (benchmarkKey) => benchmarks[benchmarkKey].asset
      );

      try {
        setIsLoading(true);

        // Pre-fetch the period ranges needed to fetch all other data
        const periodRanges = await Promise.all([
          apiFetchPeriodRange(
            [...portfoliosArrayed, ...benchmarksArrayed],
            selectedPeriod
          ),
          apiFetchPeriodRange(
            [...portfoliosArrayed, ...benchmarksArrayed],
            "UM_ANO_UTIL"
          ),
        ]);

        const selectedPeriodRange = periodRanges[0];
        const yearPeriodRange = periodRanges[1];

        const portfoliosVolatilityData = await Promise.all(
          portfoliosArrayed.map(async (portfolio) => {
            const assetsArrayed = Object.keys(portfolio.assets).map(
              (assetKey) => portfolio.assets[assetKey]
            );

            const assetsVolatilityData = await Promise.all(
              assetsArrayed.map(async (asset) => {
                const assetYearVolatility = await apiFetchVolatility(
                  asset.asset,
                  yearPeriodRange
                );
                const assetVolatility = await apiFetchVolatility(
                  asset.asset,
                  selectedPeriodRange
                );
                const assetSharpe = await apiFetchSharpe(
                  asset.asset,
                  selectedPeriodRange
                );
                const assetPositiveReturnTimes =
                  await apiFetchPositiveReturnTimes(
                    asset.asset,
                    selectedPeriodRange
                  );
                const assetNegativeReturnTimes =
                  await apiFetchNegativeReturnTimes(
                    asset.asset,
                    selectedPeriodRange
                  );
                const assetAverageReturn = await apiFetchAverageReturn(
                  asset.asset,
                  selectedPeriodRange
                );
                const assetMaximumReturn = await apiFetchMaximumReturn(
                  asset.asset,
                  selectedPeriodRange
                );
                const assetMinimumReturn = await apiFetchMinimumReturn(
                  asset.asset,
                  selectedPeriodRange
                );

                return {
                  label: asset.asset.label,
                  yearVolatility: assetYearVolatility?.value || null,
                  volatility: assetVolatility?.value || null,
                  sharpe: assetSharpe?.value || null,
                  returnDays: {
                    positive: assetPositiveReturnTimes?.value || null,
                    negative: assetNegativeReturnTimes?.value || null,
                  },
                  dailyReturn: {
                    average: assetAverageReturn?.value || null,
                    maximum: assetMaximumReturn?.value || null,
                    maximumDate: assetMaximumReturn?.date || null,
                    minimum: assetMinimumReturn?.value || null,
                    minimumDate: assetMinimumReturn?.date || null,
                  },
                };
              })
            );

            const assets = zipObj(
              Object.keys(portfolio.assets),
              assetsVolatilityData
            );

            const portfolioYearVolatility = await apiFetchVolatility(
              portfolio,
              yearPeriodRange
            );
            const portfolioVolatility = await apiFetchVolatility(
              portfolio,
              selectedPeriodRange
            );
            const portfolioSharpe = await apiFetchSharpe(
              portfolio,
              selectedPeriodRange
            );
            const portfolioPositiveReturnTimes =
              await apiFetchPositiveReturnTimes(portfolio, selectedPeriodRange);
            const portfolioNegativeReturnTimes =
              await apiFetchNegativeReturnTimes(portfolio, selectedPeriodRange);
            const portfolioAverageReturn = await apiFetchAverageReturn(
              portfolio,
              selectedPeriodRange
            );
            const portfolioMaximumReturn = await apiFetchMaximumReturn(
              portfolio,
              selectedPeriodRange
            );
            const portfolioMinimumReturn = await apiFetchMinimumReturn(
              portfolio,
              selectedPeriodRange
            );

            return {
              label: portfolio.name,
              yearVolatility: portfolioYearVolatility?.value || null,
              volatility: portfolioVolatility?.value || null,
              sharpe: portfolioSharpe?.value || null,
              returnDays: {
                positive: portfolioPositiveReturnTimes?.value || null,
                negative: portfolioNegativeReturnTimes?.value || null,
              },
              dailyReturn: {
                average: portfolioAverageReturn?.value || null,
                maximum: portfolioMaximumReturn?.value || null,
                maximumDate: portfolioMaximumReturn?.date || null,
                minimum: portfolioMinimumReturn?.value || null,
                minimumDate: portfolioMinimumReturn?.date || null,
              },
              assets,
            };
          })
        );

        const benchmarksVolatilityData = await Promise.all(
          benchmarksArrayed.map(async (benchmark) => {
            const benchmarkYearVolatility = await apiFetchVolatility(
              benchmark,
              yearPeriodRange
            );
            const benchmarkVolatility = await apiFetchVolatility(
              benchmark,
              selectedPeriodRange
            );
            const benchmarkSharpe = await apiFetchSharpe(
              benchmark,
              selectedPeriodRange
            );
            const benchmarkPositiveReturnTimes =
              await apiFetchPositiveReturnTimes(benchmark, selectedPeriodRange);
            const benchmarkNegativeReturnTimes =
              await apiFetchNegativeReturnTimes(benchmark, selectedPeriodRange);
            const benchmarkAverageReturn = await apiFetchAverageReturn(
              benchmark,
              selectedPeriodRange
            );
            const benchmarkMaximumReturn = await apiFetchMaximumReturn(
              benchmark,
              selectedPeriodRange
            );
            const benchmarkMinimumReturn = await apiFetchMinimumReturn(
              benchmark,
              selectedPeriodRange
            );

            return {
              label: benchmark.label,
              yearVolatility: benchmarkYearVolatility?.value || null,
              volatility: benchmarkVolatility?.value || null,
              sharpe: benchmarkSharpe?.value || null,
              returnDays: {
                positive: benchmarkPositiveReturnTimes?.value || null,
                negative: benchmarkNegativeReturnTimes?.value || null,
              },
              dailyReturn: {
                average: benchmarkAverageReturn?.value || null,
                maximum: benchmarkMaximumReturn?.value || null,
                maximumDate: benchmarkMaximumReturn?.date || null,
                minimum: benchmarkMinimumReturn?.value || null,
                minimumDate: benchmarkMinimumReturn?.date || null,
              },
              assets: {},
            };
          })
        );

        const volatilityResults = zipObj(
          [...Object.keys(portfolios), ...Object.keys(benchmarks)],
          [...portfoliosVolatilityData, ...benchmarksVolatilityData]
        );

        setVolatilityTableData(volatilityResults);
      } catch {
        setVolatilityTableData({});
      } finally {
        setIsLoading(false);
      }
    },
    [
      apiFetchPeriodRange,
      apiFetchVolatility,
      apiFetchSharpe,
      apiFetchPositiveReturnTimes,
      apiFetchNegativeReturnTimes,
      apiFetchAverageReturn,
      apiFetchMaximumReturn,
      apiFetchMinimumReturn,
    ]
  );

  const hookReturn = {
    loading: isLoading,
    data: volatilityTableData,
    fetch: fetchVolatilityTableData,
  };

  return hookReturn;
};

export const useFetchRisk = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const { apiFetchPeriodRange, apiFetchAssetRisk } = useAPI();

  const getAssetsRiskResults = useCallback(
    async (
      amount = "100",
      namedAssets: BenchmarksType | PortfolioType["assets"],
      period: string,
      portfoliosPeriodReferenceOnly: PortfoliosType,
      window: number
    ): Promise<{ [x: string]: string[] }> => {
      const initialAmount = String(amount);
      const portfoliosForPeriodReference = Object.keys(
        portfoliosPeriodReferenceOnly
      ).map(
        (portfolioKey) => portfoliosPeriodReferenceOnly[Number(portfolioKey)]
      );
      const benchmarkAssets = Object.keys(namedAssets).map(
        (benchmarkKey) => namedAssets[benchmarkKey].asset
      );

      try {
        setIsLoading(true);
        const periodRange = await apiFetchPeriodRange(
          [...portfoliosForPeriodReference, ...benchmarkAssets],
          period
        );
        const benchmarkRiskResults = await Promise.all(
          benchmarkAssets.map((asset) =>
            apiFetchAssetRisk(asset, initialAmount, periodRange, window)
          )
        );

        const riskResults = zipObj(
          Object.keys(namedAssets),
          benchmarkRiskResults
        );
        setData(riskResults);

        return riskResults as {
          [x: string]: string[] | XMLHttpRequestResponseType[];
        };
      } catch {
        setData({});
        return {};
      } finally {
        setIsLoading(false);
      }
    },
    [apiFetchAssetRisk, apiFetchPeriodRange]
  );

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: getAssetsRiskResults,
  };

  return hookReturn;
};

export const useFetchPortfolioRisk = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const { apiFetchPeriodRange, apiFetchAssetRisk } = useAPI();

  const getPortfoliosRiskResults = useCallback(
    async (
      amount = "100",
      portfolios: PortfoliosType,
      period: string,
      benchmarksPeriodReferenceOnly: BenchmarksType,
      window: number
    ): Promise<{ [x: string]: string[] }> => {
      const initialAmount = String(amount);
      const portfoliosArrayed = Object.keys(portfolios).map(
        (portfolioKey) => portfolios[Number(portfolioKey)]
      );
      const benchmarksForPeriodReference = Object.keys(
        benchmarksPeriodReferenceOnly
      ).map(
        (benchmarkKey) => benchmarksPeriodReferenceOnly[benchmarkKey].asset
      );

      try {
        setIsLoading(true);
        const periodRange = await apiFetchPeriodRange(
          [...portfoliosArrayed, ...benchmarksForPeriodReference],
          period
        );
        const portfolioRiskResults = await Promise.all(
          portfoliosArrayed.map((asset) =>
            apiFetchAssetRisk(asset, initialAmount, periodRange, window)
          )
        );

        const riskResults = zipObj(
          Object.keys(portfolios),
          portfolioRiskResults
        );
        setData(riskResults);

        return riskResults as {
          [x: string]: string[] | XMLHttpRequestResponseType[];
        };
      } catch {
        setData({});
        return {};
      } finally {
        setIsLoading(false);
      }
    },
    [apiFetchAssetRisk, apiFetchPeriodRange]
  );

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: getPortfoliosRiskResults,
  };

  return hookReturn;
};

export const useFetchPortfolioLiquidity = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const { apiFetchAssetLiquidity } = useAPI();

  const translateNumberCode = (numberCode: number): string => {
    const testCode = String(numberCode);
    switch (testCode) {
      case "-1":
        return "np"; // Não possui
      case "-2":
        return "nd"; // ND
      case "-3":
        return "na"; // Não se aplica
      case "-4":
        return "nh"; // Não há
      case "-5":
        return "ni"; // Não informado
      case "-6":
        return "cc"; // Conforme contrato
      case "-7":
        return "ter"; // Término
      case "-8":
        return "sec"; // Secundário
      default:
        return String(numberCode); // Dias de Liquidez
    }
  };

  const getPortfoliosLiquidityResults = useCallback(
    async (
      portfolios: PortfoliosType
    ): Promise<
      {
        days: string;
        value: number;
        percentage: number;
        assets: (false | AssetData)[];
      }[][]
    > => {
      const portfoliosArrayed = Object.keys(portfolios).map(
        (portfolioKey) => portfolios[Number(portfolioKey)]
      );

      try {
        setIsLoading(true);

        // portfolioLiquidityResults =
        //  [ port1, port2]
        //  [[]    , []]
        //  [[{asset: {...}, days: " ", ...} , ]]
        const portfolioLiquidityResults = await Promise.all(
          portfoliosArrayed.map((portfolio) =>
            Promise.all(
              Object.keys(portfolio.assets).map(async (assetPortfolioKey) => {
                const liquidity = await apiFetchAssetLiquidity(
                  portfolio.assets[assetPortfolioKey].asset
                );
                return {
                  asset: portfolio.assets[assetPortfolioKey],
                  days: translateNumberCode(liquidity.value),
                };
              })
            )
          )
        );

        // liquidityCategoriesByPortfolio = [...]
        //  [ port1, port2 ]
        //  [['-1', '-9', 'sec', ...] ]  , [...]]
        const liquidityCategoriesByPortfolio = portfolioLiquidityResults.map(
          (portfolio) => uniq(portfolio.map((liquidity) => liquidity.days))
        );

        // assetsLiquidityByPortfolioByCategories = [...]
        // [ port1, port2 ]
        // [ { '-1': {...assets}, '-9': {...}, ...} , {...} }
        const assetsLiquidityByPortfolioByCategories =
          liquidityCategoriesByPortfolio.map((portfolio, portfolioIndex) =>
            zipObj(
              portfolio,
              portfolio.map((category) =>
                portfolioLiquidityResults[portfolioIndex]
                  .map(
                    (liquidity) =>
                      liquidity.days === category && liquidity.asset
                  )
                  .filter((result) => result)
              )
            )
          );

        // liquidityObjectByPortfolioByCategory =
        // { portfolioKey1: {'-1': [{assets}, {...}, ...], '-9': [...]},  portfolioKey2}
        const portfoliosKeys = Object.keys(portfolios);
        const liquidityObjectByPortfolioByCategory = zipObj(
          portfoliosKeys,
          assetsLiquidityByPortfolioByCategories
        );

        // liquidityFormatted = {
        //   [
        //     { days: "1", value: 10000, percentage: 0.1, assets: {...} },
        //     { days: "2", value: 45000, percentage: 0.45, assets: {...}  },
        //     { days: "4", value: 10000, percentage: 0.1, assets: {...}  },
        //     { days: "sec", value: 10000, percentage: 0.1, assets: {...}  },
        //     { days: "ni", value: 25000, percentage: 0.25, assets: {...}  },
        //   ],
        //   [
        //     { days: "23", value: 50000, percentage: 0.5, assets: {...}  },
        //     { days: "ni", value: 50000, percentage: 0.5, assets: {...}  },
        //   ],
        // };
        const liquidityFormatted = Object.keys(
          liquidityObjectByPortfolioByCategory
        ).map((portfolioKeys) =>
          Object.keys(liquidityObjectByPortfolioByCategory[portfolioKeys]).map(
            (categories) => ({
              days: String(categories),
              value: sum(
                liquidityObjectByPortfolioByCategory[portfolioKeys][
                  categories
                ].map((assets) => Number(assets && assets.amount))
              ),
              percentage: sum(
                liquidityObjectByPortfolioByCategory[portfolioKeys][
                  categories
                ].map((assets) => Number(assets && assets.percentage))
              ),
              assets: liquidityObjectByPortfolioByCategory[portfolioKeys][
                categories
              ].map((assets) => assets),
            })
          )
        );

        setData(liquidityFormatted);

        return liquidityFormatted;
      } catch {
        setData([]);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [apiFetchAssetLiquidity]
  );

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: getPortfoliosLiquidityResults,
  };

  return hookReturn;
};

export const useFetchPeriod = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const { apiFetchPeriodRange } = useAPI();
  const { allPortfolios } = useAllPortfolios();

  const getPeriodDates = useCallback(
    async (period: string): Promise<PeriodType> => {
      const benchmarkAssets = Object.keys(allPortfolios).map(
        (benchmarkKey) => allPortfolios[Number(benchmarkKey)]
      );

      try {
        setIsLoading(true);
        const periodRange = await apiFetchPeriodRange(benchmarkAssets, period);
        setData(periodRange);
        return periodRange;
      } catch {
        const onErrorData = {
          initialDate: null,
          finalDate: null,
          assetsWithNoSeriesIntersection: [],
        };
        setData(onErrorData);
        return onErrorData;
      } finally {
        setIsLoading(false);
      }
    },
    [apiFetchPeriodRange, allPortfolios]
  );

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: getPeriodDates,
  };

  return hookReturn;
};

export const useFetchCompetence = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const { availableCompetences } = useWallet();
  const [data, setData] = useState<CompetenceType>(availableCompetences);
  const { apiFetchCompetenceRange } = useAPI();
  const { allPortfolios } = useAllPortfolios();
  const { setLoadingCompetenceFromSharedLink, sharedLinkTasks } =
    useSharedLink();

  const getCompetences = useCallback(async (): Promise<CompetenceType> => {
    try {
      if (!sharedLinkTasks.isLoadingCompetence) {
        setIsLoading(true);

        const portfoliosAssetsWithKeys = mergeAll(
          Object.keys(allPortfolios).map(
            (key) => allPortfolios[Number(key)].assets
          )
        );

        const portfoliosAssetsArrayed = Object.keys(
          portfoliosAssetsWithKeys
        ).map((assetKey) => ({
          assetType: portfoliosAssetsWithKeys[assetKey].asset.assetType,
          identifier: portfoliosAssetsWithKeys[assetKey].asset.identifier,
        }));

        const competencesDates = await apiFetchCompetenceRange(
          portfoliosAssetsArrayed
        );
        setData(competencesDates);
        return competencesDates;
      }
      return [];
    } catch {
      const onErrorData:
        | SetStateAction<CompetenceType>
        | PromiseLike<CompetenceType> = [];
      setData(onErrorData);
      return onErrorData;
    } finally {
      if (sharedLinkTasks.isLoadingCompetence)
        setLoadingCompetenceFromSharedLink(false);
      setIsLoading(false);
    }
  }, [
    sharedLinkTasks.isLoadingCompetence,
    allPortfolios,
    apiFetchCompetenceRange,
    setLoadingCompetenceFromSharedLink,
  ]);

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: getCompetences,
  };

  return hookReturn;
};

export const useFetchPortfolioComposition = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PortfolioCompositionType[][]>([]);
  const { apiFetchPortfolioComposition } = useAPI();
  const { allPortfolios } = useAllPortfolios();

  const getComposition = useCallback(
    async (
      groupType,
      competenceDate
    ): Promise<PortfolioCompositionType[][]> => {
      try {
        setIsLoading(true);

        const arrayedPortfolios = Object.keys(allPortfolios).map(
          (portfolioId) => allPortfolios[Number(portfolioId)]
        );

        const composition = await Promise.all(
          arrayedPortfolios.map((portfolio) =>
            apiFetchPortfolioComposition(portfolio, groupType, competenceDate)
          )
        );
        setData(composition);
        return composition;
      } catch {
        const onErrorData:
          | SetStateAction<PortfolioCompositionType[][]>
          | PromiseLike<PortfolioCompositionType[][]> = [];
        setData(onErrorData);
        return onErrorData;
      } finally {
        setIsLoading(false);
      }
    },
    [allPortfolios, apiFetchPortfolioComposition]
  );

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: getComposition,
  };

  return hookReturn;
};

export const useFetchSharedStoreByLink = (): typeof hookReturn => {
  const { storeCopy, setStoreForSharedLink } = useStoreForSharedLink();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    apiFetchPortfolio,
    apiFetchBenchmarks,
    apiRemoteAddPortfolio,
    apiFetchCompetenceRange,
  } = useAPI();
  const navigate = useNavigate();
  const { minDate, windowValue, initialApplication } = useGlobalConfigs();
  const { availableConsolidated } = useWallet();
  const { setShareError } = useShowShareError();
  const {
    setLoadingPeriodsFromSharedLink,
    setLoadingCompetenceFromSharedLink,
  } = useSharedLink();

  // Checks if the param is a valid value (reusability)
  const isValidQueryParam = (queryParam: unknown): boolean => {
    return queryParam !== null && queryParam !== undefined && queryParam !== "";
  };

  const isQueryPortfoliosValid = useCallback(
    (queryPortfolioIdentifiers: unknown): boolean => {
      return (
        isValidQueryParam(queryPortfolioIdentifiers) &&
        (Array.isArray(queryPortfolioIdentifiers) ||
          typeof queryPortfolioIdentifiers === "string")
      );
    },
    []
  );

  const isQueryBenchmarksValid = useCallback(
    (queryBenchmarksIdentifiers: unknown): boolean => {
      return (
        isValidQueryParam(queryBenchmarksIdentifiers) &&
        Array.isArray(queryBenchmarksIdentifiers)
      );
    },
    []
  );

  const isQueryPeriodValid = useCallback(
    (queryPeriod: unknown, queryCustomDateRange: unknown): boolean => {
      const validPeriods = [
        "OTIMO",
        "NO_MES",
        "UM_ANO_UTIL",
        "SEIS_MESES_UTEIS",
        "CINCO_ANOS_UTEIS",
        "DATA_PERSONALIZADA",
      ];

      const isValidCustomDateRange = (customDateRange: unknown): boolean => {
        const [initialDate, finalDate] =
          Array.isArray(customDateRange) && customDateRange.length === 2
            ? [new Date(customDateRange[0]), new Date(customDateRange[1])]
            : [];
        return (
          initialDate !== undefined &&
          finalDate !== undefined &&
          isValidQueryParam(initialDate) &&
          isValidQueryParam(finalDate) &&
          isValid(initialDate) &&
          isValid(finalDate) &&
          isBefore(finalDate, startOfTomorrow()) &&
          isAfter(initialDate, minDate) &&
          isAfter(finalDate, initialDate) &&
          isBefore(initialDate, finalDate) &&
          isBefore(initialDate, startOfTomorrow())
        );
      };

      return isValidQueryParam(queryPeriod) &&
        validPeriods.includes(queryPeriod as string) &&
        queryPeriod === "DATA_PERSONALIZADA"
        ? isValidCustomDateRange(queryCustomDateRange)
        : true;
    },
    [minDate]
  );

  const isQueryWindowValueValid = useCallback(
    (queryWindowValue: unknown): boolean => {
      return (
        isValidQueryParam(queryWindowValue) &&
        typeof queryWindowValue === "number" &&
        queryWindowValue >= windowValue.min &&
        queryWindowValue <= windowValue.max
      );
    },
    [windowValue.max, windowValue.min]
  );

  const isQueryInitialApplicationValid = useCallback(
    (queryInitialApplication: unknown): boolean => {
      return (
        isValidQueryParam(queryInitialApplication) &&
        typeof queryInitialApplication === "number" &&
        queryInitialApplication >= initialApplication.min &&
        queryInitialApplication <= initialApplication.max
      );
    },
    [initialApplication.max, initialApplication.min]
  );

  const isQueryCurrentPortfolioIdValid = useCallback(
    (queryCurrentPortfolioId: unknown): boolean => {
      return (
        isValidQueryParam(queryCurrentPortfolioId) &&
        typeof queryCurrentPortfolioId === "number" &&
        queryCurrentPortfolioId >= 0
      );
    },
    []
  );

  const isQueryConsolidatedValid = useCallback(
    (queryConsolidated: unknown): boolean => {
      return (
        isValidQueryParam(queryConsolidated) &&
        typeof queryConsolidated === "string" &&
        availableConsolidated.includes(queryConsolidated as string)
      );
    },
    [availableConsolidated]
  );

  const isQueryCompetenceValid = useCallback(
    (
      queryCompetence: unknown,
      availableCompetences: CompetenceType
    ): boolean => {
      return (
        isValidQueryParam(queryCompetence) &&
        typeof queryCompetence === "string" &&
        availableCompetences.includes(queryCompetence) &&
        isValid(new Date(queryCompetence))
      );
    },
    []
  );

  const getSharedStoreByLink = useCallback(
    async ({
      portfolios: queryPortfolioIdentifiers,
      benchmarks: queryBenchmarksIdentifiers,
      period: queryPeriod,
      windowValue: queryWindowValue,
      initialApplication: queryInitialApplication,
      currentPortfolioId: queryCurrentPortfolioId,
      selectedCustomDateRange: queryCustomDateRange,
      consolidated: queryConsolidated,
      competence: queryCompetence,
    }) => {
      try {
        setIsError(false);
        setIsLoading(true);
        if (
          !isQueryPortfoliosValid(queryPortfolioIdentifiers) ||
          !isQueryPeriodValid(queryPeriod, queryCustomDateRange) ||
          !isQueryWindowValueValid(queryWindowValue) ||
          !isQueryInitialApplicationValid(queryInitialApplication) ||
          !isQueryCurrentPortfolioIdValid(queryCurrentPortfolioId) ||
          !isQueryConsolidatedValid(queryConsolidated)
        ) {
          // DEBUG use Only
          //
          // console.log(
          //   "isQueryPortfoliosValid",
          //   isQueryPortfoliosValid(queryPortfolioIdentifiers)
          // );
          // console.log(
          //   "isQueryPeriodValid",
          //   isQueryPeriodValid(queryPeriod, queryCustomDateRange)
          // );
          // console.log(
          //   "isQueryWindowValueValid",
          //   isQueryWindowValueValid(queryWindowValue)
          // );
          // console.log(
          //   "isQueryInitialApplicationValid",
          //   isQueryInitialApplicationValid(queryInitialApplication)
          // );
          // console.log(
          //   "isQueryCurrentPortfolioIdValid",
          //   isQueryCurrentPortfolioIdValid(queryCurrentPortfolioId)
          // );
          // console.log(
          //   "isQueryBenchmarksValid",
          //   isQueryBenchmarksValid(queryBenchmarksIdentifiers)
          // );
          // console.log(
          //   "isQueryConsolidatedValid",
          //   isQueryConsolidatedValid(queryConsolidated),
          // );
          throw new Error("Missing query parameters");
        }

        const [{ remotePortfolios, remoteBenchmarks }] = await Promise.all([
          {
            remotePortfolios: await Promise.all(
              Array.isArray(queryPortfolioIdentifiers)
                ? queryPortfolioIdentifiers.map((portfolioId) =>
                    apiFetchPortfolio(portfolioId)
                  )
                : [apiFetchPortfolio(queryPortfolioIdentifiers)]
            ),
            remoteBenchmarks: isQueryBenchmarksValid(queryBenchmarksIdentifiers)
              ? await apiFetchBenchmarks()
              : [],
          },
        ]);

        const portfoliosAssetsWithKeys = mergeAll(
          Object.keys(remotePortfolios).map(
            (key) => remotePortfolios[Number(key)].assets
          )
        );

        const portfoliosAssetsArrayed = Object.keys(
          portfoliosAssetsWithKeys
        ).map((assetKey) => ({
          assetType: portfoliosAssetsWithKeys[assetKey].asset.assetType,
          identifier: portfoliosAssetsWithKeys[assetKey].asset.identifier,
        }));

        const availableCompetences = await apiFetchCompetenceRange(
          portfoliosAssetsArrayed
        );

        const clonedRemotePortfolios = await Promise.all(
          remotePortfolios.map(async (portfolio) => ({
            ...portfolio,
            assetType: "PORTFOLIO" as const,
            identifier: await apiRemoteAddPortfolio(portfolio),
          }))
        );

        const portfolios = zipObj(
          clonedRemotePortfolios.map((_portfolio, index) => index),
          clonedRemotePortfolios
        );

        let benchmarks = {};
        if (isQueryBenchmarksValid(queryBenchmarksIdentifiers)) {
          const unfilteredBenchmarks = zipObj(
            remoteBenchmarks.map(
              (benchmark) =>
                `${String(benchmark.assetType)}❤❤❤${String(
                  benchmark.identifier
                )}`
            ),
            remoteBenchmarks.map((benchmark) => ({
              asset: benchmark,
            }))
          );
          const parsedBenchmarksFromQueryString: string[] = [];
          queryBenchmarksIdentifiers.forEach(
            (_benchmarkIdentifier: string, index: number) => {
              if (index % 2 === 0)
                parsedBenchmarksFromQueryString.push(
                  `${String(queryBenchmarksIdentifiers[index])}❤❤❤${String(
                    queryBenchmarksIdentifiers[index + 1]
                  )}`
                );
            }
          );
          benchmarks = filter(
            (remoteBenchmark) =>
              parsedBenchmarksFromQueryString.includes(
                `${String(remoteBenchmark.asset.assetType)}❤❤❤${String(
                  remoteBenchmark.asset.identifier
                )}`
              ),
            unfilteredBenchmarks
          );
        }

        setStoreForSharedLink({
          ...storeCopy,
          portfolios,
          benchmarks,
          period: queryPeriod,
          windowValue: Number(queryWindowValue),
          applicationAmount: Number(queryInitialApplication),
          currentPortfolioId: Number(queryCurrentPortfolioId),
          consolidated: queryConsolidated,
          competence: isQueryCompetenceValid(
            queryCompetence,
            availableCompetences
          )
            ? queryCompetence
            : availableCompetences[0] || null,
          globalConfigs: {
            ...storeCopy.globalConfigs,
            availableCompetences,
            customDateRange: {
              initialDate:
                queryPeriod === "DATA_PERSONALIZADA"
                  ? queryCustomDateRange[0]
                  : null,
              finalDate:
                queryPeriod === "DATA_PERSONALIZADA"
                  ? queryCustomDateRange[1]
                  : null,
            },
          },
        });

        setLoadingPeriodsFromSharedLink(true);
        setLoadingCompetenceFromSharedLink(true);
        navigate("../analysis");
      } catch (error) {
        setIsError(true);
        setStoreForSharedLink({ ...storeCopy });
        navigate("../");
        setShareError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [
      isQueryPortfoliosValid,
      isQueryPeriodValid,
      isQueryWindowValueValid,
      isQueryInitialApplicationValid,
      isQueryCurrentPortfolioIdValid,
      isQueryConsolidatedValid,
      apiFetchPortfolio,
      isQueryBenchmarksValid,
      apiFetchBenchmarks,
      apiFetchCompetenceRange,
      setStoreForSharedLink,
      storeCopy,
      isQueryCompetenceValid,
      setLoadingPeriodsFromSharedLink,
      setLoadingCompetenceFromSharedLink,
      navigate,
      apiRemoteAddPortfolio,
      setShareError,
    ]
  );

  const hookReturn = {
    loading: isLoading,
    error: isError,
    fetch: getSharedStoreByLink,
  };

  return hookReturn;
};

export const useAddRemoteCustomBenchmark = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const { apiRemoteAddCustomBenchmark } = useAPI();

  const addRemoteCustomBenchmark = async (
    customBenchmark: CustomBenchmarkType
  ): Promise<string> => {
    try {
      setIsLoading(true);
      const remoteId = await apiRemoteAddCustomBenchmark(customBenchmark);
      setData(remoteId);
      return remoteId;
    } catch {
      setData("failed");
      return "failed";
    } finally {
      setIsLoading(false);
    }
  };

  const hookReturn = {
    data,
    loading: isLoading,
    send: addRemoteCustomBenchmark,
  };

  return hookReturn;
};

export const useFetchBaseDate = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState("");
  const { apiFetchBaseDate } = useAPI();

  const fetchBaseDate = async (): Promise<string> => {
    try {
      setIsLoading(true);
      const baseDate = await apiFetchBaseDate();
      setData(baseDate);
      return baseDate;
    } catch {
      setData("");
      return "";
    } finally {
      setIsLoading(false);
    }
  };

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: fetchBaseDate,
  };

  return hookReturn;
};

export const useSendTokenEmail = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const { apiSendAccessLinkEmail } = useAPI();

  const sendEmailWithToken = async (
    email: string,
    remember: boolean
  ): Promise<boolean | null> => {
    try {
      setIsLoading(true);
      const sendEmailRes = await apiSendAccessLinkEmail(email, remember);

      return sendEmailRes;
    } catch {
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const hookReturn = {
    loading: isLoading,
    fetch: sendEmailWithToken,
  };

  return hookReturn;
};

export const useFetchUserAuth = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const { apiValidateSupercarteiraToken } = useAPI();
  const { setIsUserAuthenticated, setLocalStorageToken } = useAuth();
  const { userData: data, setUserData } = useUserData();

  const fetchAndAuthenticateUser = async (
    token: string
  ): Promise<ApiValidateSupercarteiraTokenReturn | null> => {
    try {
      setIsLoading(true);
      const res = await apiValidateSupercarteiraToken(token);

      const isAuthenticatedUser = res !== null;

      if (isAuthenticatedUser) {
        if (res.user) setUserData(res.user);
        setIsUserAuthenticated(true);
        setLocalStorageToken(res.token, String(res?.user?.email ?? ""));
      }

      return res;
    } catch {
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: fetchAndAuthenticateUser,
  };

  return hookReturn;
};

export const useFetchUserData = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const { apiFetchUserData } = useAPI();
  const {
    setIsUserAuthenticated,
    // setLocalStorageToken
  } = useAuth();
  const { userData: data, setUserData } = useUserData();

  const fetchUserData = async (email: string): Promise<UserDataType | null> => {
    try {
      setIsLoading(true);
      const userData = await apiFetchUserData(email);

      const isAuthenticatedUser = userData !== null;

      if (isAuthenticatedUser) {
        setUserData(userData);
        setIsUserAuthenticated(true);
        // setLocalStorageToken(email);
      }
      return userData;
    } catch {
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: fetchUserData,
  };

  return hookReturn;
};

export const useSaveUserData = (): typeof hookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<UserDataType | string | null>(null);
  const { apiSaveUserSupercarteiraData, apiClonePortfolio } = useAPI();
  const { setUserData } = useUserData();

  const saveUserData = async (
    email: string,
    newData: UserDataType["portfolios"][superCarteiraFeatures],
    portfolioType: superCarteiraFeatures
  ): Promise<void> => {
    try {
      setIsLoading(true);
      setData(null);

      const newPortfolioId = newData.id
        ? await apiClonePortfolio(newData.id)
        : null;

      if (newPortfolioId === null) {
        throw new Error("Cloning Error");
      }

      const userData = await apiSaveUserSupercarteiraData({
        userEmail: email,
        newData: {
          ...newData,
          id: String(newPortfolioId),
        },
        portfolioType,
      });

      if (userData) {
        setData(userData);
        setUserData(userData);
      } else {
        setData("error");
      }
    } catch {
      setData("error");
    } finally {
      setIsLoading(false);
    }
  };

  const hookReturn = {
    data,
    loading: isLoading,
    fetch: saveUserData,
  };

  return hookReturn;
};
