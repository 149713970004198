import React from "react";
import { useClonePortfolio, useSideEditor } from "../../../../store";
import { SBar, SButton, SEditIcon, STitle } from "./styles";

interface PortfoliosButtonPropsType {
  barColor: string;
  text: string;
  id: string;
  cloneMode?: boolean;
  cloneModeCloseMenu?: () => void | string;
  chipMode?: boolean;
}

export const PortfoliosButton: React.FC<PortfoliosButtonPropsType> = ({
  text,
  barColor,
  id,
  cloneMode,
  cloneModeCloseMenu,
  chipMode,
}) => {
  PortfoliosButton.defaultProps = {
    cloneMode: false,
    cloneModeCloseMenu: () => "",
    chipMode: false,
  };

  const { clonePortfolioFromId } = useClonePortfolio();
  const { portfolioBeingEdited, setPortfolioBeingEdited } = useSideEditor();

  const handleSelect = (): void => {
    if (!cloneMode) {
      if (portfolioBeingEdited === Number(id)) {
        setPortfolioBeingEdited(null);
      } else {
        setPortfolioBeingEdited(Number(id));
      }
    }

    if (cloneMode) {
      clonePortfolioFromId(Number(id));
      if (cloneModeCloseMenu) cloneModeCloseMenu();
    }
  };

  return (
    <SButton
      endIcon={
        !chipMode &&
        !cloneMode &&
        portfolioBeingEdited !== Number(id) && <SEditIcon />
      }
      selected={
        (!cloneMode && portfolioBeingEdited === Number(id)) || Boolean(chipMode)
      }
      onClick={handleSelect}
    >
      <SBar barColor={barColor} />
      <STitle $chipMode={chipMode || false}>{text}</STitle>
    </SButton>
  );
};
