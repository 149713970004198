import {
  ApplicationInsights,
  IEventTelemetry,
  IPageViewTelemetry,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const APPLICATION_INSIGHTS_CONNECTION_STRING =
  process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING;
const SUBSCRIPTION_KEY = process.env.REACT_APP_API_KEY;
const THEME = process.env.REACT_APP_THEME || "";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

const customTags = {
  "quantum.subscription_key": String(SUBSCRIPTION_KEY),
};

class Analytics {
  private static instance: Analytics;

  private appInsights?: ApplicationInsights;

  public constructor() {
    if (APPLICATION_INSIGHTS_CONNECTION_STRING) {
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: String(APPLICATION_INSIGHTS_CONNECTION_STRING),
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
          },
        },
      });
      this.appInsights.loadAppInsights();
      this.appInsights.trackPageView({
        name: `Comparador de Portfólios - ${THEME}`,
        properties: {
          ...customTags,
        },
      });

      this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
        // eslint-disable-next-line no-param-reassign
        envelope.tags = envelope.tags || [];
        envelope.tags.push(customTags);
      });
    }
  }

  public trackEvent(event: AnalyticsEvent): void {
    this.appInsights?.trackEvent({
      ...event,
      properties: {
        ...event.properties,
        ...customTags,
      },
    });
  }

  public trackPageview(pageview: AnalyticsPageview): void {
    this.appInsights?.trackPageView({
      ...pageview,
      properties: {
        ...pageview.properties,
        ...customTags,
      },
    });
  }

  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }

    return Analytics.instance;
  }
}

export default Analytics;

export interface AnalyticsEvent extends IEventTelemetry {
  name: string;
  properties: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

export interface AnalyticsPageview extends IPageViewTelemetry {
  name: string;
  properties: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}
