import styled from "styled-components";
import { SvgIcon, Grid } from "@mui/material";

interface SSvgPropsType {
  $isBalanced: boolean;
}

export const SGrid = styled(Grid)`
  && {
    padding: 0px;
    padding-top: 16px;
    margin: 0px;
    width: 50px;
    justify-content: center;
    align-items: center;
  }
`;

export const SSvgIcon = styled(SvgIcon)<SSvgPropsType>`
  && {
    opacity: ${({ $isBalanced }) => ($isBalanced ? 0.4 : 1)};
    height: 36px;
    width: 36px;
    margintop: -10%;
  }
`;
