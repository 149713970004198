import { Button, Typography } from "@mui/material";
import styled from "styled-components";

export const SButton = styled(Button)`
  && {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colors.textDefault};
    border-radius: 8px;
    text-transform: capitalize;
    background-color: "inherit";
  }
`;

export const STitle = styled(Typography)`
  && {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #343a40;
  }
`;

interface SBarPropsTypes {
  barColor: string;
}

export const SBar = styled.div<SBarPropsTypes>`
  height: 16px;
  width: 4px;
  min-width: 4px;
  content: "";
  background-color: ${({ barColor }) => barColor || "black"};
`;
