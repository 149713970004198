import { jwtDecode, JwtPayload } from "jwt-decode";
import { DefaultTheme } from "styled-components";
import { isEmailValid } from "../general/ValidationUtils";

/**
 * Determines if a JWT token is expired.
 *
 * @param decodedToken - The decoded JWT payload.
 * @param theme - The theme configuration object which includes authentication settings.
 * @returns `true` if the token is expired, `false` otherwise.
 *
 * @remarks
 * The function first checks the `checkTokenExpirationOnClientSide` flag from the theme's
 * authentication settings. If this flag is set to `true`, the function immediately returns `false`,
 * indicating that the token expiration should not be checked on the client side.
 *
 * If the client-side check is allowed, the function then verifies if the `exp` (expiration time)
 * claim is present in the token payload and if it is a number. If so, it compares the expiration time
 * to the current time. If the expiration time is less than the current time, the token is expired.
 * If the `exp` claim is missing or not a number, the function returns `true`, indicating
 * that the token should be considered expired.
 *
 * @example
 * ```typescript
 * const token: JwtPayload = { exp: Math.floor(Date.now() / 1000) - 10 };
 * const theme: DefaultTheme = { auth: { checkTokenExpirationOnClientSide: false } };
 * const expired = isTokenExpired(token, theme);
 * console.log(expired); // true
 * ```
 */
export const isTokenExpired = (
  decodedToken: JwtPayload,
  theme: DefaultTheme
): boolean => {
  if (theme.auth.checkTokenExpirationOnClientSide) return false;

  return decodedToken && typeof decodedToken.exp === "number"
    ? decodedToken.exp < Math.floor(Date.now() / 1000)
    : true;
};

/**
 * Removes from localstorage both "token" and "login" keys
 */
export function removeCredentials(): void {
  localStorage.removeItem("token");
  localStorage.removeItem("login");
}

/**
 * Retrieves the local storage state of the variable named "token",
 * deconverts it from base64 encoding, and checks if the current date
 * is equal or less than the date it was set on "token".
 *
 * @returns {{
 *   valid: true;
 *   login: string;
 * } | {
 *   valid: false;
 *   login: null;
 * }} Credentials discriminated union that has a string login if credentials are valid, and null otherwise
 */
export function getCredentials(
  theme: DefaultTheme
): { valid: true; login: string } | { valid: false; login: null } {
  try {
    const storedToken = localStorage.getItem("token");
    const storedLogin = localStorage.getItem("login");

    if (storedToken && storedLogin) {
      const decodedToken = jwtDecode(storedToken);

      const storedEmail = atob(storedLogin);
      const isLoginValid = isEmailValid(storedEmail);

      if (isTokenExpired(decodedToken, theme) || !isLoginValid) {
        if (process.env.NODE_ENV === "development")
          console.error("AUTH: storage long exp token is invalid", {
            storedEmail,
            decodedToken,
            isTokenExpired: isTokenExpired(decodedToken, theme),
            isLoginValid,
          });

        removeCredentials();
        return { valid: false, login: null };
      }

      if (process.env.NODE_ENV === "development")
        console.warn("AUTH: storage long exp token is valid", {
          storedEmail,
          decodedToken,
        });

      return { valid: true, login: storedEmail };
    }

    removeCredentials();
    return { valid: false, login: null };
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error decoding or parsing token:", error);

    return { valid: false, login: null };
  }
}

/**
 * Sets the "token" local storage variable.
 * Sets the "login" local storage variable.
 *
 * @param token - The JWT token to be stored in local storage.
 * @param login - The user login credendial to be stored.
 */
export function setCredentials(token: string, login: string): void {
  try {
    const encodedLogin = btoa(login);
    localStorage.setItem("token", token);
    localStorage.setItem("login", encodedLogin);
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error encoding or setting token:", error);
  }
}
