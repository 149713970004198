import { AnyAction, Middleware } from "redux";
import {
  flatten,
  map,
  mergeAll,
  pipe,
  prop,
  propOr,
  uniq,
  values,
} from "ramda";

import Analytics from ".";
import { SearchResultsType } from "../components/shared";
import { ReduxActions } from "../store/actions";
import {
  AssetData,
  AssetsType,
  PortfoliosType,
  PortfolioType,
  ReduxStateType,
} from "../store";

const analytics = Analytics.getInstance();

type AssetId = {
  assetType: string | null;
  identifier: string | null;
};
const createAssetIdentifier = ({ assetType, identifier }: AssetId): string => {
  return `${assetType}|${identifier}`;
};

const manageAddAssetEvent = (
  state: ReduxStateType,
  action: AnyAction
): void => {
  try {
    const portfolios: PortfoliosType = propOr({}, "portfolios")(state);

    const previousAssets = pipe<
      PortfoliosType[],
      PortfolioType[],
      AssetsType[],
      AssetData[][],
      AssetData[],
      SearchResultsType[],
      SearchResultsType[]
    >(
      values,
      map(prop("assets")),
      map(values),
      flatten,
      map(prop("asset")),
      uniq
    )(portfolios);

    const currentAsset: SearchResultsType = action.payload;

    if (!previousAssets.includes(currentAsset)) {
      const assetId = createAssetIdentifier(currentAsset);

      analytics.trackEvent({
        name: "ativo_adicionado",
        properties: {
          ativo_id: assetId,
          ativo_nome: currentAsset.label,
          ativo: currentAsset,
        },
      });
    }
  } catch (err) {
    // Do nothing
    // TODO: Add Setry
  }
};

const manageRemoveAssetEvent = (
  state: ReduxStateType,
  action: AnyAction
): void => {
  try {
    const portfolios: PortfoliosType = propOr({}, "portfolios")(state);

    const previousAssets = pipe<
      PortfoliosType[],
      PortfolioType[],
      AssetsType[],
      AssetsType
    >(
      values,
      map(prop("assets")),
      mergeAll
    )(portfolios);

    const assetKey: string = action.payload;
    const currentAsset: SearchResultsType = previousAssets[assetKey]?.asset;

    if (currentAsset) {
      const assetId = createAssetIdentifier(currentAsset);

      analytics.trackEvent({
        name: "ativo_removido",
        properties: {
          ativo_id: assetId,
          ativo_nome: currentAsset.label,
          ativo: currentAsset,
        },
      });
    }
  } catch (err) {
    // Do nothing
    // TODO: Add Setry
  }
};

export const createAnalyticsMiddleware: Middleware =
  (store) => (next) => (action: ReduxActions) => {
    const state: ReduxStateType = store.getState();

    switch (action.type) {
      case "ADD_OR_UPDATE_ASSET_TO_PORTFOLIO":
        manageAddAssetEvent(state, action);
        break;
      case "REMOVE_ASSET_FROM_PORTFOLIO":
        manageRemoveAssetEvent(state, action);
        break;
      default:
        break;
    }

    return next(action);
  };
