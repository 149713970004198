import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { SearchFilterButton } from "../../index";
import { CreateAssetButton } from "./CreateAssetButton/CreateAssetButton";
import { CreateAssetDialog } from "./CreateAssetDialog/CreateAssetDialog";
import { SFiltersGrid } from "./styles";
import { useRemoteBenchmarks } from "../../../../api";
import { useCustomBenchmarkAsset } from "../../../../store";

export interface FilterListType {
  funds: boolean;
  stocks: boolean;
  fixedIncome: boolean;
  pension: boolean;
  createAsset: boolean;
  valorization: boolean;
  diversification: boolean;
  stability: boolean;
  antifragility: boolean;
  others: boolean;
}

interface SearchFilterListPropType {
  passSelectedToParent: React.Dispatch<React.SetStateAction<FilterListType>>;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setResults: React.Dispatch<React.SetStateAction<string | JSON>>;
}

export const SearchFilterList: React.FC<SearchFilterListPropType> = ({
  passSelectedToParent,
  setSearchText,
  setResults,
}) => {
  const theme = useTheme();
  const [searchFilters, setSearchFilters] = useState({
    funds: false,
    stocks: false,
    fixedIncome: false,
    pension: false,
    createAsset: false,
    valorization: false,
    diversification: false,
    stability: false,
    antifragility: false,
    others: false,
  } as FilterListType);
  const [isCreateAssetDialogOpen, setIsCreateAssetDialogOpen] = useState(false);
  const { t } = useTranslation();
  const { loading: isBenchmarkFetchLoading, data: benchmarks } =
    useRemoteBenchmarks();
  const { setShowAllSearchableCustomBenchmarks } = useCustomBenchmarkAsset();

  useEffect(
    () => passSelectedToParent(searchFilters),
    [passSelectedToParent, searchFilters]
  );

  const handleOpenAddAssetDialog = (): void => {
    setIsCreateAssetDialogOpen(true);
    setShowAllSearchableCustomBenchmarks(true);
    setSearchText("");
    setResults("");
  };

  const handleCloseAddAssetDialog = (): void => {
    setIsCreateAssetDialogOpen(false);
  };

  return (
    <SFiltersGrid>
      {theme.assetSearch.funds.show ? (
        <SearchFilterButton
          id="funds"
          text={t("searchAsset.funds")}
          barColor={theme.colors.funds || "purple"}
          setFilterIsSelected={setSearchFilters}
        />
      ) : null}
      {theme.assetSearch.stocks.show ? (
        <SearchFilterButton
          id="stocks"
          text={t("searchAsset.stocks")}
          barColor={theme.colors.stocks || "orange"}
          setFilterIsSelected={setSearchFilters}
        />
      ) : null}
      {theme.assetSearch.fixedIncome.show ? (
        <SearchFilterButton
          id="fixedIncome"
          text={t("searchAsset.fixedIncome")}
          barColor={theme.colors.fixedIncome || "pink"}
          setFilterIsSelected={setSearchFilters}
        />
      ) : null}
      {theme.assetSearch.stability.show ? (
        <SearchFilterButton
          id="stability"
          text={t("searchAsset.stability")}
          barColor={theme.colors.stability || "pink"}
          setFilterIsSelected={setSearchFilters}
        />
      ) : null}

      {theme.assetSearch.diversification.show ? (
        <SearchFilterButton
          id="diversification"
          text={t("searchAsset.diversification")}
          barColor={theme.colors.diversification || "pink"}
          setFilterIsSelected={setSearchFilters}
        />
      ) : null}

      {theme.assetSearch.valorization.show ? (
        <SearchFilterButton
          id="valorization"
          text={t("searchAsset.valorization")}
          barColor={theme.colors.valorization || "pink"}
          setFilterIsSelected={setSearchFilters}
        />
      ) : null}

      {theme.assetSearch.antifragility.show ? (
        <SearchFilterButton
          id="antifragility"
          text={t("searchAsset.antifragility")}
          barColor={theme.colors.antifragility || "pink"}
          setFilterIsSelected={setSearchFilters}
        />
      ) : null}
      {theme.assetSearch.others.show ? (
        <SearchFilterButton
          id="others"
          text={t("searchAsset.others")}
          barColor={theme.colors.others || "pink"}
          setFilterIsSelected={setSearchFilters}
        />
      ) : null}

      {/* <SearchFilterButton
          id="pension"
          text={t("searchAsset.pension")}
          barColor={theme.colors.pension || "green"}
          setFilterIsSelected={setSearchFilters}
          /> */}
      {/* <SearchFilterButton
          id="createAsset"
          text={t("searchAsset.createAsset")}
          barColor={theme.colors.custom || "blue"}
          setFilterIsSelected={setSearchFilters}
          /> */}
      {theme.assetSearch.createAsset.show ? (
        <CreateAssetButton
          id="createAsset"
          text={t("searchAsset.createAsset")}
          barColor={theme.colors.custom || "blue"}
          onClick={handleOpenAddAssetDialog}
        />
      ) : null}
      {!isBenchmarkFetchLoading && benchmarks.length > 0 ? (
        <CreateAssetDialog
          open={isCreateAssetDialogOpen}
          onClose={() => {
            handleCloseAddAssetDialog();
          }}
          benchmarks={benchmarks}
        />
      ) : null}
    </SFiltersGrid>
  );
};
