import {
  Typography,
  Grid,
  Card,
  Slider,
  Checkbox,
  Button,
  Input,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SavingsIcon from "@mui/icons-material/Savings";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { BarChart, DeleteOutline } from "@mui/icons-material";
import styled from "styled-components";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import NumberFormat from "react-number-format";

export const SAssetControls = styled(Grid)`
  && {
    height: 100%;
    width: 100%;
    margin: 0;
    margin-bottom: 24px;
    padding: 0;
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const SCardWrapper = styled(Grid)`
  && {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

interface SCardPropTypes {
  $selected: boolean;
}

export const SCard = styled(Card)<SCardPropTypes>`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
    padding-top: 14px;
    margin-bottom: ${({ $selected }) => ($selected ? "11px" : "24px")};
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
    background-color: ${({ theme, $selected }) =>
      $selected ? theme.colors.cardBgSelected : theme.colors.cardBg};
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const SCardColumn1 = styled(Grid)`
  && {
    display: ${({ theme }) =>
      theme.portfolioSummary.noCardIcons ? "none" : "inherit"};
    width: 46px;
    padding-left: 12px;
    padding-right: 8px;
    height: 100%;
    align-self: stretch;
  }
`;

export const SCardColumn2 = styled(Grid)`
  && {
    width: calc(100% - 46px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
    padding-right: ${({ theme }) =>
      theme.portfolioSummary.noCardIcons ? "0px" : "12px"};
  }
`;

export const SCardRow1 = styled(Grid)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
  }
`;

export const SCardRow2 = styled(Grid)`
  && {
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    width: 100%;
  }
`;

export const SCardRow3 = styled(Grid)`
  && {
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SCardRow4 = styled(Grid)`
  && {
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SCardRow5 = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const SCardTitle = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.textDefault};
  }
`;

export const SCardSubTitle = styled(Typography)`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.textGray};
  }
`;

export const SCardPercentageContainer = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SCardLabelPercentage = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textDefault};
    margin-right: 5px;
    width: 120px;
    text-align: right;
  }
`;

export const SCardLabelAmount = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textDefault};
    width: 120px;
    text-align: right;
  }
`;
interface SCardInputPercentagePropsType {
  $selected: boolean;
}

export const SCardInputPercentage = styled(
  NumberFormat
)<SCardInputPercentagePropsType>`
  && {
    width: 120px;
    height: 40px;
    margin: 0;
    padding: 0;
    padding-left: 6px;
    padding-right: 6px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    border: none;
    box-shadow: none;
    text-align: right;
    color: ${({ theme }) => theme.colors.textDefault || "black"};
    background-color: ${({ theme, $selected, disabled }) =>
      // eslint-disable-next-line no-nested-ternary
      $selected
        ? theme.colors.cardBgSelected
        : disabled
        ? "none"
        : theme.colors.bgGray || "gray"};

    &:focus {
      outline: none !important;
      outline-offset: none !important;
    }

    input {
      text-align: right;
      padding-right: 6px;
    }

    input:hover {
      cursor: "default";
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.colors.bgGray || "gray"} inset;
    }
    border-radius: 2px;
    z-index: 999;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

interface SCardInputAmountPropsType {
  $selected: boolean;
}

export const SCardInputAmount = styled(Input)<SCardInputAmountPropsType>`
  && {
    width: 120px;
    height: 40px;
    margin: 0;
    padding: 0;
    padding-left: 6px;
    padding-right: 6px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    border: none;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDefault || "black"};
    background-color: ${({ theme, $selected, disabled }) =>
      // eslint-disable-next-line no-nested-ternary
      $selected
        ? theme.colors.cardBgSelected
        : disabled
        ? "none"
        : theme.colors.bgGray || "gray"};

    &:focus {
      outline: none !important;
      outline-offset: none !important;
    }

    input {
      text-align: right;
    }

    input:hover {
      cursor: "default";
    }
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.colors.bgGray || "gray"} inset;
    }
    border-radius: 2px;
    z-index: 999;
  }
`;

export const SCardPercentageSlider = styled(Slider)`
  && {
    color: ${({ theme }) => theme.colors.addButtonPrimary};
    width: 100%;
  }
`;

interface SIconContainerPropsType {
  $backgroundColor: string;
}

export const SIconContainer = styled(Grid)<SIconContainerPropsType>`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
  }
`;

export const SCardHomeIcon = styled(HomeIcon)`
  && {
    color: white;
    background-color: ${({ theme }) => theme.colors.funds};
    border-radius: 4px;
    font-size: 20px;
  }
`;

export const SCardHogIcon = styled(SavingsIcon)`
  && {
    color: white;
    background-color: ${({ theme }) => theme.colors.fixedIncome};
    border-radius: 4px;
    font-size: 18px;
  }
`;

export const SCardBarIcon = styled(BarChart)`
  && {
    color: white;
    background-color: ${({ theme }) => theme.colors.custom};
    border-radius: 4px;
    font-size: 24px;
  }
`;

export const SCardBuildIcon = styled(AccountBalanceIcon)`
  && {
    color: white;
    background-color: ${({ theme }) => theme.colors.pension};
    border-radius: 4px;
    font-size: 20px;
  }
`;

export const SCardBagIcon = styled(BusinessCenterIcon)`
  && {
    color: white;
    background-color: ${({ theme }) => theme.colors.stocks};
    border-radius: 4px;
    font-size: 18px;
  }
`;

export const SCardCheckBox = styled(Checkbox)`
  && {
    width: 24px;
    height: 24px;
    align-self: flex-start;
    margin: 0;
    padding: 0;
    margin-left: 10px;
    color: ${({ theme, checked }) =>
      checked
        ? theme.colors.cardCheckboxChecked
        : theme.colors.cardCheckboxUnchecked};
  }
`;

export const SDeleteButton = styled(Button)`
  && {
    margin: 0;
    padding: 0;
    width: 85px;
    height: 36px;
    border-radius: 4px;
    padding: 0px 8px;
    margin-right: 8px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.textDefault};
    background-color: ${({ theme }) => theme.colors.cardBgSelected};

    &:hover {
      background-color: ${({ theme }) => theme.colors.cardBgSelected};
    }
  }
`;

export const STrashCanIcon = styled(DeleteOutline)`
  && {
    font-size: 18px;
    line-height: 18px;
    margin-right: 2px;
  }
`;

export const SLockButton = styled(Button)`
  && {
    margin: 0;
    padding: 0;
    min-width: 85px;
    height: 36px;
    border-radius: 4px;
    padding: 0px 6px;
    margin-right: 8px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.textDefault};
    background-color: ${({ theme }) => theme.colors.cardBgSelected};

    &:hover {
      background-color: ${({ theme }) => theme.colors.cardBgSelected};
    }
  }
`;

export const SLockOpenIcon = styled(LockOpenIcon)`
  && {
    font-size: 15px;
    line-height: 15px;
    margin: 0;
    margin-right: 2px;
    padding: 0;
  }
`;

export const SLockClosedIcon = styled(LockOutlinedIcon)`
  && {
    font-size: 15px;
    line-height: 15px;
    margin: 0;
    margin-right: 2px;
    padding: 0;
  }
`;

export const SRedLockIcon = styled(SLockClosedIcon)`
  && {
    font-size: 15px;
    color: ${({ theme }) => theme.colors.alertRed};
  }
`;
