import Big from "big.js";

/**
 * Filters and rounds valid numbers to be used with charts
 * @param number - ideally a number or a string with only numbers within
 * @returns a rounded number or null
 * @example
 * ```ts
 * chartNumber(10)      // returns 10
 * chartNumber(10.2345) // returns 10.23
 * chartNumber("220")   // returns 220
 * chartNumber("0.666") // returns 0.67
 * chartNumber("abcde") // returns null
 * chartNumber("NaN")   // returns null
 * chartNumber("")      // returns null
 * ```
 */
export const chartNumber = (
  number: string | number | undefined | null
): number | null => {
  try {
    // @ts-expect-error: If not a convertible number an error is expeted from Big
    return Big(number).round(2).toNumber();
  } catch {
    return null;
  }
};
