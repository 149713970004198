import { Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    height: 100vh;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SProgressMessage = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.textDefault};
    margin-bottom: 20px;
  }
`;

export const SCircularProgress = styled(CircularProgress)`
  && {
    line-height: 40px;
    color: ${({ theme }) => theme.colors.addButtonSecondary};
  }
`;
