import { MenuItem, Menu } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrency, useCurrentPortfolio } from "../../../../store";
import { SMenuItem, SToolbar } from "./styles";

export const CurrencyChooser: React.FC = () => {
  const { t } = useTranslation();
  const { currency, setCurrency, availableCurrencies } = useCurrency();
  const [menuAnchor, setMenuAnchor] = useState(
    null as null | (EventTarget & Element)
  );
  const isOpen = Boolean(menuAnchor);
  const { updateRemotePortfolio } = useCurrentPortfolio();

  const setNewCurrency = (newCurrency: string): void => {
    setCurrency(newCurrency);
  };

  const handleClick: React.MouseEventHandler = (event): void => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setMenuAnchor(null);
  };

  const handleChangeCurrency = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    newCurrency: string
  ): void => {
    setNewCurrency(String(newCurrency));
    handleCloseMenu();
    updateRemotePortfolio();
  };

  return (
    <>
      <SToolbar>
        <SMenuItem id="nav-button" onClick={handleClick} color="inherit">
          {t("currency.currency")} (
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment 
          @ts-ignore */}
          {t(`currency.${currency}.code`)})
          <ArrowDropDown />
        </SMenuItem>
        <Menu
          id="nav-menu"
          anchorEl={menuAnchor}
          open={isOpen}
          onClose={handleCloseMenu}
          aria-controls={isOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? "true" : undefined}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          MenuListProps={{
            "aria-labelledby": "nav-button",
          }}
        >
          {availableCurrencies.map((currencyCode) => (
            <MenuItem
              key={currencyCode}
              value={currencyCode}
              onClick={(e) => handleChangeCurrency(e, currencyCode)}
              sx={{ width: 110 }}
            >
              {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment //
          @ts-ignore */}
              {t(`currency.${currencyCode}.displayName`)}
            </MenuItem>
          ))}
        </Menu>
      </SToolbar>
    </>
  );
};
