import { FormControl, Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatPercentage, miliSecondsDelay } from "../../../../../utils";
import {
  SAddButton,
  SAddFunction,
  SCloseButton,
  SCloseIcon,
  SDialogContent,
  SDialogTitle,
  SGridColumn1,
  SGridColumn2,
  SGridColumn3,
  SGridContainer,
  SGridRow1,
  SGridRow2,
  SLabel,
  SMenuItem,
  SNameInput,
  SPaddedLines,
  SPercentageFunction,
  SProgressGrid,
  SSelect,
  STitle,
  SUnpaddedLines,
  SCircularProgress,
  SErrorGroup,
  SErrorIcon,
  SErrorMessage,
  SInputRange,
} from "./styles";
import {
  BenchmarkAssetType,
  useCustomBenchmarkAsset,
  useGlobalConfigs,
} from "../../../../../store";
import { useAddRemoteCustomBenchmark } from "../../../../../api";

interface PropsTypes {
  benchmarks: BenchmarkAssetType[];
  open: boolean;
  onClose: () => void;
}

export const CreateAssetDialog: React.FC<PropsTypes> = ({
  open,
  onClose,
  benchmarks,
}) => {
  const { t } = useTranslation();
  const {
    defaultCustomBenchmarkAssetPercentage,
    maxCustomBenchmarkAssetPercentage,
  } = useGlobalConfigs();
  const [indexer, setIndexer] = useState(JSON.stringify(benchmarks[0]));
  const [percentageFunction, setPercentageFunction] = useState(true);
  const [addFunction, setAddFunction] = useState(false);
  const [rangePercentage, setRangePercentage] = useState<string | undefined>(
    String(defaultCustomBenchmarkAssetPercentage)
  );
  const [nameInput, setNameInput] = useState("");
  const { loading: isAddBenchmarkCustomLoading, send: createCustomBenchmark } =
    useAddRemoteCustomBenchmark();
  const { addToCustomBenchmarkAssets, customBenchmarks } =
    useCustomBenchmarkAsset();
  const [isError, setIsError] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const rangePercentageRef = useRef<HTMLInputElement>(null);
  const [isAutoGeneratingName, setIsAutoGeneratingName] = useState(true);

  const focusRange = async (): Promise<void> => {
    // waits for next render to perform selection in order to
    // avoid error where MUI tries to steal focus to the "indexer"
    // select box while closing it
    await miliSecondsDelay(0);
    rangePercentageRef?.current?.select();
  };

  const handlePercentageFunc = (): void => {
    setPercentageFunction(true);
    setAddFunction(false);

    focusRange();
  };

  const handleAddFunc = (): void => {
    setAddFunction(true);
    setPercentageFunction(false);

    focusRange();
  };

  const emptyNameChecker = useCallback(
    () => nameInput.match(/^[ ]+$/) !== null,
    [nameInput]
  );

  const errorChecker = (): boolean => {
    const allCustomBenchmarksAssetsNames = customBenchmarks.map((asset) =>
      String(asset.label).toLowerCase()
    );

    const isSameNameErrorDetected = allCustomBenchmarksAssetsNames.includes(
      nameInput.toLowerCase()
    );

    const isEmptyNameErrorDetected = emptyNameChecker();

    setIsError(isSameNameErrorDetected || isEmptyNameErrorDetected);
    return isSameNameErrorDetected || isEmptyNameErrorDetected;
  };

  const handleChangeName:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (event): void => {
    setIsError(false);
    setIsAutoGeneratingName(false);
    setNameInput(event.target.value);
  };

  const handleRangePercentage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const inputValue = event.target.value;
    const integer = inputValue.includes(".")
      ? String(inputValue).split(".")[0]
      : String(inputValue);
    const mantissa =
      inputValue.includes(".") && String(inputValue).split(".")[1].length > 0
        ? String(inputValue).split(".")[1].substring(0, 2)
        : "";

    if (Number(inputValue) < maxCustomBenchmarkAssetPercentage) {
      setRangePercentage(
        `${String(Number(integer))}${mantissa.length > 0 ? `.${mantissa}` : ""}`
      );
    }
  };

  const generateNameInput = useCallback((): void => {
    const selectedIndexer = JSON.parse(indexer);
    const formattedPercentage = formatPercentage(
      String(Number(rangePercentage)),
      String(rangePercentage).includes(".")
        ? String(rangePercentage).split(".")[1].length
        : 0
    );

    setNameInput(
      addFunction
        ? `${selectedIndexer?.label} + ${formattedPercentage}`
        : `${formattedPercentage} ${t("customBenchmark.ofPreposition")} ${
            selectedIndexer?.label
          }`
    );
  }, [addFunction, indexer, rangePercentage, t]);

  const handleBlurNameInput = (): void => {
    if (nameInput === "") {
      generateNameInput();
      setIsAutoGeneratingName(true);
    }
  };

  useEffect(() => {
    if (isAutoGeneratingName) generateNameInput();
  }, [
    rangePercentage,
    percentageFunction,
    addFunction,
    indexer,
    generateNameInput,
    isAutoGeneratingName,
  ]);

  useEffect(
    () => setIsError(false),
    [rangePercentage, addFunction, percentageFunction, indexer]
  );

  useEffect(() => {
    if (open) {
      setIsAutoGeneratingName(true);
      setIsClosing(false);
      setPercentageFunction(true);
      setAddFunction(false);
      setRangePercentage(String(defaultCustomBenchmarkAssetPercentage));
      setIndexer(JSON.stringify(benchmarks[0]));
      setIsError(false);
      generateNameInput();
      focusRange();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddCustomBenchmark = async (): Promise<void> => {
    if (errorChecker()) return;

    const customBenchmarkFormatted = {
      name: nameInput,
      benchmarkIndexer: {
        id: Number(JSON.parse(indexer)?.identifier),
        name: String(JSON.parse(indexer)?.label),
      },
      rate: String(rangePercentage),
      format: percentageFunction ? "PERCENTAGE" : "POSITIVE_SPREAD",
    };

    const newAssetID = await createCustomBenchmark(customBenchmarkFormatted);

    if (newAssetID !== "failed") {
      const newAssetAsSearchableFormat = {
        assetType: "BENCHMARK_PERSONALIZADO",
        identifier: newAssetID,
        cnpj: null,
        isin: null,
        label: customBenchmarkFormatted.name,
        managementCompany: null,
        situation: null,
        stockExchange: null,
        type: null,
      };

      addToCustomBenchmarkAssets(newAssetAsSearchableFormat);

      // Prevents button text of appearing while dialog closing animation is in action
      setIsClosing(true);
      onClose();
      await miliSecondsDelay(2_000);
      setIsClosing(false);
    }
  };

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    if (event.key === "Enter" && !isAddBenchmarkCustomLoading && !isClosing)
      handleAddCustomBenchmark();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: 5 },
        elevation: 3,
      }}
      hideBackdrop
      onKeyPress={handleEnterKeyPress}
    >
      <SGridContainer>
        <SPaddedLines>
          <SDialogTitle>
            <STitle>{t("customBenchmark.createAsset")}</STitle>
            <SCloseButton onClick={onClose}>
              <SCloseIcon />
            </SCloseButton>
          </SDialogTitle>
          <SDialogContent>
            <SGridRow1>
              <SGridColumn1>
                <SLabel>{t("customBenchmark.indexer")}</SLabel>
                <FormControl>
                  <SSelect
                    value={indexer}
                    key="indexer-select-key"
                    id="indexer-select"
                    variant="standard"
                    disableUnderline
                    MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                  >
                    {benchmarks.map((benchmark) => (
                      <SMenuItem
                        value={JSON.stringify(benchmark)}
                        key={`${benchmark.identifier}--menu-item`}
                        onClick={() => {
                          setIndexer(JSON.stringify(benchmark));
                          focusRange();
                        }}
                      >
                        {benchmark.label}
                      </SMenuItem>
                    ))}
                  </SSelect>
                </FormControl>
              </SGridColumn1>
              <SGridColumn2>
                <SLabel>{t("customBenchmark.function")}</SLabel>
                <div>
                  <SPercentageFunction
                    $selected={percentageFunction}
                    onClick={handlePercentageFunc}
                  >
                    %
                  </SPercentageFunction>
                  <SAddFunction $selected={addFunction} onClick={handleAddFunc}>
                    +
                  </SAddFunction>
                </div>
              </SGridColumn2>
              <SGridColumn3>
                <SLabel>{t("customBenchmark.range")}</SLabel>
                <FormControl
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <SInputRange
                    inputRef={rangePercentageRef}
                    value={rangePercentage}
                    onChange={handleRangePercentage}
                    disableUnderline
                    onFocus={focusRange}
                    type="number"
                  />
                  %
                </FormControl>
              </SGridColumn3>
            </SGridRow1>
            <SGridRow2>
              <FormControl>
                <SLabel>{t("customBenchmark.name")}</SLabel>
                <SNameInput
                  key="name-input"
                  disableUnderline
                  value={nameInput}
                  onChange={handleChangeName}
                  onBlur={handleBlurNameInput}
                />
                {isError && !isClosing ? (
                  <SErrorGroup>
                    <SErrorIcon />
                    <SErrorMessage>
                      {emptyNameChecker()
                        ? t("customBenchmark.nameEmpty")
                        : t("customBenchmark.nameTaken")}
                    </SErrorMessage>
                  </SErrorGroup>
                ) : null}
              </FormControl>
            </SGridRow2>
          </SDialogContent>
        </SPaddedLines>
        <SUnpaddedLines>
          {isAddBenchmarkCustomLoading || isClosing ? (
            <SProgressGrid>
              <SCircularProgress />
            </SProgressGrid>
          ) : (
            <SAddButton onClick={handleAddCustomBenchmark} disabled={isError}>
              {t("customBenchmark.add")}
            </SAddButton>
          )}
        </SUnpaddedLines>
      </SGridContainer>
    </Dialog>
  );
};
