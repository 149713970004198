import { Slide } from "@mui/material";
import React, { useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { CurrencyChooser } from "./CurrencyChooser/CurrencyChooser";
import { LanguageChooser } from "./LanguageChooser/LanguageChooser";
import { SButton, SGridContainer, SGridMenuArea } from "./styles";

export const LocaleConfigsSelector: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (): void => {
    setIsOpen((currentIsOpen) => !currentIsOpen);
  };

  return (
    <SGridContainer $isMenuOpen={isOpen}>
      <SButton onClick={toggleDrawer}>
        <SettingsIcon />
      </SButton>
      <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
        <SGridMenuArea>
          {/* <Divider orientation="vertical" color="white" sx={{ height: 30 }} /> */}

          <CurrencyChooser />

          {/* <Divider orientation="vertical" color="white" sx={{ height: 30 }} /> */}

          <LanguageChooser />
        </SGridMenuArea>
      </Slide>
    </SGridContainer>
  );
};
