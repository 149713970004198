import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useMount } from "react-use";
import { useTheme } from "styled-components";
import { SGridOuterContainer, SGridContainer, SIframe } from "./styles";

export const AssetComparator: React.FC = () => {
  const assetsIframeRef = useRef<HTMLIFrameElement>(null);
  const theme = useTheme();
  const location = useLocation();

  const token = localStorage.getItem("token");

  const getAssetsUrl = (currentLocation: { hash: string }): string => {
    const hasSharedUrl = currentLocation.hash !== "";
    const sharedUrl = `${
      process.env.REACT_APP_ASSETS_COMP_URL
    }/${location.hash.substring(1)}`;

    return hasSharedUrl
      ? sharedUrl
      : String(process.env.REACT_APP_ASSETS_COMP_URL);
  };

  useMount(() => {
    if (theme.auth.useAuth && assetsIframeRef.current && token) {
      const handleLoad = (): void => {
        assetsIframeRef.current?.contentWindow?.postMessage(
          JSON.stringify({ token }),
          getAssetsUrl(location)
        );
      };

      const iframeElement = assetsIframeRef.current;

      iframeElement.addEventListener("load", handleLoad);

      return () => {
        iframeElement.removeEventListener("load", handleLoad);
      };
    }

    return () => null;
  });

  return (
    <SGridOuterContainer>
      <SGridContainer>
        <SIframe
          ref={assetsIframeRef}
          title="Comparador de Ativos"
          src={getAssetsUrl(location)}
          width="100%"
          height="100%"
          allow="clipboard-write"
          sandbox="allow-top-navigation allow-downloads allow-forms allow-scripts allow-popups allow-same-origin"
        />
      </SGridContainer>
    </SGridOuterContainer>
  );
};
