import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { indexOf, mergeAll } from "ramda";
import { useRafLoop } from "react-use";
import { useFetchVolatilityTableData } from "../../../api";

import {
  useAllPortfolios,
  useBenchmarks,
  useCustomDateRange,
  usePeriod,
  useShowAssets,
  useWindowValue,
} from "../../../store";
import { VolatilityTableRow } from "./VolatilityTableRow/VolatilityTableRow";
import {
  STableContainer,
  // SGridRow1,
  SGridRow3,
  STable,
  STableBody,
  // STableTitle,
  SHeaderTableRow,
  STableRow,
  SCell,
  SHeaderCell,
  SHeaderCellWithBorder,
  SFirstColumnHeaderCell,
  SCircularProgress,
  STableHead,
  SCellContent,
} from "./styles";
import { useAssetColor, useBenchmarkColor } from "../../../utils";

export const VolatilityTable: React.FC = () => {
  const { t } = useTranslation();
  const { allPortfolios } = useAllPortfolios();
  const { selectedPeriod } = usePeriod();
  const { isAssetsShowing } = useShowAssets();
  const {
    loading: isLoading,
    data: volatilityTableData,
    fetch: fetchVolatilityTableData,
  } = useFetchVolatilityTableData();
  const { selectedBenchmarks } = useBenchmarks();
  const { generateAssetColor } = useAssetColor();
  const { generateBenchmarkColor } = useBenchmarkColor();
  const [columnPaddings, setColumnPaddings] = useState(Array(8).fill("8px"));

  const yearVolatilityRef = useRef<HTMLDivElement | null>(null);
  const volatilityRef = useRef<HTMLDivElement | null>(null);
  const sharpeRatioRef = useRef<HTMLDivElement | null>(null);
  const positiveRef = useRef<HTMLDivElement | null>(null);
  const negativeRef = useRef<HTMLDivElement | null>(null);
  const averageRef = useRef<HTMLDivElement | null>(null);
  const maximumRef = useRef<HTMLDivElement | null>(null);
  const minimumRef = useRef<HTMLDivElement | null>(null);

  useRafLoop(() => {
    setColumnPaddings(
      [
        yearVolatilityRef.current?.offsetLeft,
        volatilityRef.current?.offsetLeft,
        sharpeRatioRef.current?.offsetLeft,
        positiveRef.current?.offsetLeft,
        negativeRef.current?.offsetLeft,
        averageRef.current?.offsetLeft,
        maximumRef.current?.offsetLeft,
        minimumRef.current?.offsetLeft,
      ].map((width) => `${width}px`)
    );
  });

  const portfoliosAssets = useMemo(
    () =>
      mergeAll(
        Object.keys(allPortfolios).map(
          (key) => allPortfolios[Number(key)].assets
        )
      ),
    [allPortfolios]
  );

  const { selectedCustomDateRange } = useCustomDateRange();
  const { windowValue } = useWindowValue();

  useEffect(() => {
    fetchVolatilityTableData(allPortfolios, selectedBenchmarks, selectedPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allPortfolios,
    selectedPeriod,
    selectedBenchmarks,
    selectedCustomDateRange,
    windowValue,
  ]);

  return (
    <STableContainer>
      {/* Disabled Feature: Table Title */}
      {/* <SGridRow1>
        <STableTitle>{t("volatilityTable.title")}</STableTitle>
      </SGridRow1> */}

      <SGridRow3>
        <STable>
          <colgroup>
            <col style={{ width: "20%" }} />
          </colgroup>
          <STableHead>
            <STableRow>
              <SHeaderCell colSpan={4} />
              <SHeaderCellWithBorder colSpan={2}>
                {t("volatilityTable.returnDays.returnDays")}
              </SHeaderCellWithBorder>
              <SHeaderCellWithBorder colSpan={5}>
                {t("volatilityTable.dailyReturn.dailyReturn")}
              </SHeaderCellWithBorder>
            </STableRow>
          </STableHead>
          <STableBody>
            <SHeaderTableRow key="volatility-header">
              <SFirstColumnHeaderCell>{t("portfolio")}</SFirstColumnHeaderCell>
              <SHeaderCell>
                <SCellContent ref={yearVolatilityRef}>
                  {t("volatilityTable.yearVolatility")}
                </SCellContent>
              </SHeaderCell>
              <SHeaderCell>
                <SCellContent ref={volatilityRef}>
                  {t("volatilityTable.volatility")}
                </SCellContent>
              </SHeaderCell>
              <SHeaderCell>
                <SCellContent ref={sharpeRatioRef}>
                  {t("volatilityTable.sharpeRatio")}
                </SCellContent>
              </SHeaderCell>
              <SHeaderCell>
                <SCellContent ref={positiveRef}>
                  {t("volatilityTable.returnDays.positive")}
                </SCellContent>
              </SHeaderCell>
              <SHeaderCell>
                <SCellContent ref={negativeRef}>
                  {t("volatilityTable.returnDays.negative")}
                </SCellContent>
              </SHeaderCell>
              <SHeaderCell>
                <SCellContent ref={averageRef}>
                  {t("volatilityTable.dailyReturn.average")}
                </SCellContent>
              </SHeaderCell>
              <SHeaderCell>
                <SCellContent ref={maximumRef}>
                  {t("volatilityTable.dailyReturn.maximum")}
                </SCellContent>
              </SHeaderCell>
              <SHeaderCell>
                <SCellContent>
                  {t("volatilityTable.dailyReturn.maximumDate")}
                </SCellContent>
              </SHeaderCell>
              <SHeaderCell>
                <SCellContent ref={minimumRef}>
                  {t("volatilityTable.dailyReturn.minimum")}
                </SCellContent>
              </SHeaderCell>
              <SHeaderCell>
                <SCellContent>
                  {t("volatilityTable.dailyReturn.minimumDate")}
                </SCellContent>
              </SHeaderCell>
            </SHeaderTableRow>
            {isLoading ? (
              <STableRow key="volatility-progress-key">
                <SCell colSpan={11}>
                  <SCircularProgress />
                </SCell>
              </STableRow>
            ) : (
              <React.Fragment key="volatility-assets-key">
                {Object.keys(allPortfolios).map(
                  (portfolioKey) =>
                    volatilityTableData?.[portfolioKey] && (
                      <React.Fragment key={`volatility-${portfolioKey}`}>
                        <VolatilityTableRow
                          key={portfolioKey}
                          paddingRights={columnPaddings}
                          large
                          asset={{
                            ...volatilityTableData[portfolioKey],
                            key: portfolioKey,
                            color: generateAssetColor(Number(portfolioKey)),
                          }}
                        />
                        {isAssetsShowing &&
                          Object.keys(
                            volatilityTableData[portfolioKey].assets
                          ).map((assetKey) => {
                            return (
                              <VolatilityTableRow
                                key={`volatility-${assetKey}`}
                                paddingRights={columnPaddings}
                                asset={{
                                  ...volatilityTableData[portfolioKey].assets[
                                    assetKey
                                  ],
                                  key: assetKey,
                                  color: generateAssetColor(
                                    indexOf(
                                      assetKey,
                                      Object.keys(portfoliosAssets)
                                    ) + Object.keys(allPortfolios).length
                                  ),
                                }}
                              />
                            );
                          })}
                      </React.Fragment>
                    )
                )}
                {Object.keys(selectedBenchmarks).map(
                  (benchmarkKey) =>
                    volatilityTableData?.[benchmarkKey] && (
                      <VolatilityTableRow
                        key={`volatility-${benchmarkKey}`}
                        paddingRights={columnPaddings}
                        asset={{
                          ...volatilityTableData[benchmarkKey],
                          key: benchmarkKey,
                          color: generateBenchmarkColor(
                            indexOf(
                              benchmarkKey,
                              Object.keys(selectedBenchmarks)
                            )
                          ),
                        }}
                      />
                    )
                )}
              </React.Fragment>
            )}
          </STableBody>
        </STable>
      </SGridRow3>
    </STableContainer>
  );
};
