import { MenuItem, Menu } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { availableLanguages } from "../../../../i18n";
import { useLanguage } from "../../../../store";
import { SMenuItem, SToolbar } from "./styles";

export const LanguageChooser: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [reduxLang, setLanguage] = useLanguage();
  const [menuAnchor, setMenuAnchor] = useState(
    null as null | (EventTarget & Element)
  );
  const isOpen = Boolean(menuAnchor);

  const setNewLanguage = (language: string): void => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  useEffect(() => {
    setLanguage(i18n.language || reduxLang);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick: React.MouseEventHandler = (event): void => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setMenuAnchor(null);
  };

  const handleChangeLanguage = (
    _e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    language: string
  ): void => {
    setNewLanguage(String(language));
    handleCloseMenu();
  };

  return (
    <>
      <SToolbar>
        <SMenuItem id="nav-button" onClick={handleClick} color="inherit">
          {t("language.language")} (
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment 
          @ts-ignore */}
          {t([
            `language.${reduxLang}.displayNameShort`,
            // NOTE: There's a bug on i18next translation function that prevents
            // this composed translation string to be used when component mounts
            // this little hack translates just this bit manually for the first
            // render, then app works as usual.

            // eslint-disable-next-line no-nested-ternary
            i18n.language === "pt-BR"
              ? "POR"
              : i18n.language === "en"
              ? "ENG"
              : "ESP",
          ])}
          )
          <ArrowDropDown />
        </SMenuItem>
        <Menu
          id="nav-menu"
          anchorEl={menuAnchor}
          open={isOpen}
          onClose={handleCloseMenu}
          aria-controls={isOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? "true" : undefined}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          MenuListProps={{
            "aria-labelledby": "nav-button",
          }}
        >
          {availableLanguages.map((language) => (
            <MenuItem
              key={language}
              value={language}
              onClick={(e) => handleChangeLanguage(e, language)}
            >
              {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment //
          @ts-ignore */}
              {t(`language.${language}.displayNameFull`)}
            </MenuItem>
          ))}
        </Menu>
      </SToolbar>
    </>
  );
};
