import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    padding-left: 32px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const SGridColumnLeft = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    margin-top: 38px;
  }
`;

export const SGridColumnRight = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.bgGray};
    min-width: 405px;
    max-width: 405px;
    height: ${({ theme }) =>
      theme.navbar.show ? "calc(100vh - 58px)" : "calc(100vh)"};
  }
`;

export const STitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    line-height: 33px;
    margin-top: 31px;
    padding: 0;
  }
`;

export const SMessage = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    margin-top: 4px;
    padding-right: 32px;
  }
`;
