import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFetchBaseDate, useFetchCompetence } from "../../../../api";
import { useWallet, useAllPortfolios } from "../../../../store";
import {
  SButtonGroup,
  SGridContainer,
  SGridLoading,
  SLinearProgress,
  SMenuItem,
  SSelect,
  STitle,
} from "./styles";

export const CompetenceSelector: React.FC = () => {
  const { t, i18n } = useTranslation();
  const {
    loading: isLoadingCompetence,
    fetch: getCompetences,
    data: competencesFromServer,
  } = useFetchCompetence();
  const {
    selectedCompetence,
    setSelectedCompetence,
    availableCompetences,
    setAvailableCompetences,
  } = useWallet();
  const { allPortfolios } = useAllPortfolios();
  const {
    loading: isLoadingBaseDate,
    fetch: getBaseDate,
    data: baseDate,
  } = useFetchBaseDate();

  const handleSelect = (selected: string): void => {
    setSelectedCompetence(selected);
  };

  useEffect(() => {
    getCompetences();
    getBaseDate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPortfolios]);

  useEffect(() => {
    const isDateAfter15 = new Date(String(baseDate)).getDate() + 1 >= 15;

    setAvailableCompetences(competencesFromServer);
    setSelectedCompetence(
      competencesFromServer.includes(selectedCompetence)
        ? selectedCompetence
        : competencesFromServer[isDateAfter15 ? 0 : 1] || ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competencesFromServer, baseDate]);

  const translateMonth = (date: string): string => {
    const month = String(
      new Intl.DateTimeFormat(i18n.language, { month: "short" })
        .format(new Date(date))
        .replaceAll(".", "")
        .replace(/\w/, (firstLetter) => firstLetter.toUpperCase())
    );

    const year = new Intl.DateTimeFormat(i18n.language, {
      year: "numeric",
    }).format(new Date(date));

    return `${month}/${year}`;
  };

  return (
    <SGridContainer>
      <STitle>{t("wallet.competence")}</STitle>
      <SButtonGroup>
        {isLoadingCompetence || isLoadingBaseDate ? (
          <SGridLoading key="CompetenceLoading">
            <SLinearProgress variant="indeterminate" color="inherit" />
          </SGridLoading>
        ) : (
          <SSelect
            value={selectedCompetence}
            id="competence-select"
            variant="standard"
            disableUnderline
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {availableCompetences?.map((availableCompetence) => (
              <SMenuItem
                onClick={() => handleSelect(availableCompetence)}
                value={availableCompetence}
                key={String(availableCompetence)}
              >
                {translateMonth(availableCompetence)}
              </SMenuItem>
            ))}
            <SMenuItem value="" key="" />
          </SSelect>
        )}
      </SButtonGroup>
    </SGridContainer>
  );
};
