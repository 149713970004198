import styled from "styled-components";
import {
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
  DialogActions,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import CheckIcon from "@mui/icons-material/Check";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ArrowDropDown } from "@mui/icons-material";

export const SGridContainer = styled(Grid)`
  && {
    width: 418px;
    height: 196px;
    padding: 16px;
  }
`;

export const SDialogTitle = styled(DialogTitle)`
  && {
    padding: 0;
    padding-bottom: 20px;
  }
`;

export const STitle = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: ${({ theme }) => theme.colors.textDefault};
    padding: 0;
  }
`;

export const SCalendarTodayIcon = styled(CalendarTodayIcon)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.colors.textDarkGray};
    font-size: 14px;
  }
`;

export const SCloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

export const SCloseIcon = styled(CloseIcon)`
  && {
    color: ${({ theme }) => theme.colors.textBlack};
  }
`;

export const SDialogContent = styled(DialogContent)`
  && {
    display: flex;
    gap: 32px;
    padding: 0;
    padding-bottom: 15px;
  }
`;

export const SDatePickerContainer = styled(Grid)`
  && {
  }
`;

export const SDatePickerTitle = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.textBlack};
    padding-bottom: 2px;
  }
`;

export const SDialogActions = styled(DialogActions)`
  && {
    padding: 0;
  }
`;

export const SClearButton = styled(Button)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textDarkGray};
    text-transform: none;
    white-space: nowrap;
    height: 36px;
    min-width: fit-content;
    width: 75px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.selectedLightBlue};
    }
  }
`;

export const SClearIcon = styled(DeleteSweepIcon)`
  color: ${({ theme }) => theme.colors.textDark};
  margin-left: 3px;
  padding-left: 6px;
`;

export const SApplyButton = styled(Button)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme, disabled }) =>
      disabled ? "white !important" : theme.colors.addButtonText};
    background-color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.addButtonSecondary
        : theme.colors.addButtonPrimary};
    text-transform: none;
    white-space: nowrap;
    height: 36px;
    min-width: fit-content;
    width: 69px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.addButtonSecondary};
    }
  }
`;

export const SApplyIcon = styled(CheckIcon)`
  && {
    color: ${({ theme }) => theme.colors.textWhite};
    margin-left: 3px;
    padding-left: 6px;
  }
`;

export const SErrorGroup = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
  }
`;

export const SErrorMessage = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.alertRed};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
    text-align: start;
  }
`;

export const SErrorIcon = styled(HighlightOffIcon)`
  && {
    color: ${({ theme }) => theme.colors.alertRed};
  }
`;

export const SArrowDown = styled(ArrowDropDown)`
  && {
  }
`;
