import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    width: 600px;
    height: 350px;
    padding: 24px;
    border-radius: 8px;
  }
`;

export const SDialogTitle = styled(DialogTitle)`
  && {
    padding: 0;
    padding-bottom: 24px;
  }
`;

export const STitle = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.textDefault};
    font-weight: 700;
    padding: 0;
    padding-top: 5px;
    padding-left: 16px;
  }
`;

export const SCloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

export const SCloseIcon = styled(CloseIcon)`
  && {
    color: ${({ theme }) => theme.colors.textBlack};
  }
`;

export const SDialogContent = styled(DialogContent)`
  && {
    display: flex;
    height: 80%;
    padding: 0;
    padding-bottom: 24px;
  }
`;

export const SGridColumn1 = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px 16px 24px;
    border-radius: 8px;

    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.textDefault};
  }
`;

export const SGridColumn2 = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px 16px 24px;
    border-radius: 8px;
  }
`;
