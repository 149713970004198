import React from "react";
import { formatPercentage, formatNumber, formatDate } from "../../../../utils";
import {
  STableRow,
  SFirstColumnCell,
  SCell,
  SBar,
  SFirstColumnTextContainer,
  SEllipsisText,
} from "./styles";

interface RentabilityTableRowPropsType {
  asset: {
    color: string;
    label: string | null;
    key: string;
    yearVolatility: string | null;
    volatility: string | null;
    sharpe: string | null;
    returnDays: {
      positive: number | null;
      negative: number | null;
    };
    dailyReturn: {
      average: string | null;
      maximum: string | null;
      maximumDate: string | null;
      minimum: string | null;
      minimumDate: string | null;
    };
  };
  large?: boolean;
  paddingRights: string[];
}

export const VolatilityTableRow: React.FC<RentabilityTableRowPropsType> = ({
  asset,
  large = false,
  paddingRights = Array(8).fill("8px"),
}) => {
  VolatilityTableRow.defaultProps = {
    large: false,
  };

  return (
    <STableRow key={`inner-volatility-${asset.key}`}>
      <SFirstColumnCell>
        <SFirstColumnTextContainer>
          <SBar $barColor={asset.color} $large={large} />
          <SEllipsisText>{asset.label}</SEllipsisText>
        </SFirstColumnTextContainer>
      </SFirstColumnCell>
      <SCell
        sx={{
          paddingRight:
            asset.yearVolatility === null
              ? "8px"
              : `${paddingRights[0]} !important`,
          textAlign: asset.yearVolatility === null ? "center" : "right",
        }}
      >
        {formatPercentage(asset.yearVolatility)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.volatility === null
              ? "8px"
              : `${paddingRights[1]} !important`,
          textAlign: asset.volatility === null ? "center" : "right",
        }}
      >
        {formatPercentage(asset.volatility)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.sharpe === null ? "8px" : `${paddingRights[2]} !important`,
          textAlign: asset.sharpe === null ? "center" : "right",
        }}
      >
        {formatNumber(asset.sharpe)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.returnDays.positive === null
              ? "8px"
              : `${paddingRights[3]} !important`,
          textAlign: asset.returnDays.positive === null ? "center" : "right",
        }}
      >
        {formatNumber(asset.returnDays.positive, 0)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.returnDays.negative === null
              ? "8px"
              : `${paddingRights[4]} !important`,
          textAlign: asset.returnDays.negative === null ? "center" : "right",
        }}
      >
        {formatNumber(asset.returnDays.negative, 0)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.dailyReturn.average === null
              ? "8px"
              : `${paddingRights[5]} !important`,
          textAlign: asset.dailyReturn.average === null ? "center" : "right",
        }}
      >
        {formatPercentage(asset.dailyReturn.average)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.dailyReturn.maximum === null
              ? "8px"
              : `${paddingRights[6]} !important`,
          textAlign: asset.dailyReturn.maximum === null ? "center" : "right",
        }}
      >
        {formatPercentage(asset.dailyReturn.maximum)}
      </SCell>
      <SCell align="center">{formatDate(asset.dailyReturn.maximumDate)}</SCell>
      <SCell
        sx={{
          paddingRight:
            asset.dailyReturn.minimum === null
              ? "8px"
              : `${paddingRights[7]} !important`,
          textAlign: asset.dailyReturn.minimum === null ? "center" : "right",
        }}
      >
        {formatPercentage(asset.dailyReturn.minimum)}
      </SCell>
      <SCell align="center">{formatDate(asset.dailyReturn.minimumDate)}</SCell>
    </STableRow>
  );
};
