import styled from "styled-components";
import { Grid } from "@mui/material";

export const SGridChartContainer = styled(Grid)`
  && {
    .chart {
      width: 100% !important;
      height: 100% !important;
    }
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;
