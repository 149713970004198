import {
  Button,
  Grid,
  LinearProgress,
  Typography,
  TextField,
  InputAdornment,
  Autocomplete,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    margin-right: 32px;
    min-width: 280px;
  }
`;

export const SButtonGroup = styled(Grid)`
  && {
    width: 100%;
    display: flex;
    grid-gap: 8px;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const SLinearProgress = styled(LinearProgress)`
  && {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.addButtonSecondary};
  }
`;

export const SLabel = styled(Typography)`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin: 4px 0px;
    color: ${({ theme }) => theme.colors.periodButtonDark};
  }
`;

interface SButtonPropsType {
  $isSelected: boolean;
}

export const SButton = styled(Button)<SButtonPropsType>`
  && {
    text-transform: capitalize;
    white-space: nowrap;
    word-break: keep-all;
    min-width: 66px;
    max-width: fit-content;
    height: 40px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: ${({ $isSelected }) => ($isSelected ? 600 : "normal")};
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.periodButtonSelectedFg
        : theme.colors.periodButtonDark};
    background: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.periodButtonSelected
        : theme.colors.periodButtonLight};
    border: 1px solid ${({ theme }) => theme.colors.periodButtonDark};
    box-sizing: border-box;
    border-radius: 2px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.periodButtonHover};
      color: ${({ theme }) => theme.colors.periodButtonHoverFg};
      border: 1px solid ${({ theme }) => theme.colors.periodButtonHover};
    }
  }
`;

export const SOthersButton = styled(Button)`
  && {
    text-transform: capitalize;
    white-space: nowrap;
    word-break: keep-all;
    min-width: 66px;
    max-width: fit-content;
    height: 40px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.periodButtonDark};
    background: ${({ theme }) => theme.colors.periodButtonLight};
    border: 1px solid ${({ theme }) => theme.colors.periodButtonDark};
    box-sizing: border-box;
    border-radius: 2px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.periodButtonHover};
      color: ${({ theme }) => theme.colors.periodButtonHoverFg};
      border: 1px solid ${({ theme }) => theme.colors.periodButtonHover};
    }
  }
`;

export const SAutocomplete = styled(Autocomplete)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    background: ${({ theme }) => theme.colors.bgGray};
  }
`;

export const STextField = styled(TextField)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    min-width: 210px;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    background: ${({ theme }) => theme.colors.bgGray};

    input {
      font-family: ${({ theme }) => theme.fonts.primary};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.textDarkGray};
    }
  }
`;

export const SInputAdornment = styled(InputAdornment)`
  && {
  }
`;

export const SSearchIcon = styled(SearchIcon)`
  && {
  }
`;

export const SCloseButton = styled(IconButton)`
  && {
  }
`;

export const SCloseIcon = styled(CancelIcon)`
  && {
    margin-left: 4px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.textWhite};
  }
`;
