import { TableCell, TableRow, Grid } from "@mui/material";
import styled from "styled-components";

export const STableRow = styled(TableRow)`
  && {
    align-items: center;
    min-height: 32px;

    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.colors.bgGray};
    }
  }
`;

export const SCell = styled(TableCell)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.periodButtonDark};
    border-bottom: 0px;
    padding: 8px;
    height: 100%;
  }
`;

export const SFirstColumnCell = styled(TableCell)`
  && {
    display: flex;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.periodButtonDark};
    border-bottom: 0px;
    padding: 8px;
    padding-left: 0px;
    height: 100%;
    text-align: left;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SFirstColumnTextContainer = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SEllipsisText = styled(Grid)`
  && {
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface SBarPropsTypes {
  $barColor: string;
  $large: boolean;
}

export const SBar = styled.div<SBarPropsTypes>`
  height: ${({ $large }) => ($large ? "8px" : "4px")};
  width: 16px;
  min-width: 16px;
  content: "";
  background-color: ${({ $barColor }) => $barColor || "black"};
  margin-right: 12px;
`;
