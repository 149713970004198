import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    min-width: 280px;
  }
`;

export const SButtonGroup = styled(Grid)`
  && {
    width: 100%;
    display: flex;
    grid-gap: 8px;
  }
`;

export const SLinearProgress = styled(LinearProgress)`
  && {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.addButtonSecondary};
  }
`;

export const SLabel = styled(Typography)`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin: 4px 0px;
    color: ${({ theme }) => theme.colors.periodButtonDark};
  }
`;

interface SButtonPropsType {
  $isSelected: boolean;
}

export const SButton = styled(Button)<SButtonPropsType>`
  && {
    text-transform: capitalize;
    white-space: nowrap;
    word-break: keep-all;
    min-width: 70px;
    max-width: 70px;
    height: 40px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: ${({ $isSelected }) => ($isSelected ? 600 : "normal")};
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.periodButtonSelectedFg
        : theme.colors.periodButtonDark};
    background: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.periodButtonSelected
        : theme.colors.periodButtonLight};
    border: 1px solid ${({ theme }) => theme.colors.periodButtonDark};
    box-sizing: border-box;
    border-radius: 2px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.periodButtonHover};
      color: ${({ theme }) => theme.colors.periodButtonHoverFg};
      border: 1px solid ${({ theme }) => theme.colors.periodButtonHover};
    }
  }
`;
