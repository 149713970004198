import React, { useEffect, useState, useRef, useCallback } from "react";
import Big from "big.js";
import { useDebounce } from "react-use";
import NumberFormat, {
  NumberFormatValues,
  SourceInfo,
} from "react-number-format";
import { useTranslation } from "react-i18next";
import { SInputContainer, SInputField, SLabel } from "./styles";
import { useGlobalConfigs, useWindowValue } from "../../../../store";

export const WindowSelector: React.FC = () => {
  const { t } = useTranslation();
  const { windowValue, setWindowValue } = useWindowValue();
  const [localWindow, setLocalWindow] = useState(String(windowValue));
  const { windowValue: defaultWindowValue } = useGlobalConfigs();
  const inputRef = useRef<HTMLInputElement>(null);

  const withValueCap = useCallback(
    (inputObj: NumberFormatValues): boolean => {
      const { floatValue } = inputObj;
      return Number(floatValue) <= defaultWindowValue.max;
    },
    [defaultWindowValue.max]
  );

  const handleChange = (
    maskedInputValue: NumberFormatValues,
    sourceInfo: SourceInfo
  ): void => {
    // onValueChange is called on EVERY change (unlike onChange) not only on events
    // If value is fully deleted, sourceinfo.source equals to "props" and not "event"
    if (
      sourceInfo.source === "event" ||
      (sourceInfo.source === "prop" && maskedInputValue.floatValue === 0)
    ) {
      setLocalWindow(String(maskedInputValue.floatValue || 0));
    }
  };

  const onFocus = (): void => {
    if (inputRef?.current?.firstChild) {
      const input = inputRef?.current?.firstChild as HTMLInputElement;
      input.select();
    }
  };

  const handleUpdateWindowValue = (): void => {
    if (Number(localWindow) < defaultWindowValue.min) {
      setLocalWindow(String(windowValue));

      // Set cursor to the end of the input on the next render
      const inputElement = inputRef.current?.firstChild as HTMLInputElement;
      setTimeout(
        () =>
          inputElement.setSelectionRange(
            inputElement.value.length,
            inputElement.value.length
          ),
        0
      );
      return;
    }

    if (Number(localWindow) !== windowValue) {
      setWindowValue(Big(localWindow).round(0).toNumber());
    }
  };

  const [, cancelWindowDebounce] = useDebounce(
    () => {
      // If the value is less than the minimum, return to previous value and prevent the update
      handleUpdateWindowValue();
    },
    1000,
    [localWindow]
  );

  const handleKeyDown: React.KeyboardEventHandler = (event): void => {
    if (event.key === "Enter") {
      handleUpdateWindowValue();
      cancelWindowDebounce();
    }

    // Displays an empty input when user presses backspace on 1 digit numbers
    if (
      event.code === "Backspace" &&
      Number(localWindow) < 10 &&
      Number(localWindow) > -10
    ) {
      event.preventDefault();
      setLocalWindow("");
    }
  };

  useEffect(() => {
    setLocalWindow(String(windowValue));
  }, [windowValue]);

  return (
    <SInputContainer>
      <SLabel>{t("window")}</SLabel>
      <NumberFormat
        onValueChange={handleChange}
        getInputRef={inputRef}
        value={localWindow}
        customInput={SInputField}
        isAllowed={withValueCap}
        decimalScale={0}
        disableUnderline
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        isNumericString
      />
    </SInputContainer>
  );
};
