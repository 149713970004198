import { CircularProgress, Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    width: 100%;
    height: 100%;
    padding: 0;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
`;

export const SGridRow1 = styled(Grid)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SGridRow2 = styled(Grid)`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    width: 100%;
  }
`;

export const SGridColumn1 = styled(Grid)`
  && {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
`;

export const SGridColumn2 = styled(Grid)`
  && {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
`;

export const SChartColumn1 = styled(Grid)`
  && {
    display: flex;
    width: 200px;
    height: 200px;
  }
`;

export const SChartColumn2 = styled(Grid)`
  && {
    display: flex;
    /* min-width: 200px; */
    padding-left: 20px;
  }
`;

export const SChartRow1 = styled(Grid)`
  && {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const SGridLoading = styled(Grid)`
  && {
    height: 100px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

export const SCircularProgress = styled(CircularProgress)`
  && {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.addButtonSecondary};
  }
`;

export const SNoResultsWarning = styled(Typography)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    font-family: ${({ theme }) => theme.fonts.primary};
    background-color: ${({ theme }) => theme.colors.bgGray};
    color: ${({ theme }) => theme.colors.textDefault};
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    padding: 16px;
    width: 402px;
  }
`;

export const SGridWarningTitle = styled(Typography)`
  && {
    width: 100%;
    margin-bottom: 4px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.textDarkGray};
  }
`;

export const SGridWarningContainer = styled(Grid)`
  && {
    width: 100%;
    height: 100%;
    padding: 0;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
`;
