import React, { useMemo } from "react";
import Big from "big.js";
import { filter } from "ramda";
import { IconButton } from "@mui/material";
import { SSvgIcon, SGrid } from "./styles";
import { ReactComponent as IconUnbalanced } from "../../../../assets/balancingIconUnbalanced.svg";
import { ReactComponent as IconBalanced } from "../../../../assets/balancingIconBalanced.svg";
import { useCurrentPortfolio } from "../../../../store";
import { calculateAssetsTotalPercentage } from "../../../../utils";

export const BalancingButton: React.FC = () => {
  const { balanceCurrentPortfolio, portfolioAssets } = useCurrentPortfolio();

  const isBalanced = useMemo(() => {
    const unlockedAssetPercentages = Object.keys(portfolioAssets)
      .filter((assetKey) => !portfolioAssets[assetKey].isLocked)
      .map((assetKey) =>
        Big(portfolioAssets[assetKey].percentage || 0).toNumber()
      );

    const lockedAssets = filter(
      (asset) => asset.isLocked === true,
      portfolioAssets
    );

    const lockedAssetPercentageSum =
      calculateAssetsTotalPercentage(lockedAssets);

    const isUnlockedAssetsEquallyDivided = (value: number): boolean => {
      if (Big(lockedAssetPercentageSum).gt(100)) {
        return Big(value).eq(0);
      }
      return unlockedAssetPercentages.length === 0
        ? false
        : Big(value).eq(
            Big(100)
              .minus(lockedAssetPercentageSum)
              .div(unlockedAssetPercentages.length)
              .round(2)
          );
    };

    return unlockedAssetPercentages.every(isUnlockedAssetsEquallyDivided);
  }, [portfolioAssets]);

  const handleBalance = (): void => {
    if (!isBalanced) balanceCurrentPortfolio();
  };

  return (
    <SGrid>
      <IconButton onClick={handleBalance} disableRipple disabled={isBalanced}>
        <SSvgIcon $isBalanced={isBalanced} inheritViewBox>
          <IconBalanced />
          {isBalanced ? <IconBalanced /> : <IconUnbalanced />}
        </SSvgIcon>
      </IconButton>
    </SGrid>
  );
};
