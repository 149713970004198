import React, { useCallback, useEffect, useState } from "react";
import Big from "big.js";
import { useDebounce } from "react-use";
import { NumberFormatValues, SourceInfo } from "react-number-format";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { calculateAssetsTotalAmount } from "../../../../utils";
import { useCurrency, useCurrentPortfolio } from "../../../../store";
import {
  SInputContainer,
  SInputTitle,
  SInputField,
  SInputDescription,
  // SMoneyAdornment // TODO: Remover essa linha se o padrão de moeda for consolidado da maneira como está
} from "./styles";
import { CurrencyInput } from "../..";

export const PortfolioTotalAmount: React.FC = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { selectedPortfolio } = useCurrentPortfolio();
  const [totalAmount, setTotalAmount] = useState(
    selectedPortfolio?.totalAmount
  );
  const { updatePortfolioTotal } = useCurrentPortfolio();
  const { currency } = useCurrency();
  const [leftoverAssetsAmount, setLeftoverAssetsAmount] = useState(0);

  const handleChange = (
    maskedInputValue: NumberFormatValues,
    sourceInfo: SourceInfo
  ): void => {
    // onValueChange is called on EVERY change (unlike onChange) not only on events
    // If value is fully deleted, sourceinfo.source equals to "props" and not "event"
    if (
      sourceInfo.source === "event" ||
      (sourceInfo.source === "prop" && maskedInputValue.floatValue === 0)
    ) {
      setTotalAmount(maskedInputValue.floatValue);
    }
  };

  const handleUpdatePortfolioTotal = (): void => {
    if (selectedPortfolio && totalAmount !== selectedPortfolio.totalAmount) {
      updatePortfolioTotal(Big(totalAmount || 0).toNumber());
    }
  };

  const [, cancelPortfolioTotalDebounce] = useDebounce(
    () => {
      handleUpdatePortfolioTotal();
    },
    theme.delay.numberInput,
    [totalAmount]
  );

  useEffect(() => {
    // Caso seja recalculado externamente
    if (
      Big(totalAmount || 0)
        .round(2)
        .toNumber() !==
      Big(selectedPortfolio?.totalAmount || 0)
        .round(2)
        .toNumber()
    ) {
      setTotalAmount(selectedPortfolio?.totalAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortfolio?.totalAmount]);

  const calculateLeftover = useCallback(
    (): number =>
      Big(
        Big(selectedPortfolio?.totalAmount || 0)
          .minus(
            calculateAssetsTotalAmount(
              selectedPortfolio && selectedPortfolio.assets
            )
          )
          .round(2)
          .toString()
      ).toNumber(),
    [selectedPortfolio]
  );

  useEffect(() => {
    setLeftoverAssetsAmount(calculateLeftover());
  }, [
    selectedPortfolio?.totalAmount,
    selectedPortfolio?.assets,
    setLeftoverAssetsAmount,
    calculateLeftover,
  ]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currencySymbol = t(`currency.${currency}.symbol`);

  const handleEnterPress: React.KeyboardEventHandler = (event): void => {
    if (event.key === "Enter") {
      if (selectedPortfolio && totalAmount !== selectedPortfolio.totalAmount) {
        updatePortfolioTotal(Big(totalAmount || 0).toNumber());
      }
      cancelPortfolioTotalDebounce();
    }
  };

  return (
    <SInputContainer>
      <SInputTitle>{t("portfolioEditorInvestedAmount")}</SInputTitle>
      <CurrencyInput
        value={Big(totalAmount || 0)
          .round(2)
          .toNumber()}
        // $hideCurrencySymbol // TODO: Remover essa linha se o padrão de moeda for consolidado da maneira como está
        onValueChange={handleChange}
        customInput={SInputField}
        disableUnderline
        onKeyDown={handleEnterPress}
        // startAdornment={<SMoneyAdornment>{currencySymbol}</SMoneyAdornment>} // TODO: Remover essa linha se o padrão de moeda for consolidado da maneira como está
        sx={{ lineHeight: 22 }}
      />

      <SInputDescription>
        {`${
          leftoverAssetsAmount < 0
            ? t("searchAsset.excess")
            : t("searchAsset.leftover")
        }: ${currencySymbol}`}
        &nbsp;
        {`${new Intl.NumberFormat(i18n.language, {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(leftoverAssetsAmount)}`}
      </SInputDescription>
    </SInputContainer>
  );
};
