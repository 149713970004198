import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    width: 100% !important;
    height: 100% !important;
    min-height: 400px;
  }
`;

export const SCircularProgress = styled(CircularProgress)`
  && {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.addButtonSecondary};
  }
`;

interface FloatButtonType {
  $isSelected: boolean;
}

export const SFloatButton = styled(Button)<FloatButtonType>`
  && {
    position: absolute;
    top: 52px;
    right: 34px;
    width: 132px;
    height: 36px;
    z-index: 1000;

    margin: 0;
    padding: 8px;

    border: 1px solid ${({ theme }) => theme.colors.textDark};
    border-radius: 4px;
    text-transform: none;
    color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.showAssetsButtonSelectedFg
        : theme.colors.showAssetsButtonDark};
    background: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.showAssetsButtonSelected
        : theme.colors.showAssetsButtonLight};

    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      background-color: ${({ theme }) => theme.colors.showAssetsButtonHover};
      color: ${({ theme }) => theme.colors.showAssetsButtonHoverFg};
      border: 1px solid ${({ theme }) => theme.colors.showAssetsButtonHover};
    }
  }
`;

export const SFloatButtonIcon = styled(ShowChartIcon)`
  && {
    width: 14px;
    margin: 0;
    padding: 0;
    padding-right: 4px;
  }
`;

export const SFloatButtonText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const SGridLoading = styled(Grid)`
  && {
    height: 400px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

export const SNoResultsWarning = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    background-color: ${({ theme }) => theme.colors.bgGray};
    color: ${({ theme }) => theme.colors.textDefault};
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    padding: 16px;
  }
`;
