import Search from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { CircularProgress, FormControl, Grid, Input } from "@mui/material";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const SFormControl = styled(FormControl)`
  && {
    width: 100%;
  }
`;

export const SSearch = styled(Search)`
  && {
    display: flex;
    margin: 12px;

    &:hover {
      cursor: default;
    }
  }
`;

export const SCancelIcon = styled(CancelIcon)`
  && {
    display: flex;
    height: 19px;
    margin: 12px;
    color: ${({ theme }) => theme.colors.addButtonPrimary || "black"};
    &:hover {
      cursor: pointer;
    }
  }
`;

export const SInput = styled(Input)`
  && {
    width: 100%;
    height: 48px;
    margin: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.bgGray || "gray"};

    &:hover {
      cursor: default;
    }

    &.Mui-focused {
      border: 1px solid ${({ theme }) => theme.colors.textDark || "#254057"};
    }

    input:-webkit-autofill {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.colors.bgGray || "gray"}
        inset;
    }
  }
`;

interface SGridContainerPropsType {
  $isLoading?: boolean | undefined;
  $isSearchDone?: boolean | undefined;
}

export const SGridResults = styled(Grid)<SGridContainerPropsType>`
  && {
    width: 100%;
    height: ${({ theme }) =>
      theme.navbar.show
        ? "calc(100vh - 240px - 73px)"
        : "calc(100vh - 220px - 30px)"};
    display: flex;
    justify-content: center;
    align-items: ${({ $isLoading, $isSearchDone }) =>
      $isLoading || !$isSearchDone ? "center" : "flex-start"};
    overflow-y: auto;
    border: ${({ $isSearchDone }) =>
      $isSearchDone && "1px solid rgba(51, 51, 51, 0.4)"};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    margin: 0;
    margin-top: 15px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6d3d3;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-button:start:increment {
      height: 3%;
      display: block;
      background: transparent;
    }
  }
`;

export const SCircularProgress = styled(CircularProgress)`
  && {
    color: ${({ theme }) => theme.colors.addButtonSecondary};
  }
`;
