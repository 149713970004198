import { Grid, Button, Typography, Input } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DeleteOutline } from "@mui/icons-material";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.bgGray};
    display: flex;
    flex-direction: column;
    box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.1);
    padding: 0;
    height: 100%;
  }
`;

export const SGridCard = styled(Grid)`
  && {
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 3;
  }
`;

export const SGridRow2 = styled(Grid)`
  && {
    display: flex;
    width: 100%;
    min-height: 155px;
  }
`;

export const SGridRow3 = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    width: 100%;
  }
`;

export const SGridRow4 = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 100%;
  }
`;

export const LeftInputColumn = styled.div`
  color: ${({ theme }) => theme.colors.textDefault};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  width: 45%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 32px;
`;

export const RightInputColumn = styled.div`
  color: ${({ theme }) => theme.colors.textDefault};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  width: 55%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-right: 32px;
  padding-left: 3px;
`;

export const SInputContainer = styled(Grid)`
  width: 100%;
`;

export const SInputTitle = styled(Typography)`
  && {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
  }
`;

export const SInputField = styled(Input)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.textDefault};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 4px;
    padding: 0;
    padding-left: 6px;
    padding-top: 10px;
    padding-bottom: 6px;
    width: 128.5px;
    height: 40px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.bgGray || "gray"};

    .MuiInput-input {
      padding: 0;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.colors.bgGray || "gray"} inset;
    }
  }
`;

export const SEditIcon = styled(DriveFileRenameOutlineOutlinedIcon)`
  color: ${({ theme }) => theme.colors.textDark};
  padding-right: 3px;
`;

export const SInputDescription = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: inherit;
    font-style: inherit;
    font-size: 12px;
    font-weight: 400;
    padding-left: 16px;
  }
`;

export const SMoneyIcon = styled(AttachMoneyOutlinedIcon)`
  color: ${({ theme }) => theme.colors.textDark};
  padding-right: 3px;
`;

export const SAddAssets = styled(Button)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.addAssetTextColor};
    background: ${({ theme }) => theme.colors.addAssetBackground};
    border: 1px solid ${({ theme }) => theme.colors.addAssetBorder};
    box-sizing: border-box;
    border-radius: 4px;

    height: 36px;
    width: 294px;
    margin-top: 16px;
    margin-left: 0;
    margin-right: 6px;

    text-transform: none;
  }
`;

export const SAddIcon = styled(AddIcon)`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.addAssetTextColor};
  }
`;

export const SRemoveButton = styled(Button)`
  && {
    margin: 0;
    padding: 0;

    height: 36px;
    margin-top: 16px;
    margin-left: 0;
    margin-right: 10px;

    min-width: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    border: 1px solid ${({ theme }) => theme.colors.deletePortfolioRed};
    box-sizing: border-box;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.deletePortfolioRed};

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.removeButtonSecondary};
      background-color: ${({ theme }) => theme.colors.deletePortfolioRed};
      color: ${({ theme }) => theme.colors.textWhite};
    }
  }
`;

export const SRemove = styled(DeleteOutline)`
  && {
    font-size: 16px;
  }
`;
