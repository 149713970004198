import { t } from "i18next";
import { FilterListType } from "../../components/shared";

type PtBrFiltersType =
  | "ACAO"
  | ""
  | "RENDA_FIXA"
  | "PREVIDENCIA"
  | "FUNDO"
  | "BENCHMARK_PERSONALIZADO"
  | "EUROPA";

/**
 * Translates category key into asset type name
 * @param category - Provides translations for assetTypes
 * @returns the translated name of assetType
 */
export const translateCategory = (
  category: PtBrFiltersType | string | null | undefined
): string => {
  switch (category) {
    case "ACAO":
      return t("searchAsset.stocks");
    case "FUNDO":
    case "EUROPA":
      return t("searchAsset.funds");
    case "RENDA_FIXA":
      return t("searchAsset.fixedIncome");
    case "PREVIDENCIA":
      return t("searchAsset.pension");
    case "VALORIZACAO":
      return t("searchAsset.valorization");
    case "DIVERSIFICACAO":
      return t("searchAsset.diversification");
    case "ESTABILIDADE":
      return t("searchAsset.stability");
    case "ANTIFRAGILIDADE":
      return t("searchAsset.antifragility");
    case "OUTROS":
      return t("searchAsset.others");
    case "BENCHMARK_PERSONALIZADO":
      return t("benchmarkButton.custom");
    case "Dólar":
      return t("benchmarkButton.dolar");

    default:
      return String(category);
  }
};

/**
 * Receives a list of filters and returns an array with these filters translated to ptbr
 * @param filters - Filters
 * @returns array of translated filter keys
 */
export const convertToPtBrFilters = (filters: FilterListType): string[] =>
  Object.keys(filters)
    .map((key) => {
      switch (key) {
        case "stocks":
          return filters[key] ? "ACAO" : "";
        case "fixedIncome":
          return filters[key] ? "RENDA_FIXA" : "";
        case "pension":
          return filters[key] ? "PREVIDENCIA" : "";
        case "funds":
          return filters[key] ? ["FUNDO", "EUROPA"] : "";
        case "valorization":
          return filters[key] ? "VALORIZACAO" : "";
        case "diversification":
          return filters[key] ? "DIVERSIFICACAO" : "";
        case "stability":
          return filters[key] ? "ESTABILIDADE" : "";
        case "antifragility":
          return filters[key] ? "ANTIFRAGILIDADE" : "";
        case "others":
          return filters[key] ? "OUTROS" : "";
        case "createAsset":
          return filters[key] ? ["BENCHMARK_PERSONALIZADO"] : "";
        default:
          return "";
      }
    })
    .flat(Infinity)
    .filter((e) => e) as string[];
