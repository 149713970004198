import React from "react";
import { useTranslation } from "react-i18next";
import { useWallet } from "../../../../store";
import { SButton, SButtonGroup, SGridContainer, STitle } from "./styles";

export const ConsolidatedSelector: React.FC = () => {
  const {
    selectedConsolidated,
    availableConsolidated,
    setSelectedConsolidated,
  } = useWallet();
  const { t } = useTranslation();

  const translateConsolidated = (serverConsolidatedKey: string): string => {
    switch (serverConsolidatedKey) {
      case "ATIVOS":
        return t("wallet.asset");
      case "SETOR_DO_ATIVO":
        return t("wallet.sector");
      case "RISCO_DO_ATIVO":
        return t("wallet.risk");
      case "CLASSE_DO_ATIVO":
        return t("wallet.class");
      case "TIPO_DO_ATIVO":
        return t("wallet.type");
      default:
        return String(serverConsolidatedKey);
    }
  };

  const handleSelectedConsolidate = (item: string): void => {
    setSelectedConsolidated(item);
  };

  return (
    <SGridContainer>
      <STitle>{t("wallet.consolidated")}</STitle>
      <SButtonGroup>
        {availableConsolidated.length > 1 &&
          availableConsolidated.map((consolidatedItem) => (
            <SButton
              $isSelected={consolidatedItem === selectedConsolidated}
              key={consolidatedItem}
              onClick={() => handleSelectedConsolidate(consolidatedItem)}
            >
              {translateConsolidated(consolidatedItem)}
            </SButton>
          ))}
      </SButtonGroup>
    </SGridContainer>
  );
};
