import { Drawer } from "@mui/material";
import React, { ReactNode } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import { SIconButtonMenu, SIconCloseMenu, SPaper } from "./styles";
import { useIsSmallSize } from "../../store";

interface ResponsiveDrawerPropsType {
  children: ReactNode;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  drawerOpen: boolean;
}

export const ResponsiveNavBarDrawer: React.FC<ResponsiveDrawerPropsType> = ({
  children,
  handleDrawerOpen,
  handleDrawerClose,
  drawerOpen,
}) => {
  const { isSmallScreen } = useIsSmallSize();

  if (!isSmallScreen) {
    return <>{children}</>;
  }

  return (
    <>
      <SIconButtonMenu
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={handleDrawerOpen}
      >
        <MenuIcon />
      </SIconButtonMenu>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
        <SPaper>
          <SIconCloseMenu
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerClose}
          >
            <CloseIcon />
          </SIconCloseMenu>
          {children}
        </SPaper>
      </Drawer>
    </>
  );
};
