import { Grid } from "@mui/material";
import styled from "styled-components";

export const SIframe = styled.iframe`
  && {
    border: 0;
  }
`;

export const SGridOuterContainer = styled(Grid)`
  && {
    overflow: hidden;
    height: calc(100vh - 84px);
  }
`;

export const SGridContainer = styled(Grid)`
  && {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    z-index: -1;
  }
`;
