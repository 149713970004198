import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import { useMount } from "react-use";

import { Logout } from "@mui/icons-material";
import {
  SAppBar,
  SLink,
  SLogoImg,
  SLogoutButton,
  SMenuItem,
  SMenuItems,
  SToolbar,
} from "./styles";
import { LocaleConfigsSelector } from "../shared";
import {
  useIsSmallSize,
  useSelectedTab,
  useSuperCarteiraRedirections,
} from "../../store";
import { ResponsiveNavBarDrawer } from "../ResponsiveNavBarDrawer/ResponsiveNavBarDrawer";
import { removeCredentials } from "../../utils";

type TabsType =
  | "supercarteira"
  | "previdencia"
  | "portfolio"
  | "asset"
  | "importSupercarteira"
  | "importPrevidencia"
  | "monitoring";

export const NavBar: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { gotoFeature } = useSuperCarteiraRedirections();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = (): void => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = (): void => {
    setDrawerOpen(false);
  };

  const initialTab = ((): TabsType => {
    if (location.pathname === "/shared") {
      return "portfolio";
    }

    if (theme.navbar.startingRoute === "/asset") {
      return "asset";
    }

    if (theme.navbar.startingRoute === "/monitoring") {
      return "monitoring";
    }

    if (process.env.REACT_APP_THEME === "SUPER_CARTEIRA") {
      return "supercarteira";
    }

    return "portfolio";
  })();

  const { selectedTab, setSelectedTab } = useSelectedTab();

  useMount(() => {
    setSelectedTab(initialTab);
  });

  const isSinglePortfolioMode = theme.portfolios.singlePortfolioMode;

  const handleSelectTab = (tabId: TabsType): void => {
    setSelectedTab(tabId);
    switch (tabId) {
      case "supercarteira":
        gotoFeature("supercarteira");
        break;
      case "previdencia":
        gotoFeature("previdencia");
        break;
      case "portfolio":
        navigate("/");
        break;

      case "asset":
        navigate("/asset");
        break;

      case "importSupercarteira":
        navigate("/import/supercarteira");
        break;
      case "importPrevidencia":
        navigate("/import/previdencia");
        break;

      case "monitoring":
        navigate("/monitoring");
        break;
      default:
        break;
    }
  };

  const { isSmallScreen } = useIsSmallSize();

  return (
    <SAppBar position="sticky">
      <SToolbar>
        {theme.logo.show === true && (
          <SLogoImg
            src={theme.logo.img}
            height={theme.logo.height}
            alt="logo"
            $padding={theme.logo.padding}
          />
        )}

        <ResponsiveNavBarDrawer
          drawerOpen={drawerOpen}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        >
          <SMenuItems
            container
            spacing={0}
            $supercarteira={process.env.REACT_APP_THEME === "SUPER_CARTEIRA"}
          >
            {theme.navbar.supercarteiraButtons ? (
              <Grid item>
                <SMenuItem
                  onClick={() => handleSelectTab("supercarteira")}
                  $selected={selectedTab === "supercarteira"}
                >
                  <SLink to="/">{t("navSupercarteira")}</SLink>
                </SMenuItem>
              </Grid>
            ) : null}

            {theme.navbar.supercarteiraButtons ? (
              <Grid item>
                <SMenuItem
                  onClick={() => handleSelectTab("previdencia")}
                  $selected={selectedTab === "previdencia"}
                >
                  <SLink to="/">{t("navPrevidencia")}</SLink>
                </SMenuItem>
              </Grid>
            ) : null}

            {!theme.navbar.supercarteiraButtons ? (
              <Grid item>
                <SMenuItem
                  id="nav-button"
                  onClick={() => handleSelectTab("portfolio")}
                  $selected={selectedTab === "portfolio"}
                >
                  <SLink to="/">
                    {isSinglePortfolioMode
                      ? t("navComparePortfolioSingleMode")
                      : t("navComparePortfolio")}
                  </SLink>
                </SMenuItem>
              </Grid>
            ) : null}

            <Grid item>
              <SMenuItem
                color="inherit"
                onClick={() => handleSelectTab("asset")}
                $selected={selectedTab === "asset"}
              >
                {t("navCompareAssets")}
              </SMenuItem>
            </Grid>

            {/*    <Grid item>
            <SMenuItem
              color="inherit"
              onClick={() => handleSelectTab("monitoring")}
              $selected={selectedTab === "monitoring"}
            >
              {t("navMonitoring")}
            </SMenuItem>
            </Grid>
       */}
            {theme.navbar.supercarteiraButtons ? (
              <Grid item>
                <SMenuItem
                  onClick={() => handleSelectTab("importSupercarteira")}
                  $selected={selectedTab === "importSupercarteira"}
                >
                  <SLink to="/">{t("navImportSupercarteira")}</SLink>
                </SMenuItem>
              </Grid>
            ) : null}
            {theme.navbar.supercarteiraButtons ? (
              <Grid item>
                <SMenuItem
                  onClick={() => handleSelectTab("importPrevidencia")}
                  $selected={selectedTab === "importPrevidencia"}
                >
                  <SLink to="/">{t("navImportPrevidencia")}</SLink>
                </SMenuItem>
              </Grid>
            ) : null}

            {theme.navbar.supercarteiraButtons ? (
              <SLogoutButton
                $isFloating={!isSmallScreen}
                onClick={() => {
                  try {
                    removeCredentials();
                    document.location.reload();
                  } catch {
                    if (process.env.NODE_ENV === "development")
                      console.error("Failed removing credentials!");
                  }
                }}
              >
                <Logout />
                {t("logout")}
              </SLogoutButton>
            ) : null}
          </SMenuItems>
        </ResponsiveNavBarDrawer>
        {theme.localeSettings.showSettingsGearButton &&
          !theme.localeSettings.showInsideLeftColumn && (
            <LocaleConfigsSelector />
          )}
      </SToolbar>
    </SAppBar>
  );
};
