import Big from "big.js";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { SBar } from "../../SearchAsset/SearchFilterButton/styles";
import { calculateAssetsTotalPercentage } from "../../../../utils";

import {
  SLegendTitle,
  SCategory,
  SGridContainer,
  SGridLegendRow,
  SMessage,
  SPercentage,
} from "./styles";
import { useCurrentPortfolio } from "../../../../store";

const formatPercentage = (value: number, decimalPlaces?: number): string => {
  return value.toFixed(decimalPlaces);
};

export const PortfolioSummaryDonutChartLegend: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { selectedPortfolio } = useCurrentPortfolio();
  const assets = selectedPortfolio?.assets || {};
  const assetsLength = Object.keys(assets).length;

  const stocks = Object.keys(assets).filter(
    (el) =>
      assets[el].asset.portfolioCategory === "ACAO" ||
      assets[el].asset.portfolioCategory === "EUROPA"
  );
  const stocksPercentage = stocks
    .reduce(
      (acc, value) => acc.plus(Big(assets[value].percentage || 0)),
      Big(0)
    )
    .round(0)
    .toNumber();

  const funds = Object.keys(assets).filter(
    (el) => assets[el].asset.portfolioCategory === "FUNDO"
  );
  const fundsPercentage = funds
    .reduce(
      (acc, value) => acc.plus(Big(assets[value].percentage || 0)),
      Big(0)
    )
    .round(0)
    .toNumber();

  const fixedIncome = Object.keys(assets).filter(
    (el) => assets[el].asset.portfolioCategory === "RENDA_FIXA"
  );
  const fixedIncomePercentage = fixedIncome
    .reduce(
      (acc, value) => acc.plus(Big(assets[value].percentage || 0)),
      Big(0)
    )
    .round(0)
    .toNumber();

  const pension = Object.keys(assets).filter(
    (el) => assets[el].asset.portfolioCategory === "PREVIDENCIA"
  );
  const pensionPercentage = pension
    .reduce(
      (acc, value) => acc.plus(Big(assets[value].percentage || 0)),
      Big(0)
    )
    .round(0)
    .toNumber();

  const custom = Object.keys(assets).filter(
    (el) => assets[el].asset.portfolioCategory === "BENCHMARK_PERSONALIZADO"
  );
  const customPercentage = custom
    .reduce(
      (acc, value) => acc.plus(Big(assets[value].percentage || 0)),
      Big(0)
    )
    .round(0)
    .toNumber();

  const valorization = Object.keys(assets).filter(
    (el) => assets[el].asset.portfolioCategory === "VALORIZACAO"
  );
  const valorizationPercentage = valorization
    .reduce(
      (acc, value) => acc.plus(Big(assets[value].percentage || 0)),
      Big(0)
    )
    .round(0)
    .toNumber();

  const diversification = Object.keys(assets).filter(
    (el) => assets[el].asset.portfolioCategory === "DIVERSIFICACAO"
  );
  const diversificationPercentage = diversification
    .reduce(
      (acc, value) => acc.plus(Big(assets[value].percentage || 0)),
      Big(0)
    )
    .round(0)
    .toNumber();

  const stability = Object.keys(assets).filter(
    (el) => assets[el].asset.portfolioCategory === "ESTABILIDADE"
  );
  const stabilityPercentage = stability
    .reduce(
      (acc, value) => acc.plus(Big(assets[value].percentage || 0)),
      Big(0)
    )
    .round(0)
    .toNumber();

  const antifragility = Object.keys(assets).filter(
    (el) => assets[el].asset.portfolioCategory === "ANTIFRAGILIDADE"
  );
  const antifragilityPercentage = antifragility
    .reduce(
      (acc, value) => acc.plus(Big(assets[value].percentage || 0)),
      Big(0)
    )
    .round(0)
    .toNumber();

  const others = Object.keys(assets).filter(
    (el) => assets[el].asset.portfolioCategory === "OUTROS"
  );
  const othersPercentage = others
    .reduce(
      (acc, value) => acc.plus(Big(assets[value].percentage || 0)),
      Big(0)
    )
    .round(0)
    .toNumber();

  const leftoverPercentage = Big(100)
    .minus(calculateAssetsTotalPercentage(assets))
    .round(0)
    .toNumber();

  const removePadding = theme.portfolioSummary.compactList;

  return (
    <SGridContainer>
      <SLegendTitle>{t("portfolioEditorTitle")}</SLegendTitle>
      {assetsLength === 0 ? (
        <SMessage>{t("placeHolderMessage")}</SMessage>
      ) : (
        <>
          {stability.length > 0 && (
            <SGridLegendRow $removePadding={removePadding}>
              <SBar
                barColor={
                  leftoverPercentage < 0
                    ? theme.colors.alertRed
                    : theme.colors.stability || "purple"
                }
              />
              <SPercentage>
                {formatPercentage(stabilityPercentage)}%
              </SPercentage>
              <SCategory>{t("searchAsset.stability")}</SCategory>
            </SGridLegendRow>
          )}

          {diversification.length > 0 && (
            <SGridLegendRow $removePadding={removePadding}>
              <SBar
                barColor={
                  leftoverPercentage < 0
                    ? theme.colors.alertRed
                    : theme.colors.diversification || "purple"
                }
              />
              <SPercentage>
                {formatPercentage(diversificationPercentage)}%
              </SPercentage>
              <SCategory>{t("searchAsset.diversification")}</SCategory>
            </SGridLegendRow>
          )}

          {valorization.length > 0 && (
            <SGridLegendRow $removePadding={removePadding}>
              <SBar
                barColor={
                  leftoverPercentage < 0
                    ? theme.colors.alertRed
                    : theme.colors.valorization || "purple"
                }
              />
              <SPercentage>
                {formatPercentage(valorizationPercentage)}%
              </SPercentage>
              <SCategory>{t("searchAsset.valorization")}</SCategory>
            </SGridLegendRow>
          )}

          {antifragility.length > 0 && (
            <SGridLegendRow $removePadding={removePadding}>
              <SBar
                barColor={
                  leftoverPercentage < 0
                    ? theme.colors.alertRed
                    : theme.colors.antifragility || "purple"
                }
              />
              <SPercentage>
                {formatPercentage(antifragilityPercentage)}%
              </SPercentage>
              <SCategory>{t("searchAsset.antifragility")}</SCategory>
            </SGridLegendRow>
          )}

          {others.length > 0 && (
            <SGridLegendRow $removePadding={removePadding}>
              <SBar
                barColor={
                  leftoverPercentage < 0
                    ? theme.colors.alertRed
                    : theme.colors.others || "purple"
                }
              />
              <SPercentage>{formatPercentage(othersPercentage)}%</SPercentage>
              <SCategory>{t("searchAsset.others")}</SCategory>
            </SGridLegendRow>
          )}

          {funds.length > 0 && (
            <SGridLegendRow $removePadding={removePadding}>
              <SBar
                barColor={
                  leftoverPercentage < 0
                    ? theme.colors.alertRed
                    : theme.colors.funds || "purple"
                }
              />
              <SPercentage>{formatPercentage(fundsPercentage)}%</SPercentage>
              <SCategory>{t("searchAsset.funds")}</SCategory>
            </SGridLegendRow>
          )}
          {stocks.length > 0 && (
            <SGridLegendRow $removePadding={removePadding}>
              <SBar
                barColor={
                  leftoverPercentage < 0
                    ? theme.colors.alertRed
                    : theme.colors.stocks || "orange"
                }
              />
              <SPercentage>{formatPercentage(stocksPercentage)}%</SPercentage>
              <SCategory>{t("searchAsset.stocks")}</SCategory>
            </SGridLegendRow>
          )}
          {fixedIncome.length > 0 && (
            <SGridLegendRow $removePadding={removePadding}>
              <SBar
                barColor={
                  leftoverPercentage < 0
                    ? theme.colors.alertRed
                    : theme.colors.fixedIncome || "pink"
                }
              />
              <SPercentage>
                {formatPercentage(fixedIncomePercentage)}%
              </SPercentage>
              <SCategory>{t("searchAsset.fixedIncome")}</SCategory>
            </SGridLegendRow>
          )}
          {pension.length > 0 && (
            <SGridLegendRow $removePadding={removePadding}>
              <SBar
                barColor={
                  leftoverPercentage < 0
                    ? theme.colors.alertRed
                    : theme.colors.pension || "green"
                }
              />
              <SPercentage>{formatPercentage(pensionPercentage)}%</SPercentage>
              <SCategory>{t("searchAsset.pension")}</SCategory>
            </SGridLegendRow>
          )}
          {custom.length > 0 && (
            <SGridLegendRow $removePadding={removePadding}>
              <SBar
                barColor={
                  leftoverPercentage < 0
                    ? theme.colors.alertRed
                    : theme.colors.custom || "green"
                }
              />
              <SPercentage>{formatPercentage(customPercentage)}%</SPercentage>
              <SCategory>{t("benchmarkButton.custom")}</SCategory>
            </SGridLegendRow>
          )}
          {leftoverPercentage > 0 && (
            <SGridLegendRow $removePadding={removePadding}>
              <SBar barColor={theme.colors.leftover || "gray"} />
              <SPercentage>{formatPercentage(leftoverPercentage)}%</SPercentage>
              <SCategory>{t("searchAsset.leftover")}</SCategory>
            </SGridLegendRow>
          )}
        </>
      )}
    </SGridContainer>
  );
};
