import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";

export const SGridContainer = styled(Grid)`
  && {
    width: 600px;
    height: 311px;
    padding: 0;
    padding-top: 30px;
  }
`;

export const SDialogTitle = styled(DialogTitle)`
  && {
    padding: 0;
  }
`;

export const STitle = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: ${({ theme }) => theme.colors.textDefault};
    padding: 0;
  }
`;

export const SCloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

export const SCloseIcon = styled(CloseIcon)`
  && {
    color: ${({ theme }) => theme.colors.textBlack};
  }
`;

export const SDialogContent = styled(DialogContent)`
  && {
    display: flex;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const SPaddedLines = styled(Grid)`
  && {
    padding-left: 32px;
    padding-right: 50px;
  }
`;

export const SUnpaddedLines = styled(Grid)`
  && {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
  }
`;

export const SGridRow1 = styled(Grid)`
  && {
    margin-top: 22px;
    width: 100%;
    display: flex;
  }
`;

export const SGridRow2 = styled(Grid)`
  && {
    margin-top: 22px;
    width: 100%;
  }
`;

export const SAddButton = styled(Button)`
  && {
    width: 100%;
    height: 56px;

    background-color: ${({ theme }) => theme.colors.textDark};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: none;
    border-radius: 0px;

    color: ${({ theme }) => theme.colors.textWhite};
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.bgGray : theme.colors.textDark};

    &:hover {
      background-color: ${({ theme }) => theme.colors.addButtonSecondary};
      color: ${({ theme }) => theme.colors.addButtonHoverFg};
    }
  }
`;

export const SLabel = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textDefault};
  }
`;

export const SGridColumn1 = styled(Grid)`
  && {
    width: 325px;
    margin-right: 18px;
  }
`;

export const SGridColumn2 = styled(Grid)`
  && {
    width: 84px !important;
  }
`;

export const SGridColumn3 = styled(Grid)`
  && {
  }
`;

export const SSelect = styled(Select)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    height: 35px;
    width: 325px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.textDefault};
    background-color: ${({ theme }) => theme.colors.bgGray};
    margin-right: 32px;
    border-radius: 2px;

    & .MuiSelect-select {
      color: ${({ theme }) => theme.colors.textDefault};
      font-weight: 600;
    }

    & .MuiSelect-select:focus {
      background-color: ${({ theme }) => theme.colors.bgGray};
    }
  }
`;

export const SMenuItem = styled(MenuItem)`
  && {
  }
`;

interface ButtonPropsType {
  $selected: boolean;
}

export const SPercentageFunction = styled(Button)<ButtonPropsType>`
  && {
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: 7px;

    background-color: ${({ theme }) => theme.colors.textDark};
    border: ${({ theme, $selected }) =>
      $selected ? "none" : `1px solid ${theme.colors.addButtonPrimary}`};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: none;
    color: ${({ theme, $selected }) =>
      $selected ? theme.colors.textWhite : theme.colors.addButtonPrimary};

    border-radius: 4px;
    background-color: ${({ theme, $selected }) =>
      $selected ? theme.colors.addButtonPrimary : theme.colors.textWhite};

    &:hover {
      color: ${({ theme }) => theme.colors.addButtonHoverFg};
      background-color: ${({ theme }) => theme.colors.addButtonSecondary};
    }
  }
`;

export const SAddFunction = styled(Button)<ButtonPropsType>`
  && {
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: 7px;

    background-color: ${({ theme }) => theme.colors.textDark};
    border: ${({ theme, $selected }) =>
      $selected ? "none" : `1px solid ${theme.colors.addButtonPrimary}`};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: none;
    color: ${({ theme, $selected }) =>
      $selected ? theme.colors.textWhite : theme.colors.addButtonPrimary};

    border-radius: 4px;
    background-color: ${({ theme, $selected }) =>
      $selected ? theme.colors.addButtonPrimary : theme.colors.textWhite};

    &:hover {
      color: ${({ theme }) => theme.colors.addButtonHoverFg};
      background-color: ${({ theme }) => theme.colors.addButtonSecondary};
    }
  }
`;

export const SInputRange = styled(Input)`
  && {
    width: 75px;
    height: 35px;
    margin: 0;
    padding: 0;
    padding-left: 16px;
    padding-right: 12px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    border: none;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDefault || "black"};
    background-color: ${({ theme }) => theme.colors.bgGray || "gray"};

    &:focus {
      outline: none !important;
      outline-offset: none !important;
    }

    input {
      text-align: right;
    }

    input:hover {
      cursor: "default";
    }
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.colors.bgGray || "gray"} inset;
    }
    border-radius: 2px;
    z-index: 999;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

export const SNameInput = styled(Input)`
  && {
    width: 514px;
    height: 35px;
    margin: 0;
    padding: 0;
    padding-left: 16px;
    padding-right: 12px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    border: none;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDefault || "black"};
    background-color: ${({ theme }) => theme.colors.bgGray || "gray"};

    &:focus {
      outline: none !important;
      outline-offset: none !important;
    }

    input {
      text-align: left;
    }

    input:hover {
      cursor: "default";
    }
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.colors.bgGray || "gray"} inset;
    }
    border-radius: 2px;
    z-index: 999;
  }
`;

export const SProgressGrid = styled(Grid)`
  && {
    width: 100%;
    height: 56px;
    max-height: 56px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.textDark};
  }
`;

export const SCircularProgress = styled(CircularProgress)`
  && {
    line-height: 40px;
    color: ${({ theme }) => theme.colors.textWhite};
  }
`;

export const SErrorGroup = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    margin-top: 5px;
  }
`;

export const SErrorMessage = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.alertRed};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
    text-align: start;
  }
`;

export const SErrorIcon = styled(HighlightOffIcon)`
  && {
    color: ${({ theme }) => theme.colors.alertRed};
  }
`;
