import { drop, take } from "ramda";

/**
 * Rotate items in an Array n steps to the right, carried values inserted at the beginning
 * @param arr - an array of strings
 * @param steps - how many steps it should rotate
 * @returns an shifted array of strings
 *
 * @example
 * Here's an example:
 * ```
 * rotateToRight([1, 2, 3, 4, 5], 1)
 * // returns: [5, 1, 2, 3, 4]
 *
 * rotateToRight([1, 2, 3, 4, 5], 2)
 * // returns: [4, 5, 1, 2, 3]
 * ```
 */
export const rotateToRight = (arr: string[], steps = 1): string[] => [
  ...drop(arr.length - steps, arr),
  ...take(arr.length - steps, arr),
];
