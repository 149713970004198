import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConsolidatedSelector } from "./ConsolidatedSelector/ConsolidatedSelector";
import { CompetenceSelector } from "./CompetenceSelector/CompetenceSelector";
import {
  SGridContainer,
  SGridRow1,
  SGridRow2,
  SGridColumn1,
  SGridColumn2,
  SChartColumn1,
  SChartColumn2,
  SChartRow1,
  SGridLoading,
  SCircularProgress,
  SNoResultsWarning,
  SGridWarningTitle,
  SGridWarningContainer,
} from "./styles";
import { useAllPortfolios, useWallet } from "../../../store";
import { WalletChart } from "./WalletChart/WalletChart";
import { WalletTable } from "./WalletTable/WalletTable";
import {
  PortfolioCompositionType,
  useFetchCompetence,
  useFetchPortfolioComposition,
} from "../../../api";

export const WalletCharts: React.FC = () => {
  const { allPortfolios } = useAllPortfolios();
  const { fetch: getPortfoliosComposition, loading: isLoadingComposition } =
    useFetchPortfolioComposition();
  const { selectedConsolidated, selectedCompetence } = useWallet();
  const [compositionAssets, setCompositionAssets] =
    useState<PortfolioCompositionType[][]>();
  const { loading: isLoadingCompetence } = useFetchCompetence();
  const { t } = useTranslation();

  const handleDataFetching = useCallback(async (): Promise<void> => {
    const portfoliosComposition = await getPortfoliosComposition(
      selectedConsolidated,
      selectedCompetence
    );

    setCompositionAssets(portfoliosComposition);
  }, [selectedConsolidated, selectedCompetence, allPortfolios]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleDataFetching();
  }, [handleDataFetching]);

  const translateOthersConsolidated = (
    serverConsolidatedKey: string
  ): string => {
    switch (serverConsolidatedKey) {
      case "ATIVOS":
        return t("wallet.otherAssets");
      case "SETOR_DO_ATIVO":
        return t("wallet.otherSectors");
      case "RISCO_DO_ATIVO":
        return t("wallet.otherRisks");
      case "CLASSE_DO_ATIVO":
        return t("wallet.otherClasses");
      case "TIPO_DO_ATIVO":
        return t("wallet.otherTypes");
      default:
        return String(serverConsolidatedKey);
    }
  };

  return (
    <SGridContainer>
      <SGridRow1>
        <SGridColumn1>
          <ConsolidatedSelector />
        </SGridColumn1>
        <SGridColumn2>
          <CompetenceSelector />
        </SGridColumn2>
      </SGridRow1>
      <SGridRow2>
        {isLoadingComposition || isLoadingCompetence ? (
          <SGridLoading key="PortfoliosCompositionKey">
            <SCircularProgress variant="indeterminate" color="inherit" />
          </SGridLoading>
        ) : null}

        {!isLoadingComposition && !isLoadingCompetence
          ? compositionAssets &&
            compositionAssets.length === Object.keys(allPortfolios).length &&
            Object.keys(allPortfolios).map((portfolioKey) =>
              selectedCompetence !== "" &&
              compositionAssets[Number(portfolioKey)].length !== 0 &&
              compositionAssets[Number(portfolioKey)][0].label !== "n/a" ? (
                <SChartRow1 key={`${portfolioKey}--Wallet-Row-key`}>
                  <SChartColumn1>
                    <WalletChart
                      key={`${portfolioKey}--Chart-key`}
                      compositionAssets={
                        compositionAssets[Number(portfolioKey)]
                      }
                      portfolio={allPortfolios[Number(portfolioKey)]}
                      othersString={translateOthersConsolidated(
                        selectedConsolidated
                      )}
                    />
                  </SChartColumn1>
                  <SChartColumn2>
                    <WalletTable
                      key={`${portfolioKey}--Wallet-key`}
                      compositionAssets={
                        compositionAssets[Number(portfolioKey)]
                      }
                      title={allPortfolios[Number(portfolioKey)].name}
                      othersString={translateOthersConsolidated(
                        selectedConsolidated
                      )}
                    />
                  </SChartColumn2>
                </SChartRow1>
              ) : (
                <SGridWarningContainer key={`${portfolioKey}--no-results`}>
                  <SGridWarningTitle>
                    {allPortfolios[Number(portfolioKey)].name}
                  </SGridWarningTitle>
                  <SNoResultsWarning>{t("wallet.noWallet")}</SNoResultsWarning>
                </SGridWarningContainer>
              )
            )
          : null}
      </SGridRow2>
    </SGridContainer>
  );
};
