import { Add, DeleteOutline } from "@mui/icons-material";
import { Button, Grid, Input, Typography } from "@mui/material";
import NumberFormat from "react-number-format";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 0;
    margin-bottom: 8px;
    min-height: 52px;

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
      z-index: 9999;
    }
  }
`;

export const SGridRow1 = styled(Grid)`
  && {
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: stretch;
    margin: 0;
    padding: 0;
  }
`;

export const STypographyTop = styled(Typography)`
  && {
    margin: 0;
    padding: 0;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.textDefault};
  }
`;

export const SGridRow2 = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

export const SGridRow3 = styled(Grid)`
  && {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const SGridRow4 = styled(Grid)`
  && {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin: 0;
    padding-top: 4px;
    padding-left: 28px;
    padding-bottom: 16px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: ${({ theme }) => theme.colors.textGray};
  }
`;

interface UnderPropType {
  $isEmpty: boolean;
}

export const STypographyUnder = styled(Typography)<UnderPropType>`
  && {
    margin: 0;
    padding: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: wrap;
    flex-wrap: wrap;

    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-left: 12.14px;
    margin-bottom: ${({ $isEmpty }) => ($isEmpty ? "0" : "8px")};
    height: ${({ $isEmpty }) => ($isEmpty ? "4px" : "auto")};
    color: ${({ theme }) => theme.colors.textFaded};
  }
`;

interface SColorBarPropsType {
  $barColor: string;
}

export const SColorBar = styled(Typography)<SColorBarPropsType>`
  && {
    margin: 0;
    padding: 0;
    background-color: ${({ $barColor }) => $barColor};
    min-width: 4.14px;
    height: 16px;
    margin-top: 8px;
    margin-bottom: 4px;
    margin-right: 8px;
  }
`;

interface SGridColumn1PropsType {
  $itemSelected: boolean;
}

export const SGridColumn1 = styled(Grid)<SGridColumn1PropsType>`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 52px);
    min-height: 52px;
    margin: 0;
    padding: 0;
    margin-right: 3px;
    ${({ $itemSelected, theme }) =>
      $itemSelected
        ? `outline: 1px solid ${theme.colors.addButtonPrimary};`
        : "outline: 1px solid #F8F9FA;"}
    outline-offset: -1px;
    border-radius: 4px;
  }
`;

interface SGridColumn2PropsType {
  $itemSelected: boolean;
}
export const SGridColumn2 = styled(Grid)<SGridColumn2PropsType>`
  && {
    width: 52px;
    min-width: 52px;
    margin: 0;
    padding: 0;
    /* align-self: stretch; */
    align-self: ${({ $itemSelected }) =>
      $itemSelected ? "stretch" : "center"};
    ${({ $itemSelected }) => ($itemSelected ? "" : "height: 52px;")}
  }
`;

interface SAddButtonPropTypes {
  selected?: boolean;
}

export const SAddButton = styled(Button)<SAddButtonPropTypes>`
  && {
    width: 52px;
    min-width: 52px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: ${({ theme, selected }) =>
      selected
        ? theme.colors.selectedLightBlue
        : theme.colors.addButtonPrimary};
    color: white;

    &:hover {
      color: ${({ theme }) => theme.colors.addButtonHoverFg};
      background-color: ${({ theme, selected }) =>
        selected
          ? theme.colors.selectedLightBlue
          : theme.colors.addButtonSecondary};
    }
  }
`;

export const SAdd = styled(Add)`
  && {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.addButtonText};
  }
`;

interface SRemoveButtonPropTypes {
  selected?: boolean;
}

export const SRemoveButton = styled(Button)<SRemoveButtonPropTypes>`
  && {
    width: 52px;
    min-width: 52px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: ${({ theme, selected }) =>
      selected
        ? theme.colors.selectedLightBlue
        : theme.colors.removeButtonPrimary};
    color: white;

    &:hover {
      background-color: ${({ theme, selected }) =>
        selected
          ? theme.colors.selectedLightBlue
          : theme.colors.removeButtonSecondary};
    }
  }
`;

export const SRemove = styled(DeleteOutline)`
  && {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.removeButtonText};
  }
`;

interface SInvestTooglePropTypes {
  $isSelected: boolean;
}

export const SInvestToogle = styled(Button)<SInvestTooglePropTypes>`
  && {
    height: 48px;
    min-width: 48px;
    width: 48px;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #333333;
    border-radius: 2px;
    background-color: ${({ theme, $isSelected }) =>
      $isSelected ? theme.colors.addButtonPrimary : theme.colors.bgPrimary};
    color: ${({ theme, $isSelected }) =>
      $isSelected ? theme.colors.addButtonText : theme.colors.textDefault};

    &:hover {
      color: ${({ theme }) => theme.colors.addButtonHoverFg};
      background-color: ${({ theme, $isSelected }) =>
        $isSelected
          ? theme.colors.addButtonSecondary
          : theme.colors.addButtonSecondary};
    }

    &:disabled {
      border: none;
      background-color: ${({ theme }) => theme.colors.bgGray};
    }
  }
`;

export const SInvestInput = styled(Input)`
  && {
    width: 100%;
    margin: 0;
    margin-left: 4px;
    padding: 0;
    height: 48px;

    padding-left: 20px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    border: none;
    box-shadow: "none";
    background-color: ${({ theme }) => theme.colors.bgGray || "gray"};

    input: {
      width: fit-content;
      min-width: 0;
    }

    &:focus {
      outline: none !important;
      outline-offset: none !important;
    }

    input:hover {
      cursor: "default";
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.colors.bgGray || "gray"} inset;
    }
    border-radius: 2px;
    z-index: 999;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

export const SPercentageInput = styled(NumberFormat)`
  && {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 48px;

    padding-left: 20px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    border: none;
    box-shadow: "none";
    background-color: ${({ theme }) => theme.colors.bgGray || "gray"};
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.textFaded : "inherit"};

    input: {
      width: fit-content;
      min-width: 0;
    }

    &:focus {
      outline: none !important;
      outline-offset: none !important;
    }

    input:hover {
      cursor: "default";
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.colors.bgGray || "gray"} inset;
    }
    border-radius: 2px;
    z-index: 999;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

export const SForm = styled.form`
  width: 100%;
`;
