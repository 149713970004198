import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: ${({ theme }) => theme.colors.bgQuantumBanner};
    color: white;
    height: 76px;
    width: 100%;
  }
`;

export const SPowerdByText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textWhite};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    margin-right: 8px;
  }
`;
