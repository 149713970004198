/**
 * Smoothly y-scrolls parent div to an element position.
 * @param parentRef - The parent ref where scrolling is happening
 * @param targetRef - The target element ref you want to scroll to
 * @param offset - Offset of original element position to be added
 * @returns nothing
 * @example
 * ```ts
        scrollTo(leftColumnRef, riskRef, 100);
        ...
        scrollTo(leftColumnRef, liquidityRef, 20);

 * ```
 */
export const scrollTo = (
  parentRef: React.RefObject<HTMLDivElement>,
  targetRef: React.RefObject<HTMLDivElement>,
  offset: number
): void => {
  parentRef?.current?.scrollTo({
    top: (targetRef?.current?.offsetTop || 0) - offset,
    left: 0,
    behavior: "smooth",
  });
};
