import i18n, { t } from "i18next";
import Big from "big.js";

/**
 * Formats a string or null value to be displayed as a Percentage according to the app's business logic
 * @param value - the value to be formatted (string | null)
 * @param decimalPlaces - the number of decimal places to format the number with
 * @returns a formatted string value with two decimal places and with the correct decimal separator according to the app's language
 */
export const formatPercentage = (
  value: string | null,
  decimalPlaces = 2
): string => {
  return value === null
    ? " - "
    : `${Intl.NumberFormat(i18n.language, {
        style: "decimal",
        minimumFractionDigits: decimalPlaces,
      }).format(Big(value).round(decimalPlaces).toNumber())}%`;
};

/**
 * Formats a string or null value to be displayed as a Currency according to the app's business logic
 * @param value - the value to be formatted (string | null)
 * @param currency - the currently selected currency (string)
 * @returns a formatted string value with two decimal places and the correct decimal separator and currency symbol according to the app's language and currency selected
 */
export const formatMoney = (value: string | null, currency: string): string => {
  const currencySymbol = t(`currency.${currency}.symbol`);

  return value === null
    ? " - "
    : `${currencySymbol} ${Intl.NumberFormat(i18n.language, {
        style: "decimal",
        minimumFractionDigits: 2,
      }).format(Big(value).round(2).toNumber())}`;
};

/**
 * Formats a string or null value to be displayed as a Number according to the app's business logic
 * @param value - the value to be formatted (string | null)
 * @param decimalPlaces - the number of decimal places to format the number with
 * @returns a formatted string value with the number of decimal places passed as a parameter and the correct decimal separator according to the app's language and currency selected
 */
export const formatNumber = (
  value: string | null | number,
  decimalPlaces = 2
): string => {
  return value === null
    ? " - "
    : `${Intl.NumberFormat(i18n.language, {
        style: "decimal",
        minimumFractionDigits: decimalPlaces,
      }).format(Big(value.toString()).round(decimalPlaces).toNumber())}`;
};

/**
 * Formats a string representing a Date, a Date object or a null value to be displayed according to the app's business logic
 * @param date - the date as either a string or a Date type or the null object
 * @returns a formatted stringified date with the correct locale format
 */
export const formatDate = (date: string | Date | null): string => {
  return date === null
    ? " - "
    : new Date(date).toLocaleDateString(i18n.language, { timeZone: "UTC" });
};
