import { Grid, Typography, Tooltip } from "@mui/material";
import styled from "styled-components";

export const SGridContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6d3d3;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-button:start:increment {
      height: 3%;
      display: block;
      background: transparent;
    }
  }
`;

export const SScrollableArea = styled(Grid)`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6d3d3;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-button:start:increment {
      height: 3%;
      display: block;
      background: transparent;
    }
  }
`;

export const SRow = styled(Grid)`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    flex-wrap: nowrap;
  }
`;

export const SColumn1 = styled(Grid)`
  && {
    width: 10%;
    min-width: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.textDarkGray};
  }
`;

export const SColumn2 = styled(Grid)`
  && {
    display: block;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.textGray};

    max-width: 250px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const STitle = styled(Typography)`
  && {
    width: 100%;
    margin-bottom: 4px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.textDarkGray};
  }
`;

export const SShowHideButton = styled(Grid)`
  && {
    width: 100%;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: left;

    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.textDarkGray};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const STooltip = styled(Tooltip)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }
`;
