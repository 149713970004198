import React from "react";
import Big from "big.js";
import Highcharts from "highcharts";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import {
  Chart,
  HighchartsChart,
  HighchartsProvider,
  PieSeries,
  Tooltip,
  Title,
} from "react-jsx-highcharts";
import {
  calculateAssetsTotalAmount,
  formatPercentage,
  getExportingConfig,
  useAssetColor,
} from "../../../../utils";
import { SGridChartContainer } from "./styles";
import { PortfolioType, useCurrency } from "../../../../store";
import { PortfolioCompositionType } from "../../../../api";

interface FormattedAssetsType {
  name: string;
  y: number;
  color?: string;
}

interface PropTypes {
  compositionAssets: PortfolioCompositionType[];
  portfolio: PortfolioType;
  othersString: string;
}

export const WalletChart: React.FC<PropTypes> = ({
  compositionAssets,
  portfolio,
  othersString,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { currency } = useCurrency();
  const { generateAssetColor } = useAssetColor();

  const formattedAssets = (): (FormattedAssetsType | null)[] => {
    if (compositionAssets) {
      const first10 = compositionAssets.slice(0, 10);
      const rest = compositionAssets.slice(10);

      const allRestParticipations =
        rest.length > 0
          ? rest
              .map((compositionAsset) => Number(compositionAsset.participation))
              .reduce((acc, current) => acc + current)
          : 0;

      const data = [
        ...first10.map((asset, index) => ({
          name: asset.label || t("summaryChart.unlabeled"),
          y: Big(asset.participation || 0)
            .abs()
            .round(2)
            .toNumber(),
          color: generateAssetColor(index),
        })),
        allRestParticipations !== 0
          ? {
              name: othersString,
              y: Big(allRestParticipations).abs().round(2).toNumber(),
              color: "#CCC",
            }
          : null,
      ].filter((x) => x !== null);

      return data;
    }
    return [];
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currencySymbol = t(`currency.${currency}.symbol`);

  return (
    <SGridChartContainer>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsChart
          exporting={{
            ...getExportingConfig(
              0,
              0,
              t("wallet.tab"),
              t("wallet.tab"),
              theme.chartExportingButton.show
            ),
            chartOptions: {
              title: {
                text: `${currencySymbol} ${new Intl.NumberFormat(
                  i18n.language,
                  {
                    style: "decimal",
                    minimumFractionDigits: 2,
                  }
                ).format(
                  Big(calculateAssetsTotalAmount(portfolio.assets) || 0)
                    .round(2)
                    .toNumber()
                )}`,
                style: {
                  color: "#777",
                  fontSize: "32px",
                  fontWeight: "600",
                  textAlign: "left",
                },
              },
              subtitle: {
                text: t("wallet.tab"),
                style: {
                  color: "#333",
                  fontSize: "32px",
                  fontWeight: "600",
                },
              },
              plotOptions: {
                pie: {
                  size: null,
                  dataLabels: {
                    enabled: true,
                  },
                },
              },
              legend: {
                enabled: true,
                padding: 20,
                itemDistance: 50,
                labelFormatter(): string {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  // eslint-disable-next-line react/no-this-in-sfc
                  const { name, y } = this;
                  return `${name} - ${formatPercentage(y)}`;
                },
              },
              credits: {
                enabled: true,
                text: "Powered by Quantum",
                style: {
                  fontSize: "10px",
                },
              },
            },
          }}
          plotOptions={{
            pie: {
              size: "120%",
              innerSize: "75%",
              borderWidth: 1,
              borderColor: theme.colors.bgPrimary,
              center: ["50%", "50%"],
            },
          }}
        >
          <Chart type="pie" />
          <Title
            align="center"
            verticalAlign="middle"
            useHTML
            style={{
              color: theme.colors.textDefault,
              fontFamily: theme.fonts.primary,
            }}
          >
            {`
            <span style="display: flex; flex-direction: column;">
              <span style="font-weight: 600; font-size: 12px; align-self: center;">
              ${currencySymbol} ${new Intl.NumberFormat(i18n.language, {
              style: "decimal",
              minimumFractionDigits: 2,
            }).format(
              Big(calculateAssetsTotalAmount(portfolio.assets) || 0)
                .round(2)
                .toNumber()
            )}
              </span>
              <span style="font-weight: 300; font-size: 12px; align-self: center;">
              ${t("portfolioEditorInvestedTotal")}
              </span>
            </span>`}
          </Title>
          <Tooltip
            outside
            useHTML
            headerFormat={`
            <div style="display: flex;
                        flex-direction: row;
                        ">
            <div style="height: 16px;
                       width: 4px; min-width: 4px; 
                       background-color: {point.color}; 
                       margin-right: 10px;
                       ">
            </div>
            <span>{point.key}</span>
            </div>`}
            pointFormat={`<div style="font-weight: 600;">{point.y}%</div>`}
            style={{
              zIndex: 9990,
            }}
          />
          <PieSeries
            data={formattedAssets()}
            dataLabels={{
              enabled: false,
            }}
            showInLegend
          />
        </HighchartsChart>
      </HighchartsProvider>
    </SGridChartContainer>
  );
};
