import React from "react";
import {
  STableRow,
  SFirstColumnCell,
  SCell,
  SBar,
  SFirstColumnTextContainer,
  SEllipsisText,
} from "./styles";
import { useCurrency } from "../../../../store";
import { formatPercentage, formatMoney } from "../../../../utils";

interface RentabilityTableRowPropsType {
  asset: {
    color: string;
    label: string | null;
    key: string;
    month: string | null;
    yearToDate: string | null;
    sixMonths: string | null;
    year: string | null;
    selectedPeriod: string | null;
    participation: string | null;
    initialBalance: string | null;
    grossBalance: string | null;
  };
  large?: boolean;
  paddingRights: string[];
}

export const RentabilityTableRow: React.FC<RentabilityTableRowPropsType> = ({
  asset,
  large = false,
  paddingRights = Array(8).fill("8px"),
}) => {
  RentabilityTableRow.defaultProps = {
    large: false,
  };

  const { currency } = useCurrency();

  return (
    <STableRow key={`inner-rentability-${asset.key}`}>
      <SFirstColumnCell>
        <SFirstColumnTextContainer>
          <SBar $barColor={asset.color} $large={large} />
          <SEllipsisText>{asset.label}</SEllipsisText>
        </SFirstColumnTextContainer>
      </SFirstColumnCell>

      <SCell
        sx={{
          paddingRight:
            asset.month === null ? "8px" : `${paddingRights[0]} !important`,
          textAlign: asset.month === null ? "center" : "right",
        }}
      >
        {formatPercentage(asset.month)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.yearToDate === null
              ? "8px"
              : `${paddingRights[1]} !important`,
          textAlign: asset.yearToDate === null ? "center" : "right",
        }}
      >
        {formatPercentage(asset.yearToDate)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.sixMonths === null ? "8px" : `${paddingRights[2]} !important`,
          textAlign: asset.sixMonths === null ? "center" : "right",
        }}
      >
        {formatPercentage(asset.sixMonths)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.year === null ? "8px" : `${paddingRights[3]} !important`,
          textAlign: asset.year === null ? "center" : "right",
        }}
      >
        {formatPercentage(asset.year)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.selectedPeriod === null
              ? "8px"
              : `${paddingRights[4]} !important`,
          textAlign: asset.selectedPeriod === null ? "center" : "right",
        }}
      >
        {formatPercentage(asset.selectedPeriod)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.participation === null
              ? "8px"
              : `${paddingRights[5]} !important`,
          textAlign: asset.participation === null ? "center" : "right",
        }}
      >
        {formatPercentage(asset.participation)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.initialBalance === null
              ? "8px"
              : `${paddingRights[6]} !important`,
          textAlign: asset.initialBalance === null ? "center" : "right",
        }}
      >
        {formatMoney(asset.initialBalance, currency)}
      </SCell>
      <SCell
        sx={{
          paddingRight:
            asset.grossBalance === null
              ? "8px"
              : `${paddingRights[7]} !important`,
          textAlign: asset.grossBalance === null ? "center" : "right",
        }}
      >
        {formatMoney(asset.grossBalance, currency)}
      </SCell>
    </STableRow>
  );
};
