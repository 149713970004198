import { Typography, List, Grid } from "@mui/material";
import styled from "styled-components";

export const SEmptyBox = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 151px;
    border: 1px dashed ${({ theme }) => theme.colors.textGray};
  }
`;

export const SGridContainer = styled(Grid)`
  && {
    padding-top: 12px;
    padding-left: 32px;
    padding-right: 27px;
    padding-bottom: 8px;
    flex: 6;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6d3d3;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-button:start:increment {
      height: 3%;
      display: block;
      background: transparent;
    }
  }
`;

export const SGridRow1 = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const SGridRow2 = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const SAssetsHeader = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
  }
`;

export const SAssetsList = styled(List)`
  && {
    max-height: 100%;
    overflow-y: auto;
  }
`;

interface SCategorySeparatorLineType {
  $barColor: string;
}

export const SCategorySeparatorLine = styled(
  Typography
)<SCategorySeparatorLineType>`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    border-bottom: 1px solid ${({ $barColor }) => $barColor};
    margin-bottom: 6px;
    color: ${({ $barColor }) => $barColor};
  }
`;
