import React, { useState, useCallback, useEffect } from "react";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useTranslation } from "react-i18next";
import { useRemotePeriods, useFetchPeriod } from "../../../api";
import { usePeriod } from "../../../store";
import { DatePickerDialog } from "./DatePickerDialog/DatePickerDialog";
import {
  SButton,
  SButtonGroup,
  SGridContainer,
  SLabel,
  SLinearProgress,
} from "./styles";

export const PeriodsSelector: React.FC = () => {
  const { t } = useTranslation();
  const { selectedPeriod, setSelectedPeriod, setFetchedPeriodDate } =
    usePeriod();
  const [isCustomPeriodDialogOpen, setIsCustomPeriodDialogOpen] =
    useState(false);
  const { loading: isPeriodsFetchLoading, data: periods } = useRemotePeriods();
  const { fetch: getPeriodDates } = useFetchPeriod();

  const translatePeriods = (serverPeriodKey: string): string => {
    switch (serverPeriodKey) {
      case "OTIMO":
        return t("periodButton.optimal");
      case "NO_MES":
        return t("periodButton.month");
      case "UM_ANO_UTIL":
        return t("periodButton.year");
      case "SEIS_MESES_UTEIS":
        return t("periodButton.sixMonths");
      case "CINCO_ANOS_UTEIS":
        return t("periodButton.fiveYears");
      default:
        return String(serverPeriodKey);
    }
  };

  const handleSelection = (selectedButtonPeriodKey: string): void => {
    setSelectedPeriod(selectedButtonPeriodKey);
  };

  const handleSelectDatePicker = (): void => {
    setIsCustomPeriodDialogOpen(true);
  };

  const handleApplyChanges = (): void => {
    setIsCustomPeriodDialogOpen(false);
  };

  const handleFetchDates = useCallback(async (): Promise<void> => {
    const periodDates = await getPeriodDates(selectedPeriod);

    const fetchedPeriodDates = {
      initialDate: periodDates.initialDate,
      finalDate: periodDates.finalDate,
    };
    setFetchedPeriodDate(fetchedPeriodDates);
  }, [selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleFetchDates();
  }, [selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SGridContainer>
      <SLabel>{t("periodButton.title")}</SLabel>
      {isPeriodsFetchLoading ? (
        <SLinearProgress variant="indeterminate" color="inherit" />
      ) : (
        periods.length > 1 && (
          <SButtonGroup>
            {periods.length > 1 &&
              periods.map((periodItem) => (
                <SButton
                  $isSelected={
                    !isCustomPeriodDialogOpen &&
                    selectedPeriod !== "DATA_PERSONALIZADA" &&
                    periodItem === selectedPeriod
                  }
                  key={periodItem}
                  onClick={() => handleSelection(periodItem)}
                >
                  {translatePeriods(periodItem)}
                </SButton>
              ))}
            <SButton
              $isSelected={
                isCustomPeriodDialogOpen ||
                selectedPeriod === "DATA_PERSONALIZADA"
              }
              key="DATA_PERSONALIZADA"
              onClick={() => handleSelectDatePicker()}
            >
              <DateRangeIcon />
            </SButton>
            <DatePickerDialog
              open={isCustomPeriodDialogOpen}
              onClose={handleApplyChanges}
            />
          </SButtonGroup>
        )
      )}
    </SGridContainer>
  );
};
