import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import { useTheme } from "styled-components";
import { useSendTokenEmail } from "../../api";
import { AuthErrorMsg } from "../../pages";
import { isEmailValid } from "../../utils";
import {
  SButton,
  SCircularProgress,
  SError,
  SFormControl,
  SGridCard,
  SGridLoading,
  SInput,
  SLabel,
  SLogoImg,
  SMessageLine1,
  SMessageLine2,
  SCardCheckBox,
  SRememberMe,
  SEmailSent,
  SSuccess,
} from "./styles";

type LoginCardProps = {
  error: AuthErrorMsg;
};

export const LoginCard: React.FC<LoginCardProps> = ({ error }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const { loading, fetch: sendTokenEmail } = useSendTokenEmail();

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value.trim());
  };

  const handleOkClick = useCallback(async (): Promise<void> => {
    if (!isEmailValid(email)) {
      setErrorMsg(t(theme.login.invalidEmailMessage as never));
      return;
    }

    if (!loading) {
      setErrorMsg("");
      const emailSentRes = await sendTokenEmail(email, rememberMe);

      if (emailSentRes === null) {
        setErrorMsg(t(theme.login.errorMessage as never));
        return;
      }

      setIsEmailSent(true);
    }
  }, [
    email,
    sendTokenEmail,
    loading,
    rememberMe,
    t,
    theme.login.errorMessage,
    theme.login.invalidEmailMessage,
  ]);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      handleOkClick();
    }
  };

  useMount(() => {
    if (error.isError) {
      setErrorMsg(error.message);
    }
  });

  return (
    <SGridCard>
      <SLogoImg src={theme.login.logo.img} alt="logo" />

      <SMessageLine1>
        {t(theme.login.splashMessageLine1 as never)}
      </SMessageLine1>
      <SMessageLine2>
        {t(theme.login.splashMessageLine2 as never)}
      </SMessageLine2>

      {isEmailSent ? (
        <SEmailSent>
          <SSuccess severity="success">
            {t(theme.login.successMessage as never)}{" "}
            <strong>
              <u>{email}</u>
            </strong>
          </SSuccess>
        </SEmailSent>
      ) : (
        <SFormControl variant="standard">
          <SLabel>{t(theme.login.insertMailMessage as never)}</SLabel>
          <SInput
            id="email"
            value={email}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
            disabled={loading}
            placeholder="email@example.com"
            autoComplete="off"
            autoCapitalize="none"
            disableUnderline
            autoFocus
          />

          <SRememberMe
            onClick={() => {
              setRememberMe((remember) => !remember);
            }}
          >
            <SCardCheckBox checked={rememberMe} />
            {t(theme.login.rememberThisDevice as never)}
          </SRememberMe>

          <SButton onClick={handleOkClick} disabled={loading}>
            {loading ? (
              <SGridLoading key="RentabilityChartLoading">
                <SCircularProgress variant="indeterminate" color="inherit" />
              </SGridLoading>
            ) : (
              t(theme.login.okButtonMessage as never)
            )}
          </SButton>

          {errorMsg !== "" && <SError severity="error">{errorMsg}</SError>}
        </SFormControl>
      )}
    </SGridCard>
  );
};
